import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MDBModalRef, MDBModalService, MdbTableDirective, ToastService } from 'ng-uikit-pro-standard';
import { Subject} from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BranchNamePopupComponent } from '../branchnamepopup/branchnamepopup.component';
import { AppComponentService } from '../../../services/appcomponenet.service';
import { CryptoService } from '../../../services/crypto.service';
import { SettingService } from '../../../services/settings.service';

@Component({
  selector: 'app-branchmaster',
  templateUrl: './branchmaster.component.html',
  styleUrls: ['./branchmaster.component.scss']
})
export class BranchMasterComponent implements OnInit {
  Username: any; FullnameValue: any; EmailidValue: any; authorizeUserDetails: any[]; jobno: any;
  filterString: any = ''; filtered: any; Application: any; Password: any; estimatorUrl: any; Level: any; reverse: boolean;

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  /*searchText: string = ''; previous: string; isDesc: boolean; column: any;*/
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };
  @ViewChild("branchNamefocus", { static: false }) branchNamefocus: ElementRef;
  modalOptions: any; modalRef: MDBModalRef;
  BranchmasterTableForm: FormGroup; submitted: boolean; /*branchMasterTable: boolean;*/
  /*enableEditBranchMaster: any;*/ BranchmasterDetails: any[]; branchMasterval: any[];
  progressBarloading: boolean; /*branchmasterrecord: boolean;*/ Companydetails: any[]; activeBranchcode: boolean = false; LastBranchCode: any[];
  modalOptionBranchnamePopup: any; BranchNamePopup: any[];  BranchNameDetails: any[]; selectcompany: any; companychange: boolean;
  assignbranchcodeflag: any; BranchnamePopupval: any; BranchcodePopupval: any; BranchidPopupval: any; BranchnamePopupflag: any; insertbranchcode: any;
  insertedBranch: any[];
  constructor(private appComponentService: AppComponentService, public cryptoservice: CryptoService, private cdRef: ChangeDetectorRef,
    private modalService: MDBModalService, public formBuilder: FormBuilder,private settingService: SettingService, private toastrService: ToastService
  ) { }
  ngOnInit() {
    this.companychange = true;
    this.activeBranchcode = true;
    this.BranchMasterTableFormData();
    this.getCompanyDetails();
    this.getAuthorizeUserDetails();
  }
  getAuthorizeUserDetails() {
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    this.authorizeUserDetails = [];
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      var Fullname = this.authorizeUserDetails[0]['fullname'];
      var UserId = this.authorizeUserDetails[0]['userid'];
      var BranchName = this.authorizeUserDetails[0]['branchname'];
      var Level = this.authorizeUserDetails[0]['level'];
      var Emailid = this.authorizeUserDetails[0]['email'];
      var Application = this.authorizeUserDetails[0]['application'];
      var Password = this.authorizeUserDetails[0]['password'];
      if (UserId != "") {
        this.Username = UserId;
      }
      if (Fullname != "") {
        this.FullnameValue = Fullname;
      }
      if (Emailid != "") {
        this.EmailidValue = Emailid;
      }
      if (Application != "") {
        this.Application = Application;
      }
      if (Password != "") {
        this.Password = Password;
      }
      if (Level != "") {
        this.Level = Level.toLowerCase();
      }
    }
    this.getBranchmaster();
  }
  BranchMasterTableFormData() {
    this.companychange = true;
    this.activeBranchcode = true;
    this.BranchmasterTableForm = this.formBuilder.group({
      branchcode: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(10)])],
      branchname: [null, Validators.required],
      company: [null, Validators.required]
    });
  }
  get f() { return this.BranchmasterTableForm.controls; }

  //enableBranchMasterTable() {
  //  this.branchMasterTable = !this.branchMasterTable;
  //  this.submitted = false;
  //  this.enableEditBranchMaster = -1;
  //  this.BranchmasterTableForm.reset();
  //  this.activeBranchcode = true;
  //}
  getCompanyDetails() {
    this.settingService.getCompanyDetails().subscribe(getresult => { this.getCompanyDetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getCompanyDetailsSuccess(getresult: any[]) {
    this.Companydetails = [];
    if (getresult.length > 0) {
      this.Companydetails = getresult;
    }
  }
  getBranchmaster() {
    this.progressBarloading = true;
    let strusername = this.Username;
    this.settingService.getBranchMaster(strusername).subscribe(getresult => { this.getBranchMasterSuccess(getresult) },
      res => { this.onError(res) });
  }
  getBranchMasterSuccess(getresult: any[]) {
    debugger
    this.progressBarloading = false;
    this.BranchmasterDetails = [];
    if (getresult.length > 0) {
      this.BranchmasterDetails = getresult;
      //this.loadBranchmasterDetails();
    }
  }
  getBranchName() {
    debugger
    this.progressBarloading = true;
    let company = this.selectcompany;
    this.settingService.getBranchName(company).subscribe(getresult => { this.getBranchNameSuccess(getresult) },
      res => { this.onError(res) });
  }
  getBranchNameSuccess(getresult: any[]) {
    debugger
    this.progressBarloading = false;
    this.BranchNameDetails = [];
    if (getresult.length > 0) {
      this.BranchNameDetails = getresult;
    }
  }
  //loadBranchmasterDetails() {
  //  this.progressBarloading = false;
  //  this.searchText = '';
  //  if (this.BranchmasterDetails.length > 0) {
  //    this.branchmasterrecord = true;
  //    this.mdbTable.setDataSource(this.BranchmasterDetails);

  //    this.BranchmasterDetails = this.mdbTable.getDataSource();
  //    this.previous = this.mdbTable.getDataSource();
  //  }
  //  else {
  //    this.branchmasterrecord = false;
  //  }
   
  //}
  insertBranchMasterTable() {
    debugger
    //evt = (evt) ? evt : window.event;
    //let charCode = (evt.which) ? evt.which : evt.keyCode;
    //if (charCode === 13 || charCode === 9) {
      this.branchMasterval = [];
      this.submitted = true;
      if (this.BranchmasterTableForm.invalid) {
        return;
      }
      console.log(this.BranchmasterTableForm.value);
      this.branchMasterval.push(this.BranchmasterTableForm.value);

      let strusername = this.Username;
      this.insertbranchcode = this.BranchmasterTableForm.value.branchcode;
      let list = [];
      this.branchMasterval.forEach(x => {
        let Item = {
          "branchcode": x.branchcode,
          "branchname": x.branchname,
          "company": x.company,
          "userid": strusername
        };
        list.push(Item);
      });
      this.settingService.insertBranchMaster(list).subscribe(getresult => { this.insertBranchMasterSuccess(getresult) },
        res => { this.onError(res) });
    //}
    
  }
  insertBranchMasterSuccess(getresult: any[]) {
    debugger
    this.BranchmasterDetails = [];
    this.insertedBranch = [];
    if (getresult.length > 0) {
      this.BranchmasterDetails = getresult;

      //this.mdbTable.setDataSource(this.BranchmasterDetails);

      //this.BranchmasterDetails = this.mdbTable.getDataSource();
      //this.previous = this.mdbTable.getDataSource();

      let branchmsg = this.BranchmasterDetails[0].branchmsg;
      if (branchmsg == 'exist') {
        this.companychange = true;
        this.activeBranchcode = true;
        this.BranchmasterTableForm.patchValue({
          company: this.selectcompany
        });
        this.toastrService.warning('Branch name already exists', '', this.options);
        
      }
      else {
        this.BranchmasterTableForm.reset();
        this.BranchMasterTableFormData();
        this.toastrService.success('Branch name created', '', this.options);
      }
    }
    this.submitted = false;
    //this.searchText = '';
    //this.enableEditBranchMaster = -1;
    
    //this.branchMasterTable = true;
    this.getBranchName();
    this.assignBranchName(this.selectcompany);
    this.BranchmasterDetails.forEach(x => {
      if (x.branchcode == this.insertbranchcode) {
        this.insertedBranch = x;
        this.assignBranchCode(this.insertedBranch);
      }
    });
    
  }
  //editBranchMasterTable(item, e, i) {
  //  this.enableEditBranchMaster = i;
  //  this.branchMasterTable = false;
  //  this.BranchmasterTableForm.patchValue({
  //    branchcode: item.branchcode, branchname: item.branchname, company: item.company
  //  });
  //}
  //deleteBranchMasterTable(tablevalue, e, i) {
  //  debugger
  //  this.branchMasterTable = false;
  //  let branchid = tablevalue.branchid;
  //  let branchcode = tablevalue.branchcode;
  //  let strusername = this.Username;
  //  if (confirm("Are you sure you want to delete this branch?")) {
  //    this.settingService.checkBranchLinkToUser(branchcode, strusername).subscribe(getresult => { this.checkBranchLinkToUserSuccess(getresult, branchid) },
  //      res => { this.onError(res) });
  //    return true;
  //  }
  //  else {
  //    return false;
  //  }
  //}
  //checkBranchLinkToUserSuccess(getresult, branchid) {
  //  debugger
  //  let strusername = this.Username;
  //  if (getresult.length > 0) {
  //    this.toastrService.error('This branch is linked to a user so cannot be deleted', '', this.options);
  //  }
  //  else {
  //    this.settingService.deleteBranchMaster(branchid, strusername).subscribe(getresult => { this.deleteBranchMasterSuccess(getresult) },
  //      res => { this.onError(res) });
  //  }
  //}
  //deleteBranchMasterSuccess(getresult: any[]) {
  //  this.BranchmasterDetails = [];
  //  if (getresult.length > 0) {
  //    this.BranchmasterDetails = getresult;
  //    this.loadBranchmasterDetails();
  //  }
  //  this.toastrService.error('Branch name deleted', '', this.options);
  //}

  //updateBranchMasterTable(tablevalue, e, i) {
  //  this.branchMasterval = [];
  //  this.submitted = true;
  //  if (this.BranchmasterTableForm.invalid) {
  //    return;
  //  }
  //  console.log(this.BranchmasterTableForm.value);
  //  this.branchMasterval.push(this.BranchmasterTableForm.value);

  //  let strusername = this.Username;
  //  let branchid = tablevalue.branchid;
  //  let list = [];
  //  this.branchMasterval.forEach(x => {
  //    let Item = {
  //      "branchcode": x.branchcode,
  //      "branchname": x.branchname,
  //      "company": x.company,
  //      "userid": strusername
  //    };
  //    list.push(Item);
  //  });
  //  this.branchMasterTable = false;
  //  this.settingService.updateBranchMaster(list, branchid).subscribe(getresult => { this.updateBranchMasterSuccess(getresult) },
  //    res => { this.onError(res) });
  //}
  //updateBranchMasterSuccess(getresult: any[]) {
  //  this.BranchmasterDetails = [];
  //  if (getresult.length > 0) {
  //    this.BranchmasterDetails = getresult;
  //    this.loadBranchmasterDetails();
  //    let branchmsg = this.BranchmasterDetails[0].branchmsg;
  //    if (branchmsg == '') {
  //      this.toastrService.success('Branch name updated', '', this.options);
  //    }
  //    else {
  //      this.toastrService.warning('Branch name already exists', '', this.options);
  //    }
      
  //  }
  //  this.enableEditBranchMaster = -1;
  //}
  assignBranchName(evt) {
    debugger
    let company = evt;
    this.selectcompany = company;
    this.companychange = true;
    this.activeBranchcode = true;
    this.BranchmasterTableForm.patchValue({
      branchname: null, branchcode: ''
    });
    if (company) {
      this.companychange = false;
      this.getBranchName();
      //this.settingService.getLastBranchCode(company).subscribe(getresult => { this.getLastBranchCodeSuccess(getresult) },
      //  res => { this.onError(res) });
    }
    else {
      this.companychange = true;
      this.activeBranchcode = true;
      this.BranchmasterTableForm.patchValue({
        branchname: null, branchcode: ''
      });
    }
    
  }
  assignBranchCode(evt) {
    debugger
    let branchname = evt.branchname;
    let branchcode = evt.branchcode;
    let company = this.selectcompany;
    this.assignbranchcodeflag = 0;
    if (company && branchname) {
     // this.getBranchName();
      this.activeBranchcode = true;
      this.BranchmasterTableForm.patchValue({
        company: this.selectcompany, branchname: branchname, branchcode: branchcode
      });
      //this.settingService.getLastBranchCode(company).subscribe(getresult => { this.getLastBranchCodeSuccess(getresult) },
      //  res => { this.onError(res) });
    }
    else {
      this.BranchmasterTableForm.patchValue({
        branchcode: ''
      });
      this.activeBranchcode = false;
    }

  }
  addBranchname() {
    let company = this.selectcompany;
    this.settingService.getLastBranchCode(company).subscribe(getresult => { this.getLastBranchCodeSuccess(getresult) },
        res => { this.onError(res) });
  }
  getLastBranchCodeSuccess(getresult: any[]) {
    debugger
    this.activeBranchcode = false;
    this.LastBranchCode = [];
    if (getresult.length > 0) {
      this.LastBranchCode = getresult;
      let branchcode = this.LastBranchCode[0].branchcode;
      this.BranchmasterTableForm.patchValue({
        branchcode: branchcode
      });
    }
  }
  //cancelBranchMasterTable(e, i) {
  //  this.enableEditBranchMaster = -1;
  //}

  ngAfterViewInit() {
    this.cdRef.detectChanges();
    
  }
  //searchItems() {
  //  const prev = this.mdbTable.getDataSource();

  //  if (!this.searchText) {
  //    this.mdbTable.setDataSource(this.previous);
  //    this.BranchmasterDetails = this.mdbTable.getDataSource();
  //  }

  //  if (this.searchText) {
  //    this.BranchmasterDetails = this.mdbTable.searchLocalDataBy(this.searchText);
  //    this.mdbTable.setDataSource(prev);
  //  }
  //}

  ModalOptionBranchnamePopupLoad() {
    this.modalOptionBranchnamePopup = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-lg modal-dialog-centered',
      containerClass: '',
      animated: true,
      data: {
        branchnamePopupmodaldata: {
          companyval: this.selectcompany,
          branchnameval: this.BranchnamePopupval,
          branchcodeval: this.BranchcodePopupval,
          branchidval: this.BranchidPopupval,
          branchnameflag: this.BranchnamePopupflag
        }
      }
    }
  }
  openBranchnamePopupModal(val,flag) {
    debugger
    this.BranchnamePopupflag = flag;
    if (val) {
      this.BranchnamePopupval = val.branchname;
      this.BranchcodePopupval = val.branchcode;
      this.BranchidPopupval = val.branchid;
    }
    else {
      this.BranchnamePopupval = '';
      this.BranchcodePopupval = '';
      this.BranchidPopupval = '';
    }
   
    this.ModalOptionBranchnamePopupLoad();
    this.BranchnamePopupModal();
  }
  BranchnamePopupModal() {
    this.modalRef = this.modalService.show(BranchNamePopupComponent, this.modalOptionBranchnamePopup);
    this.modalRef.content.action.subscribe((result: any) => {
      debugger
      this.BranchNamePopup = [];
      this.BranchNamePopup = result;
      if (result == 'delete') {
        this.companychange = false;
        this.activeBranchcode = true;
        this.getBranchName();
        this.BranchmasterTableForm.patchValue({
          branchname: null, branchcode: ''
        });
      }
      if (this.BranchnamePopupflag == 'add') {
        this.BranchmasterTableForm.patchValue({
          company: this.selectcompany, branchname: result.branchname, branchcode: result.branchcode
        });
        if (result.branchname == '' || result.branchname == undefined) {
          this.addBranchname();
          this.insertBranchMasterTable();
        }
        else {
          this.insertBranchMasterTable();
        }
        
      }
      else if (this.BranchnamePopupflag == 'edit' && result != 'delete') {
        this.getBranchName();
        this.assignBranchName(this.selectcompany);
        this.assignBranchCode(result);
      }
    });
  }
  //dtTablesortBranchmaster(property) {
  //  let direction = 0;
  //  this.isDesc = !this.isDesc; //change the direction
  //  this.column = property;
  //  direction = this.isDesc ? 1 : -1;

  //  this.BranchmasterDetails.sort(function (a, b) {
  //    if (a[property] < b[property]) {
  //      return -1 * direction;
  //    }
  //    else if (a[property] > b[property]) {
  //      return 1 * direction;
  //    }
  //    else {
  //      return 0;
  //    }
  //  });
  //};
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      evt.preventDefault();
    }

  }
  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }
  isNumberwithchar(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (!(charCode > 47 && charCode < 58) && // numeric (0-9)
      !(charCode > 64 && charCode < 91) && // upper alpha (A-Z)
      !(charCode > 96 && charCode < 123)) {
      evt.preventDefault();
    }
  }
  isNumberwithcharhyphn(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && charCode === 32 && charCode != 45 && (charCode < 48 || charCode > 57) && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      evt.preventDefault();
    }
  }
  isNumberwithdot(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && charCode != 32 && charCode != 46 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }
  onError(res: any) {
    console.log(res);
  }
}
