import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError, interval, BehaviorSubject, Subject, Subscription, of } from 'rxjs'
import { AppSettings } from '../Appsettings';

@Injectable({
  providedIn: 'root'
})
export class DocsUploadDownloadService {
  formDigest: any = null;
  URI = AppSettings.OAuthSettings.redirectUri;
  estData: any;
  //invokeFirstComponentFunction = new EventEmitter();
  //subsVar: Subscription;
  estSaletype = new Subject<any>();
  //onFirstComponentButtonClick() {
  //  this.invokeFirstComponentFunction.emit();
  //}

  estSaletypeValue = this.estSaletype.asObservable();


  changeSaletype(Saletype: any) {
    this.estSaletype.next(Saletype)
  }
  constructor(private _http: HttpClient) {
  }
  //Upload Documents
  uploadDocument(formData): Observable<any> {
    return this._http.post(this.URI + 'api/DocsUploadDownload/UploadDoc', formData);
  }
  downloadDocument(filePathval: string): Observable<HttpEvent<Blob>> {
    return this._http.request(new HttpRequest(
      'GET',
      `${this.URI + 'api/DocsUploadDownload/DownloadDoc'}?file=${filePathval}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  deleteDocumentFile(filePathval: string): Observable<any> {
    return this._http.request(new HttpRequest(
      'GET',
      `${this.URI + 'api/DocsUploadDownload/DeleteDoc'}?file=${filePathval}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  fetchFilePath(): Observable<any> {
    return this._http.get(this.URI + 'api/DocsUploadDownload/FetchFilePath');
  }
}
