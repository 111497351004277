import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation, ViewContainerRef, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MDBModalRef, ToastService} from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NewEvent } from '../../Interface/new-event';
import { HeaderService } from '../../services/header.service';
import { AppComponentService } from '../../services/appcomponenet.service';
/*import { GraphService } from '../../services/graph.service';*/
import { LeadGenerationService } from '../../services/leadgeneration.service';
import { EmailService } from '../../services/email.service';
import { DocsUploadDownloadService } from '../../services/docsuploaddownload.service';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-leademailpopup',
  templateUrl: './leademailpopup.component.html',
  styleUrls: ['./leademailpopup.component.scss']
})
export class LeademailPopupComponent implements OnInit {
  Username: any; authorizeUserDetails: any[]; leademailpopupmodaldata: any; allEmailDetails: any[];

  action = new Subject(); sendEmailForm: FormGroup;Useremail: any;

  @ViewChild('emailbodyval', { static: false }) emailbodyval: ElementRef;
  fileToUpload: File = null; model = new NewEvent(); base64textString: any;
  /* emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";*/
   emailPattern = "^([a-zA-Z0-9_+-.%]+@[a-zA-Z0-9_.-]+\.[A-Za-z]{2,4})(,[a-zA-Z0-9_+-.%]+@[a-zA-Z0-9_.-]+\.[A-Za-z]{2,4})*$";
  finalemailTo: any[]; finalemailcc: any[]; show: boolean; OutlookemailList: any[];
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' }; emailbody: any; leadid: any; LeadSentMail: any[];
  fullname: any;
  constructor(private cdRef: ChangeDetectorRef, private router: Router, private activatedroute: ActivatedRoute, private toastrService: ToastService,
    private headerService: HeaderService, private appComponentService: AppComponentService, private leadGenerationservice: LeadGenerationService,
    public modalRef: MDBModalRef, private formBuilder: FormBuilder, private emailservice: EmailService/*, private graphService: GraphService*/
    , private spinner: NgxSpinnerService) { }

  ngOnInit() {
    debugger
    this.emailbody = this.leademailpopupmodaldata.emailbody;
    this.leadid = this.leademailpopupmodaldata.leadid;
    this.formData();
    this.getAuthorizeUserDetails();
  }
  getAuthorizeUserDetails() {
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    this.authorizeUserDetails = [];
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      let UserId = this.authorizeUserDetails[0]['userid'];
      let Email = this.authorizeUserDetails[0]['email'];
      this.fullname = this.authorizeUserDetails[0]['fullname'];
      if (UserId != "") {
        this.Username = UserId;
      }
      if (Email != "") {
        this.Useremail = Email;
      }
    }
  }
  formData() {
    this.sendEmailForm = this.formBuilder.group({
      emailto: ['', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
      emailcc: ['', Validators.compose([Validators.pattern(this.emailPattern)])],
      emailattachment: ['']
    });
  }
  get f() { return this.sendEmailForm.controls; }
  showBtn() {
    this.show = true;
  }
  async sendEmail() {
    debugger
    this.allEmailDetails = []; this.finalemailTo = []; this.finalemailcc = [];let emailcc = '';
    if (this.sendEmailForm.invalid) {
      return;
    }
    this.spinner.show();
    console.log(this.sendEmailForm.value);
    this.allEmailDetails.push(this.sendEmailForm.value);
    let strusername = this.Username;
    let email = this.Useremail;

    let emailto = this.allEmailDetails[0].emailto;

      emailcc = this.allEmailDetails[0].emailcc;

    let subject = 'Pavion leads';
    let body = this.emailbody;
    let to = emailto;
    let toname = to.substring(0, to.indexOf('@'));
    let cc = emailcc;
      let attachment = this.fileToUpload;
      let attachmentbyte = this.base64textString;
    let emailgraphEvent = this.model.getEmailGraphEvent(subject, body, to, cc, attachment, attachmentbyte);
    console.log(emailgraphEvent);
    this.OutlookemailList = [];
    
    const formData = new FormData();
    if (attachment != null) {
      formData.append('cc', cc ? cc : '');
      formData.append('attachments', attachment ? attachment : null, attachment.name ? attachment.name : null);
      formData.append('subject', subject);
      formData.append('body', body);
      formData.append('to', to);
      formData.append('toname', toname);
    }
    else {
      formData.append('cc', cc ? cc : '');
      formData.append('subject', subject);
      formData.append('body', body);
      formData.append('to', to);
      formData.append('toname', toname);
    }
    
    this.emailservice.sendEmailAttachment(formData).subscribe(getresult => { this.sendEmailSuccess(getresult) },
      res => { this.onError(res) });

    //await this.graphService.addEventToEmail(emailgraphEvent).then((events) => {
    //  this.OutlookemailList.push(events);
    //  this.emailSent();
    //});
   
  }
  sendEmailSuccess(getresult) {
    debugger
    this.spinner.hide();
    if (getresult != '') {
      let emailmsg = getresult;
      if (emailmsg == '1') {
        this.toastrService.success('Email sent', '', this.options);
        this.action.next('');
        this.closeEmailModal();
        let leadid = this.leadid;
        this.leadGenerationservice.updateLeadSentMail(leadid).subscribe(getresult => { this.updateLeadSentMailSuccess(getresult) },
          res => { this.onError(res) });
      }
      else {
        this.toastrService.error('Email could not be sent due to network error!', '', this.options);
      }
    }
  }
  //emailSent() {
  //  if (this.OutlookemailList != undefined) {
  //    if (this.OutlookemailList.length > 0) {
  //      for (var i = 0; i < this.OutlookemailList.length; i++) {
  //        let subject = this.OutlookemailList[i].subject;
  //        //var from = this.OutlookemailList[i].from.emailAddress.address;
  //        console.log(subject);
  //        if (subject != '') {
  //          var id = this.OutlookemailList[i].id;
  //          var parentFolderId = this.OutlookemailList[i].parentFolderId;
  //          var outlookemailid = id;
  //          this.graphService.SendEmail(outlookemailid);
  //          console.log(id);
  //        }
  //      }
  //    }
  //  }
  //  this.action.next();

  //  this.toastrService.success('Email sent', '', this.options);
  //  this.closeEmailModal();
  //  let leadid = this.leadid;
  //  this.leadGenerationservice.updateLeadSentMail(leadid).subscribe(getresult => { this.updateLeadSentMailSuccess(getresult) },
  //    res => { this.onError(res) });
  //}
  updateLeadSentMailSuccess(getresult: any[]) {
    this.LeadSentMail = [];
    if (getresult.length > 0) {
      this.LeadSentMail = getresult;
      let leadsentmailmsg = this.LeadSentMail[0].leadsentmailmsg;
      if (leadsentmailmsg =='created') {
        this.toastrService.success('Lead status changed to Sent email', '', this.options);
      }
    }
  }
  handleInput(e) {
    if (e.which === 32 && !this.sendEmailForm.get('emailbody').value)
      e.preventDefault();
  }
  async handleFileInput(evt: any) {
    debugger
    //this.fileToUpload = files.item(0);
    const file = evt.target.files[0];
    this.fileToUpload = file;
    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    console.log(this.base64textString);
    /*stringReplaced = String.raw`${result}`.split('\\\\').join('/');*/
  }
  closeEmailModal() {
    this.show = false;
    this.sendEmailForm.reset({
      'email': '',
      'emailattachment': ''
    });
    this.modalRef.hide();
    this.spinner.hide();
  }
 
  onError(res: any) {
    this.spinner.hide();
    console.log(res);
  }
}
