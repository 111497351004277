import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MDBModalRef, MdbTableDirective, ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponentService } from '../../../services/appcomponenet.service';
import { SettingService } from '../../../services/settings.service';


@Component({
  selector: 'app-branchnamepopup',
  templateUrl: './branchnamepopup.component.html',
  styleUrls: ['./branchnamepopup.component.scss']
})
export class BranchNamePopupComponent implements OnInit {
  Username: any; authorizeUserDetails: any[]; branchnamePopupmodaldata: any;
  @ViewChild("branchnamefocus", { static: false }) branchnamefocus: ElementRef;
  @ViewChild("branchcodefocus", { static: false }) branchcodefocus: ElementRef;
  action = new Subject(); estEstimateid: any; estJobno: any;
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };
  company: any = ''; branchname: any = ''; branchcode: any = ''; branchid: any = ''; branchnameflag: any = '';
  BranchmasterDetails: any[]; branchMasterval: any[]; updatedBranch: any[]; Companydetails: any[];
  LastBranchCode: any[];
  constructor(private cdRef: ChangeDetectorRef, private router: Router, private activatedroute: ActivatedRoute,
    private appComponentService: AppComponentService,private datepipe: DatePipe, private _el: ElementRef, public modalRef: MDBModalRef, private toastrService: ToastService,
    public formBuilder: FormBuilder, private settingService: SettingService) { }
  ngAfterViewInit() {
    this.branchnameflag = this.branchnamePopupmodaldata.branchnameflag;
    if (this.branchnamefocus != undefined && this.branchnameflag == 'add') {
      this.branchnamefocus.nativeElement.focus();
    }
  }
  ngOnInit() {
    debugger
    this.company = this.branchnamePopupmodaldata.companyval;
    this.branchname = this.branchnamePopupmodaldata.branchnameval;
    this.branchcode = this.branchnamePopupmodaldata.branchcodeval;
    this.branchid = this.branchnamePopupmodaldata.branchidval;
    this.branchnameflag = this.branchnamePopupmodaldata.branchnameflag;
    this.getAuthorizeUserDetails();
  }
  getAuthorizeUserDetails() {
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    this.authorizeUserDetails = [];
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      let UserId = this.authorizeUserDetails[0]['userid'];
      let level = this.authorizeUserDetails[0]['level'];
      let Sedonadatabase = this.authorizeUserDetails[0]['sedonadatabase'];
      if (UserId != "") {
        this.Username = UserId;
      }
      this.getCompanyDetails();
    }
  }
  getCompanyDetails() {
    this.settingService.getCompanyDetails().subscribe(getresult => { this.getCompanyDetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getCompanyDetailsSuccess(getresult: any[]) {
    this.Companydetails = [];
    if (getresult.length > 0) {
      this.Companydetails = getresult;
    }
  }
  //getEstPassestDetails() {
  //  this.passestexactval = this.passestval;
  //  let strusername = this.Username;

  //  this.estimatedetailsheadService.getEstPassest(strusername).subscribe(getresult => { this.getEstPassestSuccess(getresult) },
  //    res => { this.onError(res) });
  //  if (this.passedto == '' && this.passestpassedto == '') {
  //    this.showpassestMsg = '';
  //    this.alertshowpassestSuccess = false;
  //  }
  //  else {
  //    if (this.passestpassedto) {
  //      this.alertshowpassestSuccess = true;
  //      this.showpassestMsg = 'This estimate has been passed to :' + ' ' + this.passestpassedto;
  //    }
  //    else {
  //      this.alertshowpassestSuccess = true;
  //      this.showpassestMsg = 'This estimate has been passed to :' + ' ' + this.passedto;
  //    }
  //  }
  //}
  //getEstPassestSuccess(getresult: any[]) {
  //  this.passestdetails = [];
  //  if (getresult.length > 0) {
  //    this.passestdetails = getresult;
  //  }
  //}
  //passest() {
  //  let passedto = this.passestexactval;
  //  let estimateid = this.estEstimateid;
  //  let strusername = this.Username;

  //  this.estimatedetailsheadService.updatePassest(passedto, estimateid, strusername).subscribe(getresult => { this.updatePassestSuccess(getresult) },
  //    res => { this.onError(res) });
  //}
  //updatePassestSuccess(getresult: any[]) {
  //  let newcontactcount = 0;
  //  this.updatepassest = [];
  //  if (getresult.length > 0) {
  //    this.updatepassest = getresult;
  //    newcontactcount = getresult.length;
  //    this.passestisaccept = this.updatepassest[0].isaccepted;
  //    this.passestpassedto = this.updatepassest[0].passedto;
  //    this.alertshowpassestSuccess = true;
  //    this.showpassestMsg = 'This estimate has been passed to :' + ' ' + this.passestpassedto;
  //  }
  //  this.passestexactval = this.passestval;
  //  this.allContactsService.sendPassestcount(newcontactcount);
  //}
  deleteBranchMasterTable() {
    debugger
    let branchid = this.branchid;
    let branchcode = this.branchcode;
    let strusername = this.Username;
    if (confirm("Are you sure you want to delete this branch?")) {
      this.settingService.checkBranchLinkToUser(branchcode, strusername).subscribe(getresult => { this.checkBranchLinkToUserSuccess(getresult, branchid) },
        res => { this.onError(res) });
      return true;
    }
    else {
      return false;
    }
  }
  checkBranchLinkToUserSuccess(getresult, branchid) {
    debugger
    let strusername = this.Username;
    if (getresult.length > 0) {
      this.toastrService.error('This branch is linked to a user so cannot be deleted', '', this.options);
    }
    else {
      this.settingService.deleteBranchMaster(branchid, strusername).subscribe(getresult => { this.deleteBranchMasterSuccess(getresult) },
        res => { this.onError(res) });
    }
  }
  deleteBranchMasterSuccess(getresult: any[]) {
    debugger
    this.branchname = '';
    this.BranchmasterDetails = [];
    if (getresult.length > 0) {
      this.BranchmasterDetails = getresult;
    }
    this.toastrService.error('Branch name deleted', '', this.options);
    this.closeBranchMasterTable();
    this.action.next('delete');
  }

  updateBranchMasterTable() {
    debugger
    this.branchMasterval = [];

    let strusername = this.Username;
    let branchid =this.branchid;
    let list = [];
      let Item = {
        "branchcode": this.branchcode,
        "branchname": this.branchname,
        "company": this.company,
        "userid": strusername
      };
      list.push(Item);
    this.settingService.updateBranchMaster(list, branchid).subscribe(getresult => { this.updateBranchMasterSuccess(getresult) },
      res => { this.onError(res) });
  }
  updateBranchMasterSuccess(getresult: any[]) {
    debugger
    this.BranchmasterDetails = [];
    this.updatedBranch = [];
    if (getresult.length > 0) {
      this.BranchmasterDetails = getresult;
      let branchmsg = this.BranchmasterDetails[0].branchmsg;
      if (branchmsg == '') {
        this.toastrService.success('Branch name updated', '', this.options);
      }
      else {
        this.toastrService.warning('Branch name already exists', '', this.options);
      }
      this.BranchmasterDetails.forEach(x => {
        if (x.branchid == this.branchid) {
          this.updatedBranch = x;
          this.closeBranchMasterTable();
          this.action.next(this.updatedBranch);
        }
    });
    }
  }
  addBranchname() {
    debugger
    let company = this.company;
    this.settingService.getLastBranchCode(company).subscribe(getresult => { this.getLastBranchCodeSuccess(getresult) },
      res => { this.onError(res) });
  }
  getLastBranchCodeSuccess(getresult: any[]) {
    debugger
    this.LastBranchCode = [];
    if (getresult.length > 0) {
      this.LastBranchCode = getresult;
      let branchcode = this.LastBranchCode[0].branchcode;
      if (branchcode == '') {
        this.branchcode = this.company + '-01';
      }
      else {
        this.branchcode = branchcode;
      }
      this.branchcodefocus.nativeElement.focus();
    }
  }
  assignBranchcode(evt) {
    debugger
    if (this.branchnameflag == 'add') {
      this.addBranchname();
      return true;
    }
    else {
      evt.stopPropagation();
      return false;
    }
  }
  saveBranchMasterTable() {

    this.CheckBranchExist();
  }
  CheckBranchExist() {
    debugger
    let branchname = this.branchname;
    let branchcode = this.branchcode;
    let company = this.company;
    this.settingService.checkBranchExist(branchname, branchcode, company).subscribe(getresult => { this.checkBranchExistSuccess(getresult) },
      res => { this.onError(res) });
  }
  checkBranchExistSuccess(getresult: any[]) {
    debugger
    if (getresult.length > 0) {
      let branchexistmsg = getresult[0].branchexistmsg;
      if (branchexistmsg == 'exist') {
        this.branchcode = '';
        this.toastrService.warning('Branch name or branch code already exists', '', this.options);
        this.addBranchname();
        return false;
      }
      else {
        this.closeBranchMasterTable();
        this.action.next({ branchname: this.branchname, branchcode: this.branchcode });
        return true;
      }
    }
  }
  clearBranchMasterTable() {
    this.branchname = '';
    this.branchcode = '';
  }
  closeBranchMasterTable() {
    this.modalRef.hide();
  }
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      evt.preventDefault();
    }

  }
  isNumberwithcharhyphn(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && charCode != 32 && charCode != 45 && (charCode < 48 || charCode > 57) && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      evt.preventDefault();
    }
  }
  onError(res: any) {
    console.log(res);
  }
}
