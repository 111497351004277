import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {ToastService} from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AppComponentService } from '../../services/appcomponenet.service';
import { MustMatch } from '../../feature/customvalidator/confirmed.validator';
import { SettingService } from '../../services/settings.service';
import { HeaderService } from '../../services/header.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-useredit',
  templateUrl: './useredit.component.html',
  styleUrls: ['./useredit.component.scss']
})
export class UserEditComponent implements OnInit {
  Username: any; authorizeUserDetails: any[];

  UserEditForm: FormGroup; submitted = false;
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };
  userAddval: any[]; 
  levelPlaceholder: string;branchnamePlaceholder: string;
  UserLeveldetails: any[]; Branchnamedetails: any[];
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  userDetails: any[]; userFullDetails: any[]; firstName: any; lastName: any; useridLevel: any;
  companyPlaceholder: any; Companydetails: any[]; /*saveupdatebtn: any;*/ RowId: any; /*userFlag: any;*/ userId: any;
  branchnameactive: boolean = false; ManagerName: any[]; managerPlaceholder: any; UserManagerExist: any[];
  ManagerDBValue: any; Manager2DBValue: any; Manager3DBValue: any; EmployeenumberDetails: any[];
  activeExecutiveEmail: boolean = false; activeval: boolean = false; isexecutiveemailval: boolean = false;
  fieldLevelActive: boolean = false;
  constructor(private router: Router, private activatedroute: ActivatedRoute,private appComponentService: AppComponentService, public formBuilder: FormBuilder,
    private toastrService: ToastService, private settingService: SettingService, private headerService: HeaderService, private spinner: NgxSpinnerService) {}

  ngOnInit() {
    debugger
    this.spinner.show();
    this.levelPlaceholder = 'Select Level *';
    this.branchnamePlaceholder = 'Select Branch *';
    this.companyPlaceholder = 'Select Company *';
    this.managerPlaceholder = 'Select Manager *';
   // this.activatedroute.params.subscribe(params => this.userFlag = params.userFlag);
    this.activatedroute.params.subscribe(params => this.RowId = params.RowId);
    this.activatedroute.params.subscribe(params => this.userId = params.userId);

    //if (this.RowId != undefined && this.userFlag != undefined) {
    //  if (this.userFlag == '1') {
    //    this.saveupdatebtn = 'UPDATE';
    //    this.headerService.listener('Update User');
    //  }
    //  else {
    //    this.saveupdatebtn = 'SAVE';
    //    this.headerService.listener('Create User');
    //  }
    //}
    //else {
    //  this.saveupdatebtn = 'SAVE';
    //  this.headerService.listener('Create User');
    //}

    this.UserEditFormData();
    this.getCompanyDetails();
    this.loadManagerDetails();
    this.getAuthorizeUserDetails();
  }
  getAuthorizeUserDetails() {
    debugger
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    debugger
    this.authorizeUserDetails = []; let Level = '';
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      var UserId = this.authorizeUserDetails[0]['userid'];
      Level = this.authorizeUserDetails[0]['level'];
      this.firstName = this.authorizeUserDetails[0]['firstname'];
      this.lastName = this.authorizeUserDetails[0]['lastname'];
      if (Level) {
        Level = Level.toLowerCase();
      }
      if (Level=='admin') {
        this.fieldLevelActive = true;
      }
      else {
        this.fieldLevelActive = false;
      }

      if (this.fieldLevelActive) {
        this.UserEditForm.get('isactive').enable();
        this.UserEditForm.get('isactive').setValidators(Validators.required);
        this.UserEditForm.get('isexecutiveemail').enable();
        // Set validators when enabling
      } else {
        this.UserEditForm.get('isactive').disable();
        this.UserEditForm.get('isactive').clearValidators();
        this.UserEditForm.get('isexecutiveemail').disable();
        // Clear validators when disabling
      }
      this.UserEditForm.get('isactive').updateValueAndValidity();
      this.UserEditForm.get('isexecutiveemail').updateValueAndValidity();
      // Update value and validity to apply changes


      if (UserId != "") {
        this.Username = UserId;
        this.useridLevel = Level;
      }
    }
    this.getUserLevel();
  }
  UserEditFormData() {
    this.UserEditForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      userid: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      branchname: [null, Validators.required],
      level: [null, Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
      employeenumber: [''],
      mobilenumber: ['', Validators.compose([Validators.minLength(13), Validators.maxLength(15)])],
      isactive: [{ value: true, disabled: this.fieldLevelActive }, this.fieldLevelActive ? [] :  Validators.required],
      company: [null, Validators.required],
      manager: [null, Validators.required],
      manager2: [null],
      manager3: [null],
      isexecutiveemail: [{ value: false, disabled: this.fieldLevelActive }]
    });
    this.UserEditForm.get('isactive').disable();
  }
  get f() { return this.UserEditForm.controls; }
  getUserLevel() {
    let strusername = this.Username;
    this.settingService.getUserLevel(strusername).subscribe(getresult => { this.getUserLevelSuccess(getresult) },
      res => { this.onError(res) });
  }
  getUserLevelSuccess(getresult: any[]) {
    this.UserLeveldetails = [];
    if (getresult.length > 0) {
      this.UserLeveldetails = getresult;
    }
  }
  loadManagerDetails() {
    this.settingService.getManagerDetails().subscribe(getresult => { this.getManagerDetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getManagerDetailsSuccess(getresult: any[]) {
    this.ManagerName = [];
    if (getresult.length > 0) {
      this.ManagerName = getresult;
    }
  }
  getBranchname(evt, flag) {
    debugger
    let company = evt;
    this.settingService.getCompanyWithBranchDetails(company).subscribe(getresult => { this.getCompanyWithBranchDetailsSuccess(getresult,flag) },
      res => { this.onError(res) });
  }
  getCompanyWithBranchDetailsSuccess(getresult: any[], flag) {
    debugger
    let managerval = ''; let manager2val = ''; let manager3val = '';
    this.spinner.hide();
    this.Branchnamedetails = [];
    if (getresult.length > 0) {
      this.Branchnamedetails = getresult;
    }

    if (this.RowId != undefined) {
      if (this.userFullDetails.length > 0 && flag =='1') {
        let firstname = this.userFullDetails[0].firstname;
        let lastname = this.userFullDetails[0].lastname;
        let userid = this.userFullDetails[0].userid;
        let branchid = this.userFullDetails[0].branchid;
        let level = this.userFullDetails[0].level;
        let email = this.userFullDetails[0].email;
        let employeenumber = this.userFullDetails[0].employeenumber;
        let mobilenumber = this.userFullDetails[0].mobilenumber;
        let active = this.userFullDetails[0].active;
        let company = this.userFullDetails[0].company;
        let manager = this.userFullDetails[0].manager;
        let isexecutiveemail = this.userFullDetails[0].isexecutiveemail;
        if (isexecutiveemail) {
          this.activeval = active;
        }
        if (isexecutiveemail) {
          this.isexecutiveemailval = isexecutiveemail;
        }

        if (this.Branchnamedetails.length > 0) {
          this.userFullDetails.forEach((x) => {
            this.Branchnamedetails.forEach((y) => {
              if (x.branchid == y.branchcode) {
                x.branchname = y.branchname;
              }
            })
          });
        }
        if (this.ManagerName.length > 0) {
          this.userFullDetails.forEach((x) => {
            this.ManagerName.forEach((y) => {
              if (x.manager == y.userid) {
                managerval = y.emailusername;
                this.ManagerDBValue = managerval;
              }
            })
          });
        }
        if (this.ManagerName.length > 0) {
          this.userFullDetails.forEach((x) => {
            this.ManagerName.forEach((y) => {
              if (x.manager2 == y.userid) {
                manager2val = y.emailusername;
                this.Manager2DBValue = manager2val;
              }
            })
          });
        }
        if (this.ManagerName.length > 0) {
          this.userFullDetails.forEach((x) => {
            this.ManagerName.forEach((y) => {
              if (x.manager3 == y.userid) {
                manager3val = y.emailusername;
                this.Manager3DBValue = manager3val;
              }
            })
          });
        }
        let branchname = this.userFullDetails[0].branchname;
        this.Phonenumberchange(mobilenumber);

        let levelLowerCase = level.toLowerCase();
        if (levelLowerCase == 'technician') {
          this.activeExecutiveEmail = false;
        }
        else {
          this.activeExecutiveEmail = true;
        }
        this.UserEditForm.patchValue({
          firstname: firstname, lastname: lastname, userid: userid, branchname: branchname, level: level, email: email, employeenumber: employeenumber,
          isactive: active, company: company, manager: managerval ? managerval : null, manager2: manager2val ? manager2val : null,
          manager3: manager3val ? manager3val : null, isexecutiveemail: isexecutiveemail
        });
      }
    }
  }
  companyChange(evt) {
    if (evt) {
      this.spinner.show();
      this.branchnameactive = true;
      this.UserEditForm.patchValue({
        branchname: null
      });
      this.getBranchname(evt,'0');
    }
    else {
      this.branchnameactive = false;
    }
  }
  branchNameChange() {
    let branchname = '';
    let branchnameval = this.UserEditForm.value.branchname;
    this.Branchnamedetails.forEach((branch) => {
      if (branch.branchcode == branchnameval) {
        branchname = branch.branchname;
      }
    })
    //if (branchname) {
    //  this.getUserMultipleLevel();
    //}
  }
  FetchEmployeeNumber(evt: any) {
    debugger
    let email = evt.target.value;
    if (email) {
      this.settingService.getEmployeeNumber(email).subscribe(getresult => { this.getEmployeeNumberSuccess(getresult) },
        res => { this.onError(res) });
    }
  }
  getEmployeeNumberSuccess(getresult: any[]) {
    this.EmployeenumberDetails = [];
    if (getresult.length > 0) {
      this.EmployeenumberDetails = getresult;
      let employeenumber = this.EmployeenumberDetails[0].employeenumber;
      this.UserEditForm.patchValue({
        employeenumber: employeenumber ? employeenumber : ''
      });
    }
    else {
      this.UserEditForm.patchValue({
        employeenumber:  ''
      });
    }
  }
  getCompanyDetails() {
    this.settingService.getCompanyDetails().subscribe(getresult => { this.getCompanyDetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getCompanyDetailsSuccess(getresult: any[]) {
    debugger
    this.spinner.hide();
    this.Companydetails = [];
    if (getresult.length > 0) {
      this.Companydetails = getresult;
    }
    if (this.RowId != undefined) {
        let userid = this.userId;
        this.getUser(userid);
    }
  }
  getUser(userid) {
    let strusername = this.Username;
    let user = userid;
    this.settingService.getUser(strusername, user).subscribe(getresult => { this.getUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getUserSuccess(getresult: any[]) {
    this.userFullDetails = [];
    if (getresult.length > 0) {
      this.userFullDetails = getresult;

      //let firstname = this.userFullDetails[0].firstname;
      //let lastname = this.userFullDetails[0].lastname;
      //let userid = this.userFullDetails[0].userid;
      //let branchid = this.userFullDetails[0].branchid;
      //let level = this.userFullDetails[0].level;
      //let email = this.userFullDetails[0].email;
      //let mobilenumber = this.userFullDetails[0].mobilenumber;
      //let active = this.userFullDetails[0].active;
      let company = this.userFullDetails[0].company;

      this.getBranchname(company,'1');
      //if (this.Branchnamedetails.length > 0) {
      //  this.userFullDetails.forEach((x) => {
      //    this.Branchnamedetails.forEach((y) => {
      //      if (x.branchid == y.branchcode) {
      //        x.branchname = y.branchname;
      //      }
      //    })
      //  });
      //}
      //let branchname = this.userFullDetails[0].branchname;
      //this.Phonenumberchange(mobilenumber);

      //this.UserEditForm.patchValue({
      //  firstname: firstname, lastname: lastname, userid: userid, branchname: branchname, level: level, email: email, active: active, company: company
      //});
    }
  }
  UpdateUser() {
    debugger
    this.spinner.show();
    this.userAddval = []; let branchname = ''; let user = ''; let manageruserid = ''; let manager2userid = ''; let manager3userid = '';
    this.submitted = true;
    if (this.UserEditForm.invalid) {
      return;
    }
    console.log(this.UserEditForm.value);
    this.userAddval.push(this.UserEditForm.value);

    let branchnameval = this.UserEditForm.value.branchname;
    this.Branchnamedetails.forEach((branch) => {
      if (branch.branchcode == branchnameval) {
        branchname = branch.branchcode;
      }
    })

    if (branchname =='') {
      this.Branchnamedetails.forEach((branch) => {
        if (branch.branchname == branchnameval) {
          branchname = branch.branchcode;
        }
      })
    }
    let managerval = this.UserEditForm.value.manager;
    this.ManagerName.forEach((manager) => {
      if (manager.emailusername == managerval) {
        manageruserid = manager.userid;
      }
    })

    if (manageruserid == '') {
      this.ManagerName.forEach((manager) => {
        if (manager.userid == managerval) {
          manageruserid = manager.userid;
        }
      })
    }
    let manager2val = this.UserEditForm.value.manager2;
    this.ManagerName.forEach((manager2) => {
      if (manager2.emailusername == manager2val) {
        manager2userid = manager2.userid;
      }
    })

    if (manager2userid == '') {
      this.ManagerName.forEach((manager2) => {
        if (manager2.userid == manager2val) {
          manager2userid = manager2.userid;
        }
      })
    }
    let manager3val = this.UserEditForm.value.manager3;
    this.ManagerName.forEach((manager3) => {
      if (manager3.emailusername == manager3val) {
        manager3userid = manager3.userid;
      }
    })

    if (manager3userid == '') {
      this.ManagerName.forEach((manager3) => {
        if (manager3.userid == manager3val) {
          manager3userid = manager3.userid;
        }
      })
    }
    let userval = this.UserEditForm.value.userid;
        user = this.userId;

    let strusername = this.Username;
    let list = [];
    this.userAddval.forEach(x => {
      let Item = {
        "firstname": x.firstname,
        "lastname": x.lastname,
        "userid": x.userid,
        "branchid": branchname,
        "level": x.level ? x.level : '',
        "email": x.email ? x.email : '',
        "employeenumber": x.employeenumber ? x.employeenumber : '',
        "mobilenumber": x.mobilenumber ? x.mobilenumber.replace(/[^0-9]+/g, '') :'' ,
        "isactive": x.isactive==true ? '1': '0',
        "company": x.company,
        "loginuser": strusername,
        "user": user,
        "manager": manageruserid ? manageruserid : '',
        "manager2": manager2userid ? manager2userid : '',
        "manager3": manager3userid ? manager3userid : '',
        "isexecutiveemail": x.isexecutiveemail == true ? '1' : '0',
      };
      list.push(Item);
    });
    let rowid = this.RowId;
    this.settingService.updateUser(list, rowid).subscribe(getresult => { this.updateUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  updateUserSuccess(getresult: any[]) {
    this.spinner.hide();
    this.userDetails = [];
    if (getresult.length > 0) {
      this.userDetails = getresult;
      let userMsg = this.userDetails[0].usermsg;
      if (userMsg == 'exist') {
        this.toastrService.warning('UserId already exists', '', this.options);
      }
      else {
        this.toastrService.success('User updated. Reload the page to see the changes !', '', this.options);
        this.getUserSuccess(getresult);
      }
    }
    this.submitted = false;
    this.openUser();
  }

  userActive() {
    let userActive = this.UserEditForm.value.isactive;
    if (userActive == true) {
      return true;
    }
    else {
      this.toastrService.warning('User will be deactivated', '', this.options);
    }
  }
  executiveemailActive() {
    let isexecutiveemailActive = this.UserEditForm.value.isexecutiveemail;
    if (isexecutiveemailActive == true) {
      return true;
    }
    else {
      this.toastrService.warning('User will not receive executive reports', '', this.options);
    }
  }
  onUserCancel() {
    if (this.RowId != undefined) {
        let userid = this.userId;
        this.getUser(userid);
    }
    this.openUser();
  }
  //onUserReset() {
  //  this.levelPlaceholder = 'Select Level *';
  //  this.branchnamePlaceholder = 'Select Branch *';
  //  this.companyPlaceholder = 'Select Company *';
  //  this.UserEditFormData();
  //    this.submitted = false;
  //  this.UserEditForm.reset();
  //  this.UserEditForm.patchValue({
  //    branchname: null,
  //    level: null,
  //    manager: null,
  //    zone: null,
  //    employeecode: null,
  //    application: null,
  //    uptoapprovelevel: null,
  //    marketing: null,
  //    company:null,
  //    isactive:true
  //  });
  //  this.branchnameactive = false;
  //}
  checkManager(evt, managerflagval) {
    debugger
    let managerselect = evt;
    if (managerselect) {
      let userid = this.userId
      let managerflag = managerflagval;
      this.settingService.checkUserManagerExist(managerselect, userid, managerflag).subscribe(getresult => { this.checkUserManagerExistSuccess(getresult, managerselect) },
        res => { this.onError(res) });
    }
  }
  checkUserManagerExistSuccess(getresult, managerselect) {
    debugger
    this.UserManagerExist = [];
    if (getresult.length > 0) {
      this.UserManagerExist = getresult;
      let managerflag = this.UserManagerExist[0].managerflag;
      let managerexistmsg = this.UserManagerExist[0].managerexistmsg;
      let manager = this.UserEditForm.value.manager;
      let manager2 = this.UserEditForm.value.manager2;
      let manager3 = this.UserEditForm.value.manager3;
      if (managerflag == '1' && managerexistmsg == 'exist') {
        this.UserEditForm.patchValue({
          manager: this.ManagerDBValue ? this.ManagerDBValue : null
        });
        this.toastrService.warning('Manager exists for Manager 2 or Manager 3', '', this.options);
      }
      else if (managerflag == '2' && managerexistmsg == 'exist') {
        this.UserEditForm.patchValue({
          manager2: this.Manager2DBValue ? this.Manager2DBValue: null
        });
        this.toastrService.warning('Manager exists for Manager or Manager 3', '', this.options);
      }
      else if (managerflag == '3' && managerexistmsg == 'exist') {
        this.UserEditForm.patchValue({
          manager3: this.Manager3DBValue ? this.Manager3DBValue : null
        });
        this.toastrService.warning('Manager exists for Manager or Manager 2', '', this.options);
      }
      else {
        if (managerflag == '1' && manager != '' && (manager2 == managerselect || manager3 == managerselect)) {
          this.UserEditForm.patchValue({
            manager: null
          });
          this.toastrService.warning('Manager exists for Manager 2 or Manager 3', '', this.options);
        }
        else if (managerflag == '2' && manager2 != '' && (manager == managerselect || manager3 == managerselect)) {
          this.UserEditForm.patchValue({
            manager2: null
          });
          this.toastrService.warning('Manager exists for Manager or Manager 3', '', this.options);
        }
        else if (managerflag == '3' && manager3 != '' && (manager == managerselect || manager2 == managerselect)) {
          this.UserEditForm.patchValue({
            manager3: null
          });
          this.toastrService.warning('Manager exists for Manager or Manager 2', '', this.options);
        }
      }
    }
  }
  activeIsexecutiveEmail(evt) {
    debugger
    if (evt) {
      let level = evt.toLowerCase();
      if (level == 'technician') {
        this.activeExecutiveEmail = false;
      }
      else {
        this.activeExecutiveEmail = true;
      }
    }
    else {
      this.activeExecutiveEmail = false;
    }
  }
  openUser() {
    let RandomId = Math.floor(Math.random() * 10);
    var tabVal = 'user';
    this.headerService.listener('User Settings');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['setting/user/' + RandomId], { queryParams: { tab: tabVal }, skipLocationChange: true }));
    // this.router.navigate(['setting/user/' + RandomId], { queryParams: { tab: tabVal }, skipLocationChange: true });
  }
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      event.preventDefault();
    }

  }
  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }
  isNumberwithdot(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && charCode != 32 && charCode != 46 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }
  isNumberPhonenumber(evt: any) {
    let mobilenumber = '';
    if (mobilenumber != undefined) {
      var phonenumberLengt = mobilenumber.length;
    }
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var cleaned = ('' + mobilenumber).replace(/\D/g, '').length;
    if (charCode > 31 && charCode != 45 && charCode != 40 && charCode != 41 && charCode != 32 && (charCode < 48 || charCode > 57) || phonenumberLengt >= 15 || cleaned >= 11) {
      evt.preventDefault();
    }
  }
  Phonenumberchange(fieldTypedtext) {
    var prefix = ('' + fieldTypedtext).replace(/\D/g, '');
    // this.phonenumberfieldtext = prefix;
    var strUsername = this.Username;
    var phonenumberlength = fieldTypedtext.length;
    if (phonenumberlength > 15) {
      return false;
    }
    let phone = '';
    var cleanedlength = ('' + prefix).replace(/\D/g, '').length;
    if (cleanedlength == 10) {
      var cleaned = ('' + prefix).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        phone = '(' + match[1] + ') ' + match[2] + '-' + match[3];
        this.UserEditForm.patchValue({
          mobilenumber: phone
        });
      }
    }
    else {
      var cleaned = ('' + prefix).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        phone = match[1] + '(' + match[2] + ') ' + match[3] + '-' + match[4];
        this.UserEditForm.patchValue({
          mobilenumber: phone
        });
      }
    }
  }
  onError(res: any) {
    this.spinner.hide();
    console.log(res);
  }
}
