import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError, interval, BehaviorSubject, Subject } from 'rxjs'
import { AppSettings } from '../Appsettings';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  formDigest: any = null;
  URI = AppSettings.OAuthSettings.redirectUri;
  bs = new BehaviorSubject(''); cv;
 /* bsSettingTabChange = new BehaviorSubject(''); cvSettingTabChange;*/
  constructor(private _http: HttpClient) {
    this.cv = this.bs.asObservable();
   // this.cvSettingTabChange = this.bsSettingTabChange.asObservable();
  }
  sendEmployeecode(employeecode) {
    this.bs.next(employeecode);
  }
  //sendbsSettingTabChange(tabVal) {
  //  this.bsSettingTabChange.next(tabVal);
  //}
  getBranchdetails(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchBranchDetails/' + strusername);
  }
  getCompanyWithBranchDetails(company): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchCompanyWithBranchDetails/' + company);
  }
  getCompanyDetails(): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchCompanyDetails');
  }
  getUsername(): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchUserName');
  }
  getUserLevel(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchUserLevel/' + strusername);
  }
  getLoginUserLevel(): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchLoginUserLevel');
  }
  getEmployeeNumber(email): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchEmployeeNumber/' + email);
  }
  /* User Settings*/
  getUser(strusername, user): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchUser/' + strusername + '/' + user);
  }
  insertUser(list): Observable<any> {
    return this._http.post(this.URI + 'api/Setting/InsertUser', list);
  }
  updateUser(list, rowid): Observable<any> {
    return this._http.put(this.URI + 'api/Setting/UpdateUser/' + rowid, list);
  }
  deleteUser(rowid, user): Observable<any> {
    return this._http.delete(this.URI + 'api/Setting/DeleteUser/' + rowid + '/' + user);
  }
  /* Manager Settings*/
  checkUserManagerExist(managerselect, userid, managerflag): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/CheckUserManagerExist/' + managerselect + '/' + userid + '/' + managerflag);
  }
  getManagerDetails(): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchManagerDetails');
  }
  getManager(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchManager/' + strusername);
  }
  insertManager(list): Observable<any> {
    return this._http.post(this.URI + 'api/Setting/InsertManager', list);
  }
  updateManager(list, managerid): Observable<any> {
    return this._http.put(this.URI + 'api/Setting/UpdateManager/' + managerid, list);
  }
  deleteManager(managerid, user): Observable<any> {
    return this._http.delete(this.URI + 'api/Setting/DeleteManager/' + managerid + '/' + user);
  }
  /* Manager Settings*/

  /*MiscellaneousLevel*/
  checkMiscellaneousLevelLink(value): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/CheckMiscellaneousLevelLink/' + value);
  }
  checkMiscellaneousLevelExist(value, company): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/CheckMiscellaneousLevelExist/'+ value + '/' + company);
  }
  getMiscellaneousLevelValue(company): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchMiscellaneousLevelValue/' + company);
  }
  getMiscellaneousLevel(): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchMiscellaneousLevel');
  }
  insertMiscellaneousLevel(list): Observable<any> {
    return this._http.post(this.URI + 'api/Setting/InsertMiscellaneousLevel', list);
  }
  updateMiscellaneousLevel(list, miscellaneouslevelid): Observable<any> {
    return this._http.put(this.URI + 'api/Setting/UpdateMiscellaneousLevel/' + miscellaneouslevelid, list);
  }
  deleteMiscellaneousLevel(miscellaneouslevelid): Observable<any> {
    return this._http.delete(this.URI + 'api/Setting/DeleteMiscellaneousLevel/' + miscellaneouslevelid);
  }
  /*Miscellaneou Master*/
  checkMiscellaneousLink(value): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/CheckMiscellaneousLink/' + value);
  }
  checkMiscellaneousValueExist(type,value ,company): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/CheckMiscellaneousValueExist/' + type + '/' + value + '/' + company);
  }
  getMiscellaneousMstValue(company, type): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchMiscellaneousMstValue/' + company + '/' + type);
  }
  getMiscellaneousMst(): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchMiscellaneousMst');
  }
  insertMiscellaneousMst(list): Observable<any> {
    return this._http.post(this.URI + 'api/Setting/InsertMiscellaneousMst', list);
  }
  updateMiscellaneousMst(list, miscellaneousmstid): Observable<any> {
    return this._http.put(this.URI + 'api/Setting/UpdateMiscellaneousMst/' + miscellaneousmstid, list);
  }
  deleteMiscellaneousMst(miscellaneousmstid): Observable<any> {
    return this._http.delete(this.URI + 'api/Setting/DeleteMiscellaneousMst/' + miscellaneousmstid);
  }
  /*Branch Master*/
  checkBranchExist(branchname,branchcode,company): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/CheckBranchExist/'+branchname + '/' + branchcode+'/' + company);
  }
  getBranchName(company): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchBranchName/' + company);
  }
  getBranchMaster(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchBranchMaster/' + strusername);
  }
  getLastBranchCode(company): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchLastBranchCode/' + company);
  }
  insertBranchMaster(list): Observable<any> {
    return this._http.post(this.URI + 'api/Setting/InsertBranchMaster', list);
  }
  updateBranchMaster(list, branchid): Observable<any> {
    return this._http.put(this.URI + 'api/Setting/UpdateBranchMaster/' + branchid, list);
  }
  deleteBranchMaster(branchid, strusername): Observable<any> {
    return this._http.delete(this.URI + 'api/Setting/DeleteBranchMaster/' + branchid + '/' + strusername);
  }
  checkBranchLinkToUser(branchcode, strusername): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/CheckBranchLinkToUser/' + branchcode+ '/' + strusername);
  }
  getFetchTableSearchList(searchcategory, searchtext): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchTableSearchList/' + searchcategory + '/' + searchtext);
  }

  /*Update User Leads Count*/
  getUpdateUserLeadsCount(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/UpdateUserLeadsCount/' + strusername);
  }
}
