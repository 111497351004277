import { Injectable }     from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree }    from '@angular/router';
import { HeaderService } from './header.service';

@Injectable({ providedIn:'root' })
export class AuthGuardService{
  level: any; levelActive: boolean;
  //canActivate(route: ActivatedRouteSnapshot,
  //  state: RouterStateSnapshot): boolean | UrlTree  {
  //  debugger
  //      //Your redirect logic/condition. I use this.
  //    //return this.levelActive;
  //    //this.router.navigate(['dashboard/approvalcenter']);
  //  //if (this.levelActive != true) {
  //  //  this.router.navigate(['dashboard/approvalcenter']);
  //  //  return false;
  //  //}
  //  //else {
  //  //  return true;
  //  //}
  //  return true;
  //  }
    //Constructor 
  constructor(private router: Router, private headerService: HeaderService) { }

  getUserLevel(authorizeUserDetails) {
    let level = authorizeUserDetails[0].level;
    if (level) {
      let levelval = level.toLowerCase();
      this.level = levelval;
      if (this.level == 'manager') {
        this.headerService.listener('All Leads');
        this.router.navigate(['dashboard']);
        this.levelActive = true;
      }
      else {
        this.levelActive = false;
      }
    }
    console.log(authorizeUserDetails);
    return this.levelActive;
  }
}
