import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError, interval, BehaviorSubject, Subject } from 'rxjs'
import { AppSettings } from '../Appsettings';

@Injectable({
  providedIn: 'root'
})
export class ExportLeadService {
  formDigest: any = null;
  URI = AppSettings.OAuthSettings.redirectUri;
  constructor(private _http: HttpClient) { }
  getExportleads(list): Observable<any> {
    return this._http.post(this.URI + 'api/ExportLeads/FetchExportLeads', list);
  }
  getExecutiveEmail(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/ExportLeads/FetchExecutiveEmail/' + strusername);
  }
  getLeadsIndividualStatusCount(list): Observable<any> {
    return this._http.post(this.URI + 'api/ExportLeads/FetchLeadsIndividualStatusCount', list);
  }
  uploadDocument(formData): Observable<any> {
    return this._http.post(this.URI + 'api/DocsUploadDownload/UploadDoc', formData);
  }
  readExportLeadsEmailDoc(filePathval: string): Observable<HttpEvent<Blob>> {
    return this._http.request(new HttpRequest(
      'GET',
      `${this.URI + 'api/DocsUploadDownload/ReadExportLeadsEmailDoc'}?file=${filePathval}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  downloadDoc(filePathval: string): Observable<HttpEvent<Blob>> {
    return this._http.request(new HttpRequest(
      'GET',
      `${this.URI + 'api/DocsUploadDownload/DownloadDoc'}?file=${filePathval}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  deleteDocumentFile(filePathval: string): Observable<any> {
    return this._http.request(new HttpRequest(
      'GET',
      `${this.URI + 'api/DocsUploadDownload/DeleteDoc'}?file=${filePathval}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  getExportEmailStatus(type): Observable<any> {
    debugger
    return this._http.get(this.URI + 'api/ExportLeads/FetchExportEmailStatus/' + type);
  }
  insertExportEmailStatus(date,flag,type): Observable<any> {
    return this._http.get(this.URI + 'api/ExportLeads/InsertExportEmailStatus/' + date + '/' + flag + '/' + type);
  }
  deleteExportEmailStatus(type): Observable<any> {
    return this._http.delete(this.URI + 'api/ExportLeads/DeleteExportEmailStatus/' + type);
  }
  getManagerEmail(fromdate, todate): Observable<any> {
    return this._http.get(this.URI + 'api/ExportLeads/FetchManagerEmail/' + fromdate + '/' + todate);
  }
  getEmployeeNameForLead(fromdate, todate): Observable<any> {
    return this._http.get(this.URI + 'api/ExportLeads/FetchEmployeeNameForLead/' + fromdate + '/' + todate);
  }
  getFetchTableSearchList(searchcategory, searchtext): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchTableSearchList/' + searchcategory + '/' + searchtext);
  }
}
