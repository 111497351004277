import { Component, OnInit } from '@angular/core';
import { AppComponentService } from 'src/app/services/appcomponenet.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  myDate = new Date(); currentDate: any; currentYear: any; versionname: any; authorizeUserDetails: any[]; copyright: any;
    constructor(private appComponentService: AppComponentService, private router: Router,
      private activatedroute: ActivatedRoute, private datePipe: DatePipe
    ) { }
  ngOnInit() {
    this.currentDate = this.datePipe.transform(this.myDate, 'MM-dd-yyyy');
    this.currentYear = new Date().getFullYear();
    this.getAuthorizeUserDetails();
    this.getAppVersion();
  }
  getAuthorizeUserDetails() {
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    this.authorizeUserDetails = [];
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      this.copyright = this.authorizeUserDetails[0].copyright;
    }
  }
  getAppVersion() {
    this.appComponentService.getAppVersion().subscribe(getresult => { this.getAppVersionSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAppVersionSuccess(getresult:any[]) {
    if (getresult.length > 0) {
      this.versionname = getresult[0].appversionname;
    }
  }
  onError(res: any) {
    console.log(res);
  }
}
