export class User {
  displayName: any;
  email: any;
  avatar: any;
  timeZone:any;
}
export class SearchedField {
  SearchedFieldArray = [{ "searchfieldName": "Business Unit", "searchfieldVal": "businessunit" },
  { "searchfieldName": "Company", "searchfieldVal": "company" }, { "searchfieldName": "Manager", "searchfieldVal": "manager" }];
}
export class SearchType {
  SearchTypeArray = [{ "searchTypeName": "Global Search", "searchTypeVal": "globalsearch" },
  { "searchTypeName": "Column Search", "searchTypeVal": "columnsearch" }];
}
export class UserIdFirstLastService {
  generateUserId(firstName: string, lastName: string): string {
    const userId = `${firstName.charAt(0).toLowerCase()}${lastName.toLowerCase().replace(/[\s\W]/g, '')}`;
    return userId;
  }
}
export class UserIdFirstLastDuplicateService {
  generateUserId(firstName: string, lastName: string, uniqueCount:any): string {
    const userId = `${firstName.substring(0, uniqueCount+1).toLowerCase()}${lastName.toLowerCase().replace(/[\s\W]/g, '')}`;
    return userId;
  }
}
export class UserIdFirstService {
  generateUserId(firstName: string): string {
    const userId = `${firstName.toLowerCase().replace(/[\s\W]/g, '')}`;
    return userId;
  }
}
