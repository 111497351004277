import { Component, ViewChild, ElementRef, ChangeDetectorRef, OnInit, ViewContainerRef, TemplateRef, Input, AfterViewInit, HostListener, OnDestroy, Renderer2} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponentService } from 'src/app/services/appcomponenet.service';
import { HeaderService } from 'src/app/services/header.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Subject, Subscription} from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { NewEvent } from '../../Interface/new-event';
/*import { GraphService } from '../../services/graph.service';*/
import { AppSettings } from '../../Appsettings';
import { MdbTableDirective, MdbTablePaginationComponent, ToastService } from 'ng-uikit-pro-standard';
import { PageIndexService } from '../../services/pageindex.service';
import { CdkDragDrop, moveItemInArray, CdkDragStart, CdkDragRelease } from '@angular/cdk/drag-drop';
import * as moment from 'moment';
import { LeadGenerationService } from '../../services/leadgeneration.service';
import { CryptoService } from '../../services/crypto.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ExcelService } from '../../services/excel.service';
import { DatePipe } from '@angular/common';
import { SearchedField, SearchType } from '../../Interface/user';
import { DynamicsService } from '../../services/dynamics.service';
declare var $;

@Component({
  selector: 'app-leadgeneration',
  templateUrl: './leadgeneration.component.html',
  styleUrls: ['./leadgeneration.component.scss']
})
export class LeadGenerationComponent implements OnInit, AfterViewInit, OnDestroy{
    submitted = false;contactHeader: boolean;show: boolean;saveViewSavebtn: boolean;PreviewData: any;
    imageUrl: any = "assets/user.png";
    //showMsg: any;
    //showContactAssignMsg: any;
    //showviewMsg: any;
    profileForm: FormGroup;masterSelected: boolean;allLeadlist: any[];allUsername: any[];deleteLeaddetails: any[];
    Username: any;getLeadFlag: any;allLeadlistData: any[];responseData: any[];
    p: number = 1;priorityDefault: any;useridLevel: string;userNameFirstOption: string;
  dtTrigger: Subject<any> = new Subject(); ComponetgettingFlag: any=''; authorizeUserDetails: any[];

   dataTablesearchvalue: any; dataTablecolshortindex: any; dataTablecolshortformat: any; dropdownList = []; selectedItems = [];
  dropdownSettings = {};selectedDropdownlist: any[];finalselectedDropdownlist: any[];viewDetails: any[];
  createView: boolean; deleteViewbool: boolean; clearViewbool: boolean; viewFullResult: any;
    //public viewFullResult: Array<any> = [];
    subscription: Subscription;
  private unsubscribe$: Subject<any> = new Subject<any>(); allLeadCompareList: any[];

  @ViewChild('viewName', { static: false }) viewName: ElementRef;
  @ViewChild('vcSaveViewModal', { read: ViewContainerRef, static: true }) vcSaveViewModal: ViewContainerRef;

  leadsoureOption: string;leadStatusOption: string;
  createContactList: any[]; fieldSearchDataCustomer: any[]; fieldSearchDataCS: any[]; fieldSearchDataEmail: any[];
  fieldSearchDataCompany: any[]; fieldSearchDataSiteaddress: any[]; fieldSearchDataPhonenumber: any[]; fieldSearchDataOfficenumber: any[]; 
  Leadsourcearray: any[]; Leadstatusarray: any[]; phonenumberfieldtext: string; officenumberfieldtext: string;
  //public showCreateContactMsg: string;
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  // phonePattern = "^([\d]{6}|((\([\d]{3}\)|[\d]{3})( [\d]{3} |-[\d]{3}-)))[\d]{4}$";
  //zipPattern = "^(?(?=\d{5})(\d{5}|[a-zA-Z0-9]{7}))$"
  //@Input() contactid: any;
  model = new NewEvent(); submitnewlead: any = '0';
  phonenumbersmltime: any = ''; officenumbersmltime: any = '';
  //New Table Added
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild('row', { static: true }) row: ElementRef;


  searchText: any;previous: string;isDesc: boolean;column: any;
  // pager object
  pager: any = {};
  // paged items
  pagedallLeadlist: any[];
  zoomWidth: number; pagenumber: number; theight: any;
    
/*dtOptions: DataTables.Settings = {};*/

  tableData: any[] = [
    { 'id': 1, 'header': 'Company', 'value': 'company', 'short': 'companyshow' },
    { 'id': 2, 'header': 'Site Address', 'value': 'siteaddress', 'short': 'siteaddressshow' },
    { 'id': 3, 'header': 'Contact Name', 'value': 'customercontactname', 'short': 'customercontactnameshow' },
    { 'id': 4, 'header': 'Phone #', 'value': 'phonenumber', 'short': 'phonenumbershow' },
    { 'id': 5, 'header': 'Email', 'value': 'email', 'short': 'emailshow' },
    { 'id': 6, 'header': 'City', 'value': 'city', 'short': 'cityshow' },
    { 'id': 7, 'header': 'State', 'value': 'state', 'short': 'stateshow' },
    { 'id': 8, 'header': 'Zip', 'value': 'zip', 'short': 'zipshow' },
    { 'id': 9, 'header': 'Date', 'value': 'date', 'short': 'dateshow' },
    { 'id': 10, 'header': 'Office', 'value': 'office', 'short': 'officeshow' },
    { 'id': 11, 'header': 'Lead Status', 'value': 'leadstatus', 'short': 'leadstatusshow' },
    { 'id': 12, 'header': 'Lead Recipient (Salesperson Email)', 'value': 'salespersonemail', 'short': 'salespersonemailshow' },
    { 'id': 13, 'header': 'Priority Lead', 'value': 'prioritylead', 'short': 'priorityleadshow' },
    { 'id': 14, 'header': 'Dynamics Lead Id', 'value': 'dynamicsleadid', 'short': 'dynamicsleadidshow' },
    { 'id': 15, 'header': 'Line of Business', 'value': 'lineofbusiness', 'short': 'lineofbusinessshow' },
    { 'id': 16, 'header': 'Existing Acount', 'value': 'existingacount', 'short': 'existingacountshow' },
    { 'id': 17, 'header': 'Lead Category', 'value': 'leadcategory', 'short': 'leadcategoryshow' },
    { 'id': 18, 'header': 'User Company', 'value': 'usercompany', 'short': 'usercompanyshow' },
    { 'id': 19, 'header': 'Business Unit', 'value': 'businessunit', 'short': 'businessunitshow' },
    { 'id': 20, 'header': 'Manager', 'value': 'manager', 'short': 'managershow' },
    { 'id': 21, 'header': 'Note', 'value': 'note', 'short': 'noteshow' }];

  OutlookemailList: any[]; fullEmployeename: any; userEmail: any; submitLeadlist: any[];
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };
  officenoextensionEnable: boolean; searchtextval: boolean = false; userLevel: any;
  @ViewChild('SaveViewModal', { static: true }) SaveViewModal: TemplateRef<any>;
  SearchedFieldName: any = null; /*searchFieldText: any = '';*/ downloadExcel: boolean = false; TypeofSearch: any = null;
  SearchedFieldArray: any[]; SearchTypeArray: any[];

  FetchTableSearchList: any[]; loadGifTableSearch: boolean; keywordTableSearch = 'searchoption'; historyHeadingTableSearch: any; searchTextFlag: any = '0';
  leadDataForDynamics: any[]; IsDynamicswarning: boolean = false;
  scrollCheck: boolean; NewUserDetails: any[];
  /*alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');selectedLetter: string = '';*/
    constructor(private cd: ChangeDetectorRef, public formBuilder: FormBuilder,private router: Router, private activatedroute: ActivatedRoute,
      private appComponentService: AppComponentService, private headerService: HeaderService, private leadGenerationservice:LeadGenerationService,
      private dashBoardservice: DashboardService/*, private graphService: GraphService*/, private pagerService: PageIndexService, public renderer2: Renderer2,
      private toastrService: ToastService, private cryptoservice: CryptoService, private spinner: NgxSpinnerService, private excelService: ExcelService,
      public datepipe: DatePipe, public dynamicsService:DynamicsService) {
        this.masterSelected = false;
        this.allLeadlistData = [];
        this.responseData = [];
    }
 
  ngOnInit() {
    debugger
    this.SearchedFieldArray = [];
    this.SearchTypeArray = [];
    let SearchedFieldVal = new SearchedField();
    this.SearchedFieldArray = SearchedFieldVal.SearchedFieldArray;
    let SearchTypeVal = new SearchType();
    this.SearchTypeArray = SearchTypeVal.SearchTypeArray;
    this.TypeofSearch = 'columnsearch';
    this.loadTypeOfSearch();
    this.headerService.sendEmployeecode('');
    this.headerService.listener('All Leads');
        this.dropdownSettings = {
            singleSelection: true,
            enableCheckAll: false,
            idField: 'leadviewid',
            textField: 'viewname',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          closeDropDownOnSelection: true
        };
        this.createView = true;
        this.deleteViewbool = true;
        this.clearViewbool = true;
   
        this.priorityDefault = 'Low';
        this.getAuthorizeUserDetails();
        this.contactHeader = true;
        //this.allContactsService.cvView.pipe(
        //    takeUntil(this.unsubscribe$)
        //)
        //    .subscribe((data) => {
        //      this.viewFullResult = data;
        //    });
   /* this.headerService.bsaddContact.pipe(takeUntil(this.unsubscribe$)).subscribe(x => x ? this.loadCreateContactmodal() : null);*/

      //.subscribe(x => console.log(x));
        //if (this.viewFullResult.viewname != '' && this.viewFullResult.viewname != undefined) {
        //    this.viewResult.push(this.viewFullResult);
        //}
        //var flag = '';
        //if (this.viewFullResult.viewname == undefined) {
        //    flag = '1';
        //}
        //else {
        //    flag = '2';
        //}
    //this.registerFormData();

    this.leadGenerationservice.bsLeadsFromExternal.subscribe(x => this.getAuthorizeUserDetails());

    let browserZoomLevel = Math.ceil(((window.outerWidth - 10) / window.innerWidth) * 100);
    if (window.innerHeight >= 1000) {
      this.zoomTheLrgscreen(browserZoomLevel); 
    }
    else {
      this.zoomThescreen(browserZoomLevel); 
    }
     
  }
  getAuthorizeUserDetails() {
    debugger
        this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
            res => { this.onError(res) });
    }
  getAuthorizationForUserSuccess(getresult: any[]) {
    debugger
    let Level = ''; let userEmail = '';
        this.authorizeUserDetails = [];
        if (getresult.length > 0) {
            this.authorizeUserDetails = getresult;
            var UserId = this.authorizeUserDetails[0]['userid'];
          Level = this.authorizeUserDetails[0]['level'];
          if (Level) {
            Level = Level.toLowerCase();
            this.userLevel = Level;
          }
          userEmail = this.authorizeUserDetails[0]['email'];
          if (userEmail) {
            userEmail = userEmail.toLowerCase();
          }

            if (UserId != "") {
                this.Username = UserId;
                this.useridLevel = Level;
          }
          if (userEmail != "") {
            this.userEmail = userEmail;
          }
    }
    //this.loadCreateContactmodal();
    this.getLeadFlag = '1';
        let flagValue = '';
      this.activatedroute.queryParams.subscribe(params => this.ComponetgettingFlag = params.contactlevelid);
        //this.activatedroute.params.subscribe(params => this.ComponetgettingFlag = params.id);
        let strusername = this.Username;
        if (this.ComponetgettingFlag == '1') {
          flagValue = this.getLeadFlag;
        }
        else {
            flagValue = this.ComponetgettingFlag;
        }
    this.loadTableData();
    }
  loadTableData() {
    let strusername = this.Username;
    this.loadAllLeads(strusername, '1');
    this.loadView(strusername, '1');
  }
  loadAllLeads(strusername, flagValue) {
    this.spinner.show();
    this.leadGenerationservice.getAllLeads(strusername, flagValue).subscribe(getresult => { this.getAllLeadSuccess(getresult) },
            res => { this.onError(res) });
    }
  getAllLeadSuccess(getresult: any[]) {
    this.spinner.hide();
        this.contactHeader = true;
        this.allLeadlist = [];
        this.responseData = [];
    if (getresult.length > 0) {
      this.downloadExcel = true;
            this.responseData = getresult;
            for (var i = 0; i < this.responseData.length; i++) {
                this.allLeadlist.push(this.responseData[i])
            }
      }
      this.mdbTable.setDataSource(this.allLeadlist);
      this.allLeadlist = this.mdbTable.getDataSource();
      this.setPage(1);
    this.previous = this.mdbTable.getDataSource();
    //this.scrollCheck = false;
    //this.pagedallLeadlist = this.allLeadlist.slice(0, AppSettings.OAuthSettings.lazyLoadingCount);
  }
  openViewPopup() {
    let view = this.SaveViewModal.createEmbeddedView(null);
    this.vcSaveViewModal.insert(view);
    this.SaveViewModal.elementRef.nativeElement.previousElementSibling.classList.remove('fade');
    this.SaveViewModal.elementRef.nativeElement.previousElementSibling.classList.add('modal-open');
    this.SaveViewModal.elementRef.nativeElement.previousElementSibling.style.display = 'block';
    document.getElementById('viewName').focus();
  }
  loadView(strusername, flagValue) {
      this.leadGenerationservice.getView(strusername, flagValue).subscribe(getresult => { this.getViewSuccess(getresult) },
            res => { this.onError(res) });
    }
    getViewSuccess(getresult: any[]) {
        this.viewDetails = [];
        this.dropdownList = [];
        this.selectedItems = [];
        if (getresult.length > 0) {
            this.viewDetails = getresult;
          this.dropdownList = this.viewDetails;
        }  
    }
    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.unsubscribe$.next('');
        this.unsubscribe$.complete();
    }

  openLeadPage(leadid, leadname) {
   // this.router.navigate(['/createlead', { leadlevel: '1', leadId: leadid,leadFlag:'1'}], { skipLocationChange: true });
    this.router.navigate(['/createlead'], { queryParams: { leadlevel: this.cryptoservice.Encrypt('1'), leadId: this.cryptoservice.Encrypt(leadid), leadFlag: this.cryptoservice.Encrypt('1') } });
    this.headerService.listener('Update Lead');
    }

    onSelectFile(event) {
        let reader = new FileReader(); // HTML5 FileReader API
        let file = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            reader.readAsDataURL(file);

            // When file uploads set it to file formcontrol
            reader.onload = () => {
                this.imageUrl = reader.result;
                this.profileForm.patchValue({
                    file: reader.result
                });
            }
            // ChangeDetectorRef since file is loading outside the zone
            this.cd.markForCheck();
        }
    }
  deleteLead() {
        let strusername = this.Username;
      let leadid = this.allLeadlistData[0].leadid;
      if (confirm("Are you sure you want to delete the lead?")) {
        this.leadGenerationservice.deleteLead(strusername, leadid).subscribe(getresult => { this.deleteLeadSuccess(getresult) },
            res => { this.onError(res) });
                return true;
      }
      else {
        this.loadLeadsGrid();
        return false;
      }
    }
  deleteLeadSuccess(getresult: any[]) {
        this.contactHeader = true;
        this.allLeadlist = [];
        this.responseData = [];
        if (getresult.length > 0) {
            this.responseData = getresult;
        }

      this.toastrService.error('Lead deleted', '', this.options);
    this.loadLeadsGrid();
    this.leadGenerationservice.sendLeadcount(getresult.length);
  }
  onItemSelect(item: any) {
    if (this.dropdownList.length > 0 && item) {
      this.selectedItems = this.dropdownList;
      this.deleteViewbool = false;
      this.clearViewbool = false;
      this.searchtextval = true;
    }
    else {
      this.deleteViewbool = true;
      this.clearViewbool = true;
      this.searchtextval = false;
    }
    this.selectedDropdownlist = [];
    if (item) {
      this.selectedDropdownlist.push(item);

      for (var i = 0; i < this.dropdownList.length; i++) {
        if (this.dropdownList[i].contactviewid == this.selectedDropdownlist[0].contactviewid) {
          //this.allContactsService.sendViewcontact(this.dropdownList[i]);
          let searchText = this.dropdownList[0].searchvalue;
          let columnHeader = this.dropdownList[0].shortvalue;
          let columnFormat = this.dropdownList[0].shortformat;
          this.searchText = searchText;
          let property = columnHeader + ',' + columnFormat;
          this.searchItems();
          this.dtTablesort(property, 1);
        }
      }
      //this.loadContactsGrid();
    }
  }
  onItemDeSelect(item: any) {
    if (item) {
      this.deleteViewbool = false;
      this.clearViewbool = false;
    }
    else {
      this.deleteViewbool = true;
      this.clearViewbool = true;
    }
    this.searchtextval = false;
    this.selectedDropdownlist = [];
    this.leadGenerationservice.sendViewLead([]);
    this.loadLeadsGrid();
  }

  onSelectAll(items: any) {
    this.selectedDropdownlist = [];
    if (items) {
      this.selectedDropdownlist.push(items);
    }
    console.log(items);
  }
  ViewNameChange(viewVal) {
    if (viewVal) {
      this.saveViewSavebtn = true;
    }
    else {
      this.saveViewSavebtn = false;
    }
  }
  saveViews(viewNamevalue) {
        let flagValue = '';
        let strusername = this.Username;
            flagValue = '1';
      let dataTablesearchvalue = this.dataTablesearchvalue;
      let dataTablecolshortindex = ''; let dataTablecolshortformat = 0;
      if (this.dataTablecolshortindex) {
        dataTablecolshortindex = this.dataTablecolshortindex;
      }
      else {
        dataTablecolshortindex = 'fullname';
      }
      if (this.dataTablecolshortformat) {
        dataTablecolshortformat = this.dataTablecolshortformat;
      }
      else {
        dataTablecolshortformat = 1;
      }
      let list = [];
        let Item = {
          "viewname": viewNamevalue,
          "flagValue": flagValue,
          "searchvalue": dataTablesearchvalue,
          "shortformat": dataTablecolshortformat?dataTablecolshortformat.toString():'0',
          "shortindex": dataTablecolshortindex,
          "strusername": strusername
        }
        list.push(Item);
      this.leadGenerationservice.saveView(list).subscribe(getresult => { this.saveViewSuccess(getresult) },
            res => { this.onError(res) });
    }
  saveViewSuccess(getresult: any) {
    this.closeViews();
        this.viewDetails = [];
        this.dropdownList = [];
        if (getresult.length > 0) {
            this.viewDetails = getresult;
            this.dropdownList = this.viewDetails;
        }

    this.toastrService.success('View created', '', this.options);
    }
    deleteView() {
        if (confirm("Are you sure you want to delete the selected view?")) {
          let leadviewid = this.dropdownList[0].leadviewid;
          let viewname = this.dropdownList[0].viewname;
                var strusername = this.Username;
          let componetgettingFlag = this.ComponetgettingFlag;
          this.leadGenerationservice.deleteView(leadviewid, viewname, strusername, componetgettingFlag).subscribe(getresult => { this.deleteViewSuccess(getresult) },
                    res => { this.onError(res) });
                return true;
            }
            else {
                return false;
            }
        }
    deleteViewSuccess(getresult: any) {
        this.viewDetails = [];
        this.dropdownList = [];
        this.selectedDropdownlist = [];
        if (getresult.length > 0) {
            this.viewDetails = getresult;
            this.dropdownList = this.viewDetails;
        }
      this.leadGenerationservice.sendViewLead([]);

      this.toastrService.error('View deleted', '', this.options);
      this.loadLeadsGrid();
    }
  clearView() {
      this.leadGenerationservice.sendViewLead([]);
      this.loadLeadsGrid();
    }
    closeViews() {
      this.viewName.nativeElement.value = '';
      this.vcSaveViewModal.clear();
      this.saveViewSavebtn = false;
  }
  checkUncheckAll(e) {
    this.allLeadlistData = [];
    for (var i = 0; i < this.allLeadlist.length; i++) {
      this.allLeadlist[i].isselectedlead = this.masterSelected;
      this.allLeadlistData.push(this.allLeadlist);
      if (this.allLeadlist[i].isselectedlead) {
        this.contactHeader = false;
      }
      else {
        this.contactHeader = true;
        this.loadLeadsGrid();
      }
    }
  }
  isAllSelected(e, data) {
    debugger
    if (e.target.checked) {
      this.allLeadlistData.push(data);
      if (this.allLeadlistData.length > 1) {
        this.pagedallLeadlist.forEach(child => {
          child.isselectedlead = false
        })
        this.pagedallLeadlist.forEach(child => {
          if (data.leadid == child.leadid) {
            child.isselectedlead = true;
          }
        })
        this.allLeadlistData = [];
        this.allLeadlistData.push(data);
      }
      else {
        this.allLeadlistData = [];
        this.allLeadlistData.push(data);
      }
    }
    else {
      this.allLeadlistData.splice(this.allLeadlistData.indexOf(data), 1);
    }
    if (this.allLeadlistData.length > 0) {
      this.contactHeader = false;
    }
    else {
      this.contactHeader = true;
      this.loadLeadsGrid();
    }
    this.masterSelected = this.allLeadlist.every(function (item: any) {
      return item.isselectedlead == true;
    })

  }
  isManagerAllSelected(e, data) {
    debugger
    if (e.target.checked) {
       this.allLeadlistData.push(data);
    }
    else {
      this.allLeadlistData.splice(this.allLeadlistData.indexOf(data), 1);
    }
    if (this.allLeadlistData.length > 0) {
      this.contactHeader = false;
    }
    else {
      this.contactHeader = true;
      this.loadLeadsGrid();
    }
    this.masterSelected = this.allLeadlist.every(function (item: any) {
      return item.isselectedlead == true;
    })

  }
  deselectLead() {
    this.allLeadlistData = [];
    this.loadLeadsGrid();
  }

  loadLeadsGrid() {
    this.IsDynamicswarning == false;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['dashboard']));
      this.headerService.listener('All Leads');

  }
  //New Table Added
  searchItems() {
    debugger
    let SearchedFieldName = this.SearchedFieldName;
    let TypeofSearch = this.TypeofSearch;
    const prev = this.mdbTable.getDataSource();
    if (this.searchTextFlag == '1') {
      this.searchText = this.searchText.searchoption;
    }
    else {
      this.searchText = this.searchText;
    }
    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.allLeadlist = this.mdbTable.getDataSource();
      this.setPage(1);
    }
  else if (this.searchText && TypeofSearch == 'globalsearch') {
      this.spinner.hide();
      this.allLeadlist = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
    else {
      this.spinner.hide();
      if (SearchedFieldName == 'company') {
        SearchedFieldName = 'usercompany';
      }
      this.allLeadlist = this.mdbTable.searchLocalDataByFields(this.searchText.toLowerCase(), [SearchedFieldName]);
      //this.userDetails = this.mdbTable.getDataSource().filter((item: any) => {
      //  /* Customize this filter logic based on your data structure.*/
      //  return item.businessunit.toLowerCase().includes(this.searchFieldText.toLowerCase())
      //    || item.company.toLowerCase().includes(this.searchFieldText.toLowerCase())
      //    || item.manager.toLowerCase().includes(this.searchFieldText.toLowerCase());
      //});
      this.mdbTable.setDataSource(prev);
    }
    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.setPage(1);
    });
    if (this.searchText != '') {
      this.createView = false;
    }
    else {
      this.createView = true;
    }
    console.log(this.allLeadlist.length);
    this.spinner.hide();
    this.downloadExcel = true;
    this.dataTablesearchvalue = this.searchText;
  }
  ngAfterViewInit() {
    this.cd.detectChanges();
  }
  dtTablesort(property, flag) {
    let direction = 0;
    if (flag == 0) {
      this.isDesc = !this.isDesc; //change the direction    
      this.column = property;
       direction = this.isDesc ? 1 : -1;

      this.pagedallLeadlist.sort(function (a, b) {
        if (a[property] < b[property]) {
          return -1 * direction;
        }
        else if (a[property] > b[property]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
      console.log(property, direction);
      this.dataTablecolshortindex = property;
      this.dataTablecolshortformat = direction;  
    }
    else {
      let propertyval = property;
      property = propertyval.slice(0, propertyval.indexOf(','))
      let isDescVal  = propertyval.slice(propertyval.lastIndexOf(',') + 1);
      if (isDescVal == '1') {
        this.isDesc = true;
      }
      else {
        this.isDesc = false;
      }
      // this.isDesc = !this.isDesc; //change the direction
      this.column = property;
       direction = this.isDesc ? 1 : -1;

      this.pagedallLeadlist.sort(function (a, b) {
        if (a[property] < b[property]) {
          return -1 * direction;
        }
        else if (a[property] > b[property]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    }
  };

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.zoomWidth = Math.ceil(((event.target.window.outerWidth - 10) / event.target.window.innerWidth) * 100);
    this.zoomThescreen(this.zoomWidth);
    this.setPage(1);
  }

  setPage(page: number) {
    debugger
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }

    // get pager object from service
    this.pager = this.pagerService.getPager(this.allLeadlist.length, page, this.pagenumber);

    // get current page of items
    this.pagedallLeadlist = this.allLeadlist.slice(this.pager.startIndex, this.pager.endIndex + 1);

    //Lazy loading code for page number
    //this.pagedallLeadlist = this.allLeadlist.slice(0, this.allcontactcount);
  }
  zoomThescreen(browserZoomLevel) {
    if (browserZoomLevel >= 100 && browserZoomLevel <= 105) {
      this.pagenumber = 11;
      //this.theight = 403;
    } else if (browserZoomLevel >= 90 && browserZoomLevel <= 95) {
      this.pagenumber = 13;
      //this.theight = 430;
    } else if (browserZoomLevel >= 80 && browserZoomLevel <= 85) {
      this.pagenumber = 16;
     // this.theight = 450;
    } else {
      this.pagenumber = 19;
      //this.theight = 550;
    }
  }
  zoomTheLrgscreen(browserZoomLevel) {
    if (browserZoomLevel >= 100 && browserZoomLevel <= 105) {
      this.pagenumber = 18;
     // this.theight = 403;
    } else if (browserZoomLevel >= 90 && browserZoomLevel <= 95) {
      this.pagenumber = 20;
      //this.theight = 430;
    } else if (browserZoomLevel >= 80 && browserZoomLevel <= 85) {
      this.pagenumber = 23;
      //this.theight = 450;
    } else {
      this.pagenumber = 26;
      //this.theight = 550;
    }
  }
  //Dragable Contact Table Starts
  dropRow(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.pagedallLeadlist, event.previousIndex, event.currentIndex);
  }
  dropCol(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.tableData, event.previousIndex, event.currentIndex);
    console.log(this.tableData, this.pagedallLeadlist)
  }
  mouseDown(event, el: any = null) {
    el = el || event.target
    //this.pos = {
    //  x: el.getBoundingClientRect().left - event.clientX + 'px',
    //  y: el.getBoundingClientRect().top - event.clientY + 'px',
    //  width: el.getBoundingClientRect().width + 'px'
    //}
  }
  onDragRelease(event: CdkDragRelease) {
    this.renderer2.setStyle(event.source.element.nativeElement, 'margin-left', '0px')
  }

  //emailSent() {
  //  if (this.OutlookemailList != undefined) {
  //    if (this.OutlookemailList.length > 0) {
  //      for (var i = 0; i < this.OutlookemailList.length; i++) {
  //        let subject = this.OutlookemailList[i].subject;
  //        //var from = this.OutlookemailList[i].from.emailAddress.address;
  //        console.log(subject);
  //        if (subject != '') {
  //          var id = this.OutlookemailList[i].id;
  //          var parentFolderId = this.OutlookemailList[i].parentFolderId;
  //          var outlookemailid = id;
  //          this.graphService.SendEmail(outlookemailid);
  //          console.log(id);
  //        }
  //      }
  //    }
  //  }
  //}
  openCreateLead() {
    /*this.sidenav.hide();*/
    this.router.navigateByUrl('/').then(() =>
      this.router.navigate(['createlead']));

    this.headerService.listener('Create Lead');
  }
  loadTypeOfSearch() {
    debugger
    let TypeofSearch = this.TypeofSearch;
    if (TypeofSearch == 'globalsearch') {
      this.SearchedFieldName = null;
    }
    else if (TypeofSearch == null) {
      this.clearSearch();
    }
    else {
      this.SearchedFieldName = 'businessunit';
    }
  }
  loadSearchedField() {
    debugger
    let SearchedFieldName = this.SearchedFieldName;
    if (SearchedFieldName == null) {
      this.clearSearch();
    }

  }
  getFetchTableSearchListSuccess(getresult: any[]) {
    debugger
    this.loadGifTableSearch = false;
    this.FetchTableSearchList = [];
    if (getresult.length > 0) {
      this.FetchTableSearchList = getresult;
    }
  }
  getSelectedSearchText(evt) {
    if (evt) {
      this.searchTextFlag = '1';
    }
  }
  getSearchTextChange(evt) {
    this.loadGifTableSearch = true;
    let searchcategory = this.SearchedFieldName;
    let searchtext = '';
    if (evt) {
      searchtext = evt;
    }
    else {
      searchtext = null;
    }
    this.leadGenerationservice.getFetchTableSearchList(searchcategory, searchtext).subscribe(getresult => { this.getFetchTableSearchListSuccess(getresult) },
      res => { this.onError(res) });
  }
  FocusedSearchTextChange(evt) {
    this.historyHeadingTableSearch = null;
    this.loadGifTableSearch = true;
    let searchcategory = this.SearchedFieldName;
    let searchtext = null;
    this.leadGenerationservice.getFetchTableSearchList(searchcategory, searchtext).subscribe(getresult => { this.getFetchTableSearchListSuccess(getresult) },
      res => { this.onError(res) });
  }
  ClearedSearchTextChange() {
    this.searchTextFlag = '0';
    this.FetchTableSearchList = [];
    this.loadGifTableSearch = false;
    this.spinner.hide();
    this.loadTableData();
    this.pager = -1;
  }
  clearSearch() {
    debugger
    this.searchText = '';
    this.TypeofSearch = null;
    this.SearchedFieldName = null;
    this.downloadExcel = false;
    this.spinner.hide();
    this.loadTableData();
    this.pager = -1;
  }
  exportExcelLeads() {
    debugger
    if (this.downloadExcel == true) {
      let filterExportLeads = this.allLeadlist.map(function (obj) {
        return {
          fullname: obj.fullname,
          company: obj.company,
          siteaddress: obj.siteaddress,
          customercontactname: obj.customercontactname,
          phonenumber: obj.phonenumber,
          email: obj.email,
          city: obj.city,
          state: obj.state,
          zip: obj.zip,
          date: obj.date,
          office: obj.office,
          leadstatus: obj.leadstatus,
          salespersonemail: obj.salespersonemail,
          prioritylead: obj.prioritylead == 'Y' ? 'Yes' : 'No',
          dynamicsleadid: obj.dynamicsleadid,
          existingacount: obj.existingacount == 'Y'?'Yes':'No',
          lineofbusiness: obj.lineofbusiness,
          leadcategory: obj.leadcategory,
          usercompany: obj.usercompany,
          businessunit: obj.businessunit,
          manager: obj.manager,
          note: obj.note
        }
      });
      if (filterExportLeads) {
        //this.downloadFile(filterExportLeads);
        let excelfilename = 'Export Leads';
        let excelHeaders = ['Name', 'Company', 'Site Address', 'Contact Name', 'Phone #', 'Email', 'City',
          'State', 'Zip', 'Date', 'Office', 'Lead Status', 'Lead Recipient (Salesperson Email)', 'Priority Lead',
          'Dynamics Lead Id', 'Existing Account', 'Line Of Business', 'Lead Category', 'User Company', 'Business Unit', 'Manager', 'Note'];
        this.excelService.exportAsExcelFile(filterExportLeads, this.Username + '(' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + ')' + ' ' + excelfilename, excelHeaders);
        this.toastrService.success('Excel exported', '', this.options);
      }
    }
   /* this.clearSearch();*/
  }
  pushLeadToDynamics() {
    debugger
    this.allLeadlistData.every(data => {
      if (data.dynamicsleadid == '' || data.dynamicsleadid == undefined) {
        if (data.salespersonemail) {
        }
        else {
          this.allLeadlistData.splice(this.allLeadlistData.indexOf(data), 1);
          let index = this.pagedallLeadlist.indexOf(data);
          this.pagedallLeadlist.splice(index, 1);
          this.toastrService.warning('Lead(s) without a salesperson email have not been exported!', '', this.options);
        }
      }
      else {
        this.IsDynamicswarning = true;
        this.toastrService.warning('Lead(s) has been previously exported to Dynamics!', '', this.options);
        this.loadLeadsGrid();
        return;
        
      }
      
    })
    if (this.allLeadlistData.length > 0) {
      this.contactHeader = false;
    }
    else {
      this.contactHeader = true;
      this.loadLeadsGrid();
    }
    this.masterSelected = this.allLeadlist.every(function (item: any) {
      return item.isselectedlead == true;
    })
    if (this.IsDynamicswarning == false && this.allLeadlistData.length > 0) {
      this.spinner.show();
      let strusername = this.Username;
      let list = [];
      this.allLeadlistData.forEach(x => {
        let Item = {
          "leadid": x.leadid ? x.leadid.toString() : '0',
          "companyname": x.company ? x.company : '',
          "firstname": x.customercontactname ? x.customercontactname : '',
          "lastname": x.company ? x.company : '',
          "email": x.email ? x.email : '',
          "city": x.city ? x.city : '',
          "state": x.state ? x.state : '',
          "zip": x.zip ? x.zip : '',
          "siteaddress": x.siteaddress ? x.siteaddress : '',
          "phonenumber": x.phonenumber ? x.phonenumber : '',
          "jobtitle": x.jobtitle ? x.jobtitle : '',
          "salespersonemail": x.salespersonemail ? x.salespersonemail : '',
          "subject": x.customercontactname ? x.customercontactname : '',
          "note": x.note ? x.office ? x.note + ' ' +'(' + x.office + ')' : x.note : '',
          "employeereferal":x.employeereferal ? x.employeereferal : '',
          "userid": strusername,
          "useremail": this.userEmail ? this.userEmail:''
        }
        list.push(Item);
      });
      this.dynamicsService.createLeadToDynamics(list).subscribe(getresult => { this.createLeadToDynamicsSuccess(getresult) },
        res => { this.onError(res) });
    }
  }
  createLeadToDynamicsSuccess(getresult:any) {
    debugger
    this.spinner.hide();
    if (getresult == 'Lead created') {
      this.toastrService.success('Lead(s) with a recipient email have been exported to Dynamics', '', this.options);
    }
    else if (getresult == 'Lead id not created') {
      this.toastrService.error('Lead(s) exported to Dynamics but the leadid not created. Please contact your administrator!', '', this.options);
    }
    else {
      this.toastrService.error('Lead(s) could not be exported to Dynamics. Please contact your administrator!', '', this.options);
    }
    this.loadLeadsGrid();
  }
  getLeadToDynamics() {
    this.spinner.show();
    this.dynamicsService.getLeadToDynamics().subscribe(getresult => { this.getLeadToDynamicsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getLeadToDynamicsSuccess(getresult: any) {
    debugger
    this.spinner.hide();
    if (getresult) {
      let result = getresult;
    }
    else {
      this.toastrService.error('Lead(s) could not be exported to Dynamics. Please contact your administrator!', '', this.options);
    }
    this.loadLeadsGrid();
  }
  //filterByLetter(letter: string) {
  //  this.selectedLetter = letter;
  //  if (letter) {
  //    this.pagedallLeadlist = this.allLeadlist.filter(item =>
  //      item.fullname.toLowerCase().startsWith(letter.toLowerCase())
  //    );
  //  } else {
  //    this.pagedallLeadlist = this.allLeadlist; // Show all items if 'All' is selected
  //  }
  //}
  //onScroll() {
  //  debugger

  //  console.log("scrolled!!");
  //  this.scrollCheck = true;

  //  /* const length = this.no;
  //   setTimeout(() => {
  //     const p = ' '.repeat(this.Estimates.length).split('').map((s, i) => i + 1 + this.Estimates.length)
 
  //     // This approach should be used to avoid creating another memory address to the array
  //     while (p.length <= this.Estimates.length) this.Estimatesnew.push(p.shift())
  //   }, 1000) */
  //  if (!this.searchText) {
  //    if (this.pagedallLeadlist.length >= this.allLeadlist.length) {
  //      return false;
  //    }
  //    else {
  //      this.scrollCheck = false;
  //      const remainingLength = Math.min(100, this.allLeadlist.length - this.pagedallLeadlist.length);
  //      this.pagedallLeadlist.push(...this.allLeadlist.slice(this.pagedallLeadlist.length, this.pagedallLeadlist.length + remainingLength));
  //      return true;
  //    }
  //  }
  //  else {
  //    return false;
  //  }


  //  /* const scrollTop = evt.currentTarget.scrollTop;
  //   const scrollHeight = evt.target.scrollHeight;
  //   const offsetHeight = evt.target.offsetHeight;
  //   const scrollPosition = scrollTop + offsetHeight;
  //   const scrollTreshold = scrollHeight - this.pageHeight;
  //   if (scrollPosition > scrollTreshold) {
  //     this.pageEnd += this.pageBuffer;
  //   }
  //  | slice: pageStart: pageEnd  */
  //}
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      evt.preventDefault();
    }

  }
    onError(res: any) {
      console.log(res);
      this.spinner.hide();
      this.loadLeadsGrid();
    }
}
