import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation, OnChanges, Input, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {ToastService} from 'ng-uikit-pro-standard';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { NewEvent } from '../Interface/new-event';
import { DefaultLeadAddService } from '../services/defaultleadadd.service';
import { DatePipe } from '@angular/common';
import { EmailService } from '../services/email.service';


@Component({
  selector: 'app-defaultleadadd',
  templateUrl: './defaultleadadd.component.html',
  styleUrls: ['./defaultleadadd.component.scss']
})
export class DefaultLeadAddComponent implements OnInit, OnChanges {
  submitted = false; imageUrl: any = "assets/user.png"; imageValue: any = "assets/Pavion.png";
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  CreateLeadForm: FormGroup;zoomWidth: number; pagenumber: number;
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };
  createLeadList: any[]; submitLeadlist: any[]; existingaccountPlaceHolder: any; phonenumberfieldtext: any;
  
  ManagerDetails: any[];model = new NewEvent();
  @ViewChild('leademailfocus', { static: false }) leademailfocus: ElementRef;
  enableOtherManger: boolean = true; @Input() guestuserFlag: any; guestuserFlagValue: any; employeeManagerPlaceHolder: any;
  loadGifManager: boolean; keywordManager = 'email'; historyHeadingManager: any; DefaultLeadDetails: any[]; versionname: any;
  currentDate: any; currentYear: any; myDate = new Date(); copyright: any = 'Pavion Connect and Protect';
  EmpEmail: any = ''; ManagerEmail: any = ''; CustName: any = ''; LeadEmail: any = ''; MobileNo: any = ''; CompanyName: any = '';
  Siteaddress: any = ''; City: any = ''; State: any = ''; Zip: any = ''; Note: any = ''; Priority: any;
  constructor(private router: Router, private activatedroute: ActivatedRoute, private defaultLeadService: DefaultLeadAddService,
    public formBuilder: FormBuilder, private toastrService: ToastService, private spinner: NgxSpinnerService, private cd: ChangeDetectorRef,
    private datePipe: DatePipe, private emailservice: EmailService  ) { }

  ngOnChanges() {
    debugger
    if (this.guestuserFlag) {
      this.guestuserFlagValue = this.guestuserFlag;
    }
  }
  ngOnInit() {
    debugger
    this.spinner.show();
    this.currentDate = this.datePipe.transform(this.myDate, 'MM-dd-yyyy');
    this.currentYear = new Date().getFullYear();
    this.existingaccountPlaceHolder = 'Select Existing Acount';
    this.employeeManagerPlaceHolder = 'Select Employee Manager';
    this.CreateLeadFormData();
    /*this.loadManager();*/
    this.getAppVersion();

    let browserZoomLevel = Math.ceil(((window.outerWidth - 10) / window.innerWidth) * 100);
    if (window.innerHeight >= 1000) {
      this.zoomTheLrgscreen(browserZoomLevel);
    }
    else {
      this.zoomThescreen(browserZoomLevel);
    }

  }
  clickLoginButton() {
    debugger
    this.guestuserFlag = '2';
    /*this.router.navigate(['createlead'], { skipLocationChange: false });*/
    this.router.navigateByUrl('/', { skipLocationChange: false }).then(() =>
      this.router.navigate(['createlead']));
    /*this.router.navigate(['']);*/
    this.defaultLeadService.callRoot(this.guestuserFlag);
  }
  
  CreateLeadFormData() {
    this.CreateLeadForm = this.formBuilder.group({
      empemail: ['', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
      empmanager: ['', Validators.compose([Validators.pattern(this.emailPattern)])],
      existingaccount: ['Y', Validators.required],
      customername: ['', Validators.required],
      leademail: ['', Validators.compose([Validators.pattern(this.emailPattern)])],//Validators.pattern(this.emailPattern)
      mobilenumber: ['', Validators.compose([Validators.minLength(13), Validators.maxLength(15)])],
      companyname: ['', Validators.required],
      jobtitle: [''],
      siteaddress: [''],
      city: [''],
      state: [''],
      zip: ['', Validators.compose([Validators.minLength(5)])],
      priority:[''],
      note: ['', Validators.required]
    });
    /* this.ContactOwnerOption, Validators.compose([Validators.required, Validators.minLength(13)])*/
  }
  get f() { return this.CreateLeadForm.controls; }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    //this.unsubscribe$.next();
    //this.unsubscribe$.complete();
  }
  //loadManager() {
  //  let strusername = null;
  //  this.defaultLeadService.getManagerEmailForLead(strusername).subscribe(getresult => { this.getManagerSuccess(getresult) },
  //    res => { this.onError(res) });
  //}
  //getManagerSuccess(getresult: any[]) {
  //  this.spinner.hide();
  //  this.ManagerDetails = [];
  //  if (getresult.length > 0) {
  //    this.ManagerDetails = getresult;
  //  }
  //}
  getAppVersion() {
    this.defaultLeadService.getAppVersion().subscribe(getresult => { this.getAppVersionSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAppVersionSuccess(getresult: any[]) {
    this.spinner.hide();
    if (getresult.length > 0) {
      this.versionname = getresult[0].appversionname;
    }
  }
  SubmitLead() {
    debugger
    this.createLeadList = []; this.submitLeadlist = [];
    let email = this.CreateLeadForm.value.leademail;
    let mobilenumber = this.CreateLeadForm.value.mobilenumber;
    if ((email == '' || email == undefined || email == null) && (mobilenumber == '' || mobilenumber == undefined || mobilenumber == null)) {
      this.toastrService.warning('Email or Mobile # required', '', this.options);
      this.leademailfocus.nativeElement.focus();
      return;
    }

    this.spinner.show();
    this.submitted = true;
    if (this.CreateLeadForm.invalid) {
      return;
    }



    // display form values on success
    this.createLeadList.push(this.CreateLeadForm.value);

    var existingaccountval = this.createLeadList[0]['existingaccount'];


    let existingaccount = '';

    if (existingaccountval != this.existingaccountPlaceHolder && existingaccountval != null) {
      existingaccount = existingaccountval;
    }
    else {
      existingaccount = '';
    }

    let EmpEmail = this.CreateLeadForm.value.empemail;
    if (EmpEmail) {
      this.EmpEmail = EmpEmail;
    }
    let ManagerEmail = this.CreateLeadForm.value.empmanager;
    if (ManagerEmail) {
      this.ManagerEmail = ManagerEmail;
    }
    let CustName = this.CreateLeadForm.value.customername;
    if (CustName) {
      this.CustName = CustName;
    }
    let LeadEmail = this.CreateLeadForm.value.leademail;
    if (LeadEmail) {
      this.LeadEmail = LeadEmail;
    }
    let MobileNo = this.CreateLeadForm.value.mobilenumber;
    if (MobileNo) {
      this.MobileNo = MobileNo;
    }
    let CompanyName = this.CreateLeadForm.value.companyname;
    if (CompanyName) {
      this.CompanyName = CompanyName;
    }
    let Siteaddress = this.CreateLeadForm.value.siteaddress;
    if (Siteaddress) {
      this.Siteaddress = Siteaddress;
    }
    let City = this.CreateLeadForm.value.city;
    if (City) {
      this.City = City;
    }
    let State = this.CreateLeadForm.value.state;
    if (State) {
      this.State = State;
    }
    let Zip = this.CreateLeadForm.value.zip;
    if (Zip) {
      this.Zip = Zip;
    }
    let Note = this.CreateLeadForm.value.note;
    if (Note) {
      this.Note = Note;
    }
    let priority = this.CreateLeadForm.value.priority;
    if (priority) {
      this.Priority = priority;
    }
    else {
      this.Priority = false;
    }
    let list = [];
    this.createLeadList.forEach(x => {
      let Item = {
        "empemail": x.empemail ? x.empemail:'',
        "empmanager": x.empmanager ? x.empmanager : '',
        "existingaccount": x.existingaccount ? x.existingaccount : '',
        "customername": x.customername ? x.customername : '',
        "leademail": x.leademail ? x.leademail : '',
        "mobilenumber": x.mobilenumber == null ? '' : x.mobilenumber.replace(/[^0-9]+/g, ''),
        "companyname": x.companyname ? x.companyname : '',
        "jobtitle": x.jobtitle ? x.jobtitle : '',
        "siteaddress": x.siteaddress ? x.siteaddress : '',
        "city": x.city ? x.city : '',
        "state": x.state ? x.state.toUpperCase() : '',
        "zip": x.zip ? x.zip : '',
        "priority": x.priority===true ? '1' : '0',
        "note": x.note ? x.note : ''
      }
      list.push(Item);
    });
    
    this.defaultLeadService.submitDefaultLead(list).subscribe(getresult => { this.submitDefaultLeadSuccess(getresult) },
          res => { this.onError(res) });
  }
  async submitDefaultLeadSuccess(getresult: any) {
    debugger
    this.DefaultLeadDetails = [];
    if (getresult.length > 0) {
      this.DefaultLeadDetails = getresult;
      let defaultleadmsg = this.DefaultLeadDetails[0].defaultleadmsg;
      if (defaultleadmsg == 'inserted') {
        if (this.ManagerEmail && this.Priority==true) {
          this.EmailsentToManager();
        }
        this.onclearContent('1');
      }
      else {
        this.onclearContent('2');
      }
    }
    else {
      this.onReset();
    }
  }
  Phonenumberchange(fieldTypedtext) {
    debugger
    var prefix = ('' + fieldTypedtext).replace(/\D/g, '');
    // this.phonenumberfieldtext = prefix;
    var phonenumberlength = fieldTypedtext.length;
    if (phonenumberlength > 15) {
      return false;
    }
    let phone = '';
    var cleanedlength = ('' + prefix).replace(/\D/g, '').length;
    if (cleanedlength == 10) {
      var cleaned = ('' + prefix).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        phone = '(' + match[1] + ') ' + match[2] + '-' + match[3];
        this.CreateLeadForm.patchValue({
          mobilenumber: phone
        });
      }
    }
    else {
      var cleaned = ('' + prefix).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        phone = match[1] + '(' + match[2] + ') ' + match[3] + '-' + match[4];
        this.CreateLeadForm.patchValue({
          mobilenumber: phone
        });
      }
    }
  }
  onReset() {
    debugger
    /*this.ngSelectComponent.clearModel();*/
    //this.phonenumbersmltime = '';
    //this.officenumbersmltime = '';
    this.submitted = false;
    this.CreateLeadForm.reset();
    this.CreateLeadForm.patchValue({
      empmanager:null,
      existingaccount: 'Y'
    });
    //this.loadGifCustomer = false;
    //this.loadGifCs = false;
  }
  getManagerChange(evt) {

  }
  ClearedManagerChange(evt) {

  }
  FocusedManagerChange(evt) {

  }
  async EmailsentToManager() {
    let to = '';
    let subject = 'Priority - Lead submitted via Lead Generation application.';

    let body = '<p>The following lead has been submitted in the Lead Generation application'+'<br/><br/>'
      + 'Submitted by:' + '<br/>' +
      '<address>' +
      'Employee Email: ' + '<b>' + this.EmpEmail + '</b>' + '<br/>' +
      'Phone: ' + '<b>' + this.MobileNo + '</b>' + '<br/>' +
      'Customer Name: ' + '<b>' + this.CustName + '</b>' + '<br/>' +
      'Lead Email: ' + '<b>' + this.LeadEmail + '</b>' + '<br/>' +
      'Company Name: ' + '<b>' + this.CompanyName + '</b>' + '<br/>' +
      'Site Address: ' + '<b>' + this.Siteaddress + '</b>' + ' ' + ',' + 'City :' + ' ' + '<b>' + this.City + '</b>' +
      ' ,State :' + ' ' + '<b>' + this.State + '</b>' + ' ,Zip :' + ' ' + '<b>' + this.Zip + '</b>' + '<br/>' +
      'Note: ' + '<b>' + this.Note + '</b>'+ '</address></p>';
    if (this.ManagerEmail) {
       to = this.ManagerEmail;
    }
    
    let toname = to.substring(0, to.indexOf('@'));
    let list = {
      "to": to,
      "toname": toname,
      "subject": subject,
      "body": body
    }
    this.emailservice.sendEmail(list).subscribe(getresult => { this.sendEmailManagerSuccess(getresult) },
      res => { this.onError(res) });
  }
  sendEmailManagerSuccess(getresult) {
    if (getresult != '') {
      let emailmsg = getresult;
      if (emailmsg == '1') {
        this.toastrService.success('Email sent to manager.', '', this.options);
      }
      else {
        this.toastrService.error('Email could not be sent due to network error!', '', this.options);
      }

    }
  }
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      evt.preventDefault();
    }

  }
  restrictFieldlength(evt: any) {
    if (evt.target.value.length >= 60) {
      this.toastrService.warning('Customer name/Customer address/Site address cannot exceed 60 characters', '', this.options);
      evt.preventDefault();
    }
  }
  isNumberwithhyphn(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && charCode != 32 && charCode != 45 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }
  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }
  isNumberwithchar(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 90) {
      evt.preventDefault();
    }
  }
  isNumberwithcharhyphn(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && charCode != 32 && charCode != 45 && (charCode < 48 || charCode > 57) && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      evt.preventDefault();
    }
  }
  isNumberPhonenumber(evt: any) {
    debugger
    var phonenumber = this.phonenumberfieldtext;
    if (phonenumber != undefined) {
      var phonenumberLengt = phonenumber.length;
    }
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var cleaned = ('' + phonenumber).replace(/\D/g, '').length;
    if (charCode > 31 && charCode != 45 && charCode != 40 && charCode != 41 && charCode != 32 && (charCode < 48 || charCode > 57) || phonenumberLengt >= 15 || cleaned >= 11) {
      evt.preventDefault();
    }
    this.phonenumberfieldtext = '';
  }
  desiableField() {

  }
  onclearContent(flagval) {
    this.spinner.hide();
    this.onReset();
    if (flagval == '1') {

      this.toastrService.success('Lead created', '', this.options);
      /* this.loadLeadsGrid();*/
    }
    else if (flagval == '2') {

      this.toastrService.warning('Lead already exist', '', this.options);
      /*this.loadLeadsGrid();*/
    }
    else {
      /* this.loadLeadsGrid();*/
    }
  }


  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.zoomWidth = Math.ceil(((event.target.window.outerWidth - 10) / event.target.window.innerWidth) * 100);
    this.zoomThescreen(this.zoomWidth);
  }

  zoomThescreen(browserZoomLevel) {
    if (browserZoomLevel >= 100 && browserZoomLevel <= 105) {
      this.pagenumber = 11;
      //this.theight = 403;
    } else if (browserZoomLevel >= 90 && browserZoomLevel <= 95) {
      this.pagenumber = 13;
      //this.theight = 430;
    } else if (browserZoomLevel >= 80 && browserZoomLevel <= 85) {
      this.pagenumber = 16;
      // this.theight = 450;
    } else {
      this.pagenumber = 19;
      //this.theight = 550;
    }
  }
  zoomTheLrgscreen(browserZoomLevel) {
    if (browserZoomLevel >= 100 && browserZoomLevel <= 105) {
      this.pagenumber = 18;
      // this.theight = 403;
    } else if (browserZoomLevel >= 90 && browserZoomLevel <= 95) {
      this.pagenumber = 20;
      //this.theight = 430;
    } else if (browserZoomLevel >= 80 && browserZoomLevel <= 85) {
      this.pagenumber = 23;
      //this.theight = 450;
    } else {
      this.pagenumber = 26;
      //this.theight = 550;
    }
  }
  //emailSent() {
  //  if (this.OutlookemailList != undefined) {
  //    if (this.OutlookemailList.length > 0) {
  //      for (var i = 0; i < this.OutlookemailList.length; i++) {
  //        let subject = this.OutlookemailList[i].subject;
  //        //var from = this.OutlookemailList[i].from.emailAddress.address;
  //        console.log(subject);
  //        if (subject != '') {
  //          var id = this.OutlookemailList[i].id;
  //          var parentFolderId = this.OutlookemailList[i].parentFolderId;
  //          var outlookemailid = id;
  //          this.graphService.SendEmail(outlookemailid);
  //          console.log(id);
  //        }
  //      }
  //    }
  //  }
  //}
  onError(res: any) {
    this.spinner.hide();
    console.log(res);
  }
}
