import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MDBModalRef, MdbTableDirective, ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponentService } from '../../services/appcomponenet.service';
import { SettingService } from '../../services/settings.service';

@Component({
  selector: 'app-managerpopup',
  templateUrl: './managerpopup.component.html',
  styleUrls: ['./managerpopup.component.scss']
})
export class ManagerPopupComponent implements OnInit {
  Username: any; authorizeUserDetails: any[]; managerPopupmodaldata: any;
  action = new Subject();
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };
  ManagerName: any[]; rowid: any; ManagerDetails: any[]; managerid: any;
  fullname: any; managerPlaceholder: any; manager: any = null; btnName: any;
  constructor(private cdRef: ChangeDetectorRef, private router: Router, private activatedroute: ActivatedRoute,
    private appComponentService: AppComponentService,private datepipe: DatePipe, private _el: ElementRef, public modalRef: MDBModalRef, private toastrService: ToastService,
    public formBuilder: FormBuilder, private settingService: SettingService) { }
  ngOnInit() {
    debugger
    this.managerPlaceholder = 'Select Manager';
    this.rowid = this.managerPopupmodaldata.rowid;
    this.getAuthorizeUserDetails();
  }
  getAuthorizeUserDetails() {
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    this.authorizeUserDetails = [];
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      let UserId = this.authorizeUserDetails[0]['userid'];
      let fullname = this.authorizeUserDetails[0]['fullname'];
      let level = this.authorizeUserDetails[0]['level'];
      let Sedonadatabase = this.authorizeUserDetails[0]['sedonadatabase'];
      if (UserId != "") {
        this.Username = UserId;
      }
      if (fullname) {
        this.fullname = fullname;
      }
      this.loadManagerDetails();
    }
  }
  loadManagerDetails() {
    this.settingService.getManagerDetails().subscribe(getresult => { this.getManagerDetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getManagerDetailsSuccess(getresult: any[]) {
    this.ManagerName = [];
    if (getresult.length > 0) {
      this.ManagerName = getresult;
    }
    this.getManager();
  }

  getManager() {
    debugger
    let strusername = this.Username;
    this.settingService.getManager(strusername).subscribe(getresult => { this.getManagerSuccess(getresult) },
      res => { this.onError(res) });
  }
  getManagerSuccess(getresult: any[]) {
    debugger
    this.ManagerDetails = [];
    if (getresult.length > 0) {
      this.btnName = 'Update';
      this.ManagerDetails = getresult;
      let manager = this.ManagerDetails[0].manageruserid;
      let managerid = this.ManagerDetails[0].managerid;
      this.ManagerName.forEach(x => {
        if (x.userid == manager) {
          this.manager = x.emailusername;
        }
      });
      if (managerid) {
        this.managerid = managerid;
      }
    }
    else {
      this.btnName = 'Save';
      this.ClearManager();
    }
  }
  UpdateManger() {
    debugger
    let email = ''; let manageruserid = '';

    let managernameval = this.manager;
    this.ManagerName.forEach((ManagerNameselect) => {
      if (ManagerNameselect.emailusername == managernameval) {
        manageruserid = ManagerNameselect.userid;
      }
    })
    if (manageruserid == '') {
      this.ManagerName.forEach((ManagerNameselect) => {
        if (ManagerNameselect.userid == managernameval) {
          manageruserid = ManagerNameselect.userid;
        }
      })
    }
    let strusername = this.Username;
    let managerid = this.managerid;
    let list = [];
      let Item = {
        "manageruserid": manageruserid ? manageruserid.toString() : '',
        "userid": strusername ? strusername : ''
      };
      list.push(Item);
    this.settingService.updateManager(list, managerid).subscribe(getresult => { this.updateManagerSuccess(getresult) },
      res => { this.onError(res) });
  }
  updateManagerSuccess(getresult: any[]) {
    this.ManagerDetails = [];
    if (getresult.length > 0) {
      this.ManagerDetails = getresult;
      let managerMsg = this.ManagerDetails[0].managermsg;
      if (managerMsg == 'exist') {
        this.toastrService.warning('Manager already exists', '', this.options);
      }
      else {
        this.toastrService.success('Manager updated', '', this.options);
      }
    }
    this.action.next('result');
    this.CloseManager();
  }
  insertManager() {
    debugger
    let manageruserid = '';
    let strusername = this.Username;
    let managernameval = this.manager;
    if (this.ManagerName.length > 0) {
      this.ManagerName.forEach((ManagerNameselect) => {
        if (ManagerNameselect.userid == managernameval) {
          manageruserid = ManagerNameselect.userid;
        }
      });
    }
    let list = [];
      let Item = {
        "manageruserid": manageruserid ? manageruserid.toString() : '',
        "userid": strusername ? strusername : ''
      };
      list.push(Item);
    this.settingService.insertManager(list).subscribe(getresult => { this.insertManagerSuccess(getresult) },
      res => { this.onError(res) });
  }
  insertManagerSuccess(getresult: any[]) {
    this.ManagerDetails = [];
    if (getresult.length > 0) {
      this.ManagerDetails = getresult;
      let managerMsg = this.ManagerDetails[0].managermsg;
      if (managerMsg == 'exist') {
        this.toastrService.warning('Manager already exists', '', this.options);
      }
      else {
        this.toastrService.success('Manager created', '', this.options);
      }
    }
    this.action.next('result');
    this.CloseManager();
  }
  CloseManager() {
    this.modalRef.hide();
  }
  ClearManager() {
    this.manager = null;
    this.managerPlaceholder = 'Select Manager';
  }
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      evt.preventDefault();
    }

  }
  onError(res: any) {
    console.log(res);
  }
}
