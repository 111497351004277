import { Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-submitissue',
  templateUrl: './submitissue.component.html',
  styleUrls: ['./submitissue.component.scss']
})
export class SubmitIssueComponent implements OnInit{
  src: any;
    constructor() {
  }  
  ngOnInit() {
    this.src = 'https://forms.office.com/Pages/ResponsePage.aspx?id=Tv6YKuYNEUG9j7ZhEzTnkqtvdFaoR4REoIl_Hw8dL-JUM1NSUlJPRkFMQlI3MlJXSkJGNUNWT1JZSi4u&embed=true';
    }       
}
