import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MDBModalRef, MDBModalService, MdbTableDirective, ToastService } from 'ng-uikit-pro-standard';
import { Subject} from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponentService } from '../../../services/appcomponenet.service';
import { CryptoService } from '../../../services/crypto.service';
import { SettingService } from '../../../services/settings.service';
import { HeaderService } from '../../../services/header.service';
import { MiscellaneousPopupComponent } from '../miscellaneouspopup/miscellaneouspopup.component';
import { MiscellaneousLevelPopupComponent } from '../miscellaneouslevelpopup/miscellaneouslevelpopup.component';
import { ConfirmationdilogService } from '../../../services/confirmationdilog.service';


@Component({
  selector: 'app-miscellaneous',
  templateUrl: './miscellaneous.component.html',
  styleUrls: ['./miscellaneous.component.scss']
})
export class MiscellaneousSettingComponent implements OnInit {
  Username: any; FullnameValue: any; EmailidValue: any; authorizeUserDetails: any[];Level: any;

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };

  modalOptions: any; modalRef: MDBModalRef; submitted: boolean; Companydetails: any[];
  /* Miscellaneous Data */
  MisDataTableForm: FormGroup; misDataDetails: any[]; misDataTable: boolean;
  enableEditMisData: any; misDataTableval: any[]; searchMisDataText: string = ''; previousMisData: string;
  isDescMisData: boolean; columnMisData: any; misDataDetailscount: any; progressBarloading: boolean;
  @ViewChild("valuefocus", { static: false }) valuefocus: ElementRef;
  modalOptionMiscellaneousPopup: any; selectcompany: any; selectedlevel: any; activeValue: boolean;
  MiscellaneousMstValueDetails: any[]; MiscellaneousPopupflag: any; MiscellaneousPopup: any[]; miscellaneousmstid: any;
  selectedvalue: any; insertedMiscellaneous: any[]; insertedValue: any;

  /*Miscellaneous Level*/
  //searchMiscellaneousLevelText: string = ''; previousMiscellaneousLevel: string; isDescMiscellaneousLevel: boolean; columnMiscellaneousLevel: any;
  //MiscellaneousLevelForm: FormGroup; MiscellaneousLevelTable: boolean; MiscellaneousLevelDetailscount: any;
  //enableEditMiscellaneousLevel: any; MiscellaneousLevelTableval: any[];

  //activeMiscellaneousLevelVal: boolean; selectMiscellaneousLevelcompany: any;  
  selectedMiscellaneousLevelvalue: any; MiscellaneousLevelPopupflag: any; miscellaneouslevelid: any; insertedMiscellaneousLevel: any[];
  insertedMiscellaneousLevelValue: any; MiscellaneousLevelDetails: any[]; MiscellaneousLevelValueDetails: any[]; modalOptionMiscellaneousLevelPopup: any;
  activeMisLevelbtn: boolean = false;

  /* Lead Category */
  //LeadCategoryForm: FormGroup; LeadCategoryDetails: any[]; LeadCategoryTable: boolean;
  //enableEditLeadCategory: any; LeadCategoryTableval: any[]; searchLeadCategoryText: string = ''; previousLeadCategory: string;
  //isDescLeadCategory: boolean; columnLeadCategory: any; LeadCategoryDetailscount: any;

  //insertedLeadCategoryValue: any; insertedLeadCategory: any[]; selectLeadCategorycompany: any; selectedLeadCategoryvalue: any = '';
  //leadcategoryid: any; LeadCategoryPopupflag: any = ''; activeLeadCategoryValue: boolean = false; modalOptionLeadCategoryPopup: any;
  //LeadCategoryPopup: any[]; LeadCategoryDetailsValue: any[]; 

  constructor(private appComponentService: AppComponentService,private router: Router, private activatedroute: ActivatedRoute, private headerService: HeaderService,
    public cryptoservice: CryptoService, private cdRef: ChangeDetectorRef, private modalService: MDBModalService, public formBuilder: FormBuilder,
    private settingService: SettingService, private toastrService: ToastService, private confirmationDialogService: ConfirmationdilogService) { }

  ngOnInit() {
    this.activeValue = true;
    /*this.activeLeadCategoryValue = true;*/
    this.MisDataTableFormData();
    /*this.MiscellaneousLevelTableFormData();*/
   /* this.LeadCategoryTableFormData();*/
    this.getCompanyDetails();
    this.getAuthorizeUserDetails();
  }
  getAuthorizeUserDetails() {
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    debugger
    this.authorizeUserDetails = []; let userid = '';
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      var Fullname = this.authorizeUserDetails[0]['fullname'];
      var UserId = this.authorizeUserDetails[0]['userid'];
      var BranchName = this.authorizeUserDetails[0]['branchname'];
      var Level = this.authorizeUserDetails[0]['level'];
      var Emailid = this.authorizeUserDetails[0]['email'];
      var Application = this.authorizeUserDetails[0]['application'];
      var Password = this.authorizeUserDetails[0]['password'];
      if (UserId != "") {
        this.Username = UserId;
        userid = UserId.toLowerCase();
      }
      if (Fullname != "") {
        this.FullnameValue = Fullname;
      }
      if (Emailid != "") {
        this.EmailidValue = Emailid;
      }
      if (Level != "") {
        this.Level = Level.toLowerCase();
      }
      if (this.Level == 'admin' && (userid == 'admin' || userid == 'administrator')) {
        this.activeMisLevelbtn = true;
      }
      else {
        this.activeMisLevelbtn = false;
      }
    }
    this.getMisData();
    /*this.getMiscellaneousLevel();*/
    this.loadLevelType();
    /*this.getLeadcategory();*/
  }

  /* Miscellaneous Data */

  MisDataTableFormData() {
    this.activeValue = true;
    this.MisDataTableForm = this.formBuilder.group({
      type: [null, Validators.required],
      value: [null, Validators.required]
      //value: [null, Validators.required],
      //company: [null, Validators.required]
    });
  }
  get f() { return this.MisDataTableForm.controls; }
  //enableMisDataTable() {
  //  this.misDataTable = !this.misDataTable;
  //  this.submitted = false;
  //  this.enableEditMisData = -1;
  //  this.MisDataTableForm.reset();
  //  this.MiscellaneousLevelTable = false;
  //  this.LeadCategoryTable = false;
  //  this.getMiscellaneousLevel();
  //}
  getMisData() {
    this.settingService.getMiscellaneousMst().subscribe(getresult => { this.getMiscellaneousMstSuccess(getresult) },
      res => { this.onError(res) });
  }
  getMiscellaneousMstSuccess(getresult: any[]) {
    this.misDataDetails = [];
    if (getresult.length > 0) {
      this.misDataDetails = getresult;
      /*this.loadMisDataDetails();*/
    }
  }
  //loadMisDataDetails() {
  //  this.misDataDetailscount = this.misDataDetails.length;
  //  this.mdbTable.setDataSource(this.misDataDetails);

  //  this.misDataDetails = this.mdbTable.getDataSource();
  //  this.previousMisData = this.mdbTable.getDataSource();
  //}


  getCompanyDetails() {
    this.settingService.getCompanyDetails().subscribe(getresult => { this.getCompanyDetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getCompanyDetailsSuccess(getresult: any[]) {
    this.Companydetails = [];
    if (getresult.length > 0) {
      this.Companydetails = getresult;
    }
  }


  //getMiscellaneousLevel() {
  //  this.settingService.getMiscellaneousLevel().subscribe(getresult => { this.getMiscellaneousLevelSuccess(getresult) },
  //    res => { this.onError(res) });
  //}
  //getMiscellaneousLevelSuccess(getresult: any[]) {
  //  this.MiscellaneousLevelDetails = [];
  //  if (getresult.length > 0) {
  //    this.MiscellaneousLevelDetails = getresult;
  //    /*this.loadMiscellaneousLevelDetails();*/
  //  }
  //}
  insertMisDataTable() {
    this.misDataTableval = [];
    this.submitted = true;
    if (this.MisDataTableForm.invalid) {
      return;
    }
    console.log(this.MisDataTableForm.value);
    this.misDataTableval.push(this.MisDataTableForm.value);

    let strusername = this.Username;
    this.insertedValue = this.MisDataTableForm.value.value;
    let list = [];
    this.misDataTableval.forEach(x => {
      let Item = {
        "type": x.type,
        "value": x.value,
        "company": 'AFA'
      };
      list.push(Item);
    });
    this.settingService.insertMiscellaneousMst(list).subscribe(getresult => { this.insertMiscellaneousMstSuccess(getresult) },
      res => { this.onError(res) });
  }
  insertMiscellaneousMstSuccess(getresult: any[]) {
    debugger
    this.misDataDetails = [];
    this.insertedMiscellaneous = [];
    if (getresult.length > 0) {
      this.misDataDetails = getresult;

      let miscellaneousmsg = this.misDataDetails[0].miscellaneousmsg;
      if (miscellaneousmsg == 'exist') {
        this.MisDataTableForm.patchValue({
          value: null
        });
        this.toastrService.warning('Miscellaneous already exists', '', this.options);
      }
      else {
        this.MisDataTableForm.reset();
        this.MisDataTableFormData();
        this.toastrService.success('Miscellaneous created', '', this.options);
      }
    }
    this.submitted = false;

   /* this.getMiscellaneousMstValue();*/
    /*this.selectCompany(this.selectcompany);*/
    this.loadLevelType();
    this.selectLevelType(this.selectedlevel);
    this.misDataDetails.forEach(x => {
      if (x.value == this.insertedValue) {
        this.insertedMiscellaneous = x;
        this.selectValue(this.insertedMiscellaneous);
      }
    });
  }
  //editMisDataTable(item, e, i) {
  //  this.enableEditMisData = i;
  //  this.misDataTable = false;
  //  this.MiscellaneousLevelTable = false;
  //  this.LeadCategoryTable = false;
  //  this.MisDataTableForm.patchValue({
  //    type: item.type, value: item.value, company: item.company
  //  });
  //}
  //deleteMisDataTable(tablevalue, e, i) {
  //  this.misDataTable = false;
  //  this.MiscellaneousLevelTable = false;
  //  this.LeadCategoryTable = false;
  //  let miscellaneousmstid = tablevalue.miscellaneousmstid;
  //  if (confirm("Are you sure you want to delete this miscellaneous data?")) {
  //    this.settingService.deleteMiscellaneousMst(miscellaneousmstid).subscribe(getresult => { this.deleteMiscellaneousMstSuccess(getresult) },
  //      res => { this.onError(res) });
  //    return true;
  //  }
  //  else {
  //    return false;
  //  }
  //}
  //deleteMiscellaneousMstSuccess(getresult: any[]) {
  //  this.misDataDetails = [];
  //  if (getresult.length > 0) {
  //    this.misDataDetails = getresult;
  //    this.loadMisDataDetails();
  //  }
  //  this.toastrService.error('Miscellaneous data deleted', '', this.options);
  //}
  //updateMisDataTable(tablevalue, e, i) {
  //  this.misDataTableval = [];
  //  this.MiscellaneousLevelTable = false;
  //  this.LeadCategoryTable = false;
  //  this.submitted = true;
  //  if (this.MisDataTableForm.invalid) {
  //    return;
  //  }
  //  console.log(this.MisDataTableForm.value);
  //  this.misDataTableval.push(this.MisDataTableForm.value);

  //  let strusername = this.Username;
  //  let miscellaneousmstid = tablevalue.miscellaneousmstid;
  //  let list = [];
  //  this.misDataTableval.forEach(x => {
  //    let Item = {
  //      "type": x.type,
  //      "value": x.value,
  //      "company": x.company
  //    };
  //    list.push(Item);
  //  });
  //  this.misDataTable = false;
  //  this.settingService.updateMiscellaneousMst(list, miscellaneousmstid).subscribe(getresult => { this.updateMiscellaneousMstuccess(getresult) },
  //    res => { this.onError(res) });
  //}
  //updateMiscellaneousMstuccess(getresult: any[]) {
  //  this.misDataDetails = [];
  //  if (getresult.length > 0) {
  //    this.misDataDetails = getresult;
  //    this.loadMisDataDetails();
  //    let miscellaneousmsg = this.misDataDetails[0].miscellaneousmsg;
  //    if (miscellaneousmsg == 'exist') {
  //      this.toastrService.warning('Miscellaneous data already exists', '', this.options);
  //    }
  //    else {
  //      this.toastrService.success('Miscellaneous data updated', '', this.options);
  //    }

  //  }
  //  this.enableEditMisData = -1;
  //}
  //focusValue() {
  //  this.valuefocus.nativeElement.focus();
  //}
  //cancelMisDataTable(e, i) {
  //  this.enableEditMisData = -1;
  //}
  //searchMisDataItems() {
  //  const prev = this.mdbTable.getDataSource();

  //  if (!this.searchMisDataText) {
  //    this.mdbTable.setDataSource(this.previousMisData);
  //    this.misDataDetails = this.mdbTable.getDataSource();
  //  }

  //  if (this.searchMisDataText) {
  //    this.misDataDetails = this.mdbTable.searchLocalDataBy(this.searchMisDataText);
  //    this.mdbTable.setDataSource(prev);
  //  }
  //}
  //dtTablesortMisData(property) {
  //  let direction = 0;
  //  this.isDescMisData = !this.isDescMisData; //change the direction
  //  this.columnMisData = property;
  //  direction = this.isDescMisData ? 1 : -1;

  //  this.misDataDetails.sort(function (a, b) {
  //    if (a[property] < b[property]) {
  //      return -1 * direction;
  //    }
  //    else if (a[property] > b[property]) {
  //      return 1 * direction;
  //    }
  //    else {
  //      return 0;
  //    }
  //  });
  //};
  //selectLevelType(evt) {
  //  debugger
  //  let company = evt;
  //  this.selectcompany = company;
  //  if (company) {
  //    this.activeLevel = false;
  //    this.MisDataTableForm.patchValue({
  //      company: company
  //    });
  //  }
  //  else {
  //    this.activeLevel = true;
  //  }
  //}
  clearMisData() {
    this.MisDataTableForm.reset();
    this.MisDataTableFormData();
    this.selectcompany = '';
    this.selectedlevel = '';
    this.selectedvalue = '';
    this.miscellaneousmstid = '';
    this.MiscellaneousPopupflag = '';
    this.activeValue = true;


    this.selectedMiscellaneousLevelvalue = '';
    this.miscellaneouslevelid = '';
    this.MiscellaneousLevelPopupflag = '';
  }
  //selectCompany(evt) {
  //  debugger
  //  this.clearLeadCategory();
  //  let company = '';
  //  if (evt) {
  //    company = evt;
  //  }
  //  this.selectcompany = company;
  //  this.clearDisableFields('company');
  //  if (company) {
  //    this.activeLevel = false;
  //    this.getMiscellaneousLevelValueDetails();
  //    this.MisDataTableForm.patchValue({
  //      company: company
  //    });
  //  }
  //  else {
  //    this.activeLevel = true;
  //  }

  //}
  loadLevelType() {
    this.selectcompany = 'AFA';
    this.clearDisableFields('type');
    this.getMiscellaneousLevelValueDetails();
  }
  selectLevelType(evt) {
    debugger
    let type = '';
    if (evt) {
      type = evt.value;
    }
    if (type == undefined || type =='') {
      type = evt;
    }
    let company = this.selectcompany;
    this.selectedlevel = type;
    this.clearDisableFields('type');
    //if (company && type) {
    //  // this.getBranchName();
    //  this.activeValue = false;
    //  this.getMiscellaneousMstValueDetails();
    //  //this.MisDataTableForm.patchValue({
    //  //  company: this.selectcompany, type: type
    //  //});
    //  this.MisDataTableForm.patchValue({
    //    type: type
    //  });
    //}
    //else {
    //  this.activeValue = true;
    //  //this.MisDataTableForm.patchValue({
    //  //  company: this.selectcompany,type: null
    //  //});
    //  this.MisDataTableForm.patchValue({
    //    type: null
    //  });
    //}


    if (type) {
      // this.getBranchName();
      this.activeValue = false;
      this.getMiscellaneousMstValueDetails();
      //this.MisDataTableForm.patchValue({
      //  company: this.selectcompany, type: type
      //});
      this.MisDataTableForm.patchValue({
        type: type
      });
    }
    else {
      this.activeValue = true;
      //this.MisDataTableForm.patchValue({
      //  company: this.selectcompany,type: null
      //});
      this.MisDataTableForm.patchValue({
        type: null
      });
    }
  }
  selectValue(evt) {
    debugger
    let type = '';
    let value = '';
    if (evt) {
      type = evt.type;
      value = evt.value;
    }
    //let company = this.selectcompany;
    this.clearDisableFields('value');
    //if (company && type && value) {
    //  // this.getBranchName();
    //  this.MisDataTableForm.patchValue({
    //    company: this.selectcompany, type: type, value: value
    //  });
    //  //this.settingService.getLastBranchCode(company).subscribe(getresult => { this.getLastBranchCodeSuccess(getresult) },
    //  //  res => { this.onError(res) });
    //}
    //else {
    //  this.MisDataTableForm.patchValue({
    //    company: this.selectcompany, type: this.selectedlevel,value: null
    //  });
    //}


    if (type && value) {
      // this.getBranchName();
      this.MisDataTableForm.patchValue({
        type: type, value: value
      });
      //this.settingService.getLastBranchCode(company).subscribe(getresult => { this.getLastBranchCodeSuccess(getresult) },
      //  res => { this.onError(res) });
    }
    else {
      this.MisDataTableForm.patchValue({
        type: this.selectedlevel, value: null
      });
    }
  }
  clearDisableFields(val) {
    this.MisDataTableForm.patchValue({
      company: null, type: null, value: null
    });
    //if (val == 'company') {
    //  this.activeLevel = false;
    //  this.activeValue = true;
    //}
    //else
      if (val == 'type') {
        this.activeValue = true;
    }
    else {
      this.activeValue = false;
    }
  }
  getMiscellaneousMstValueDetails() {
    debugger
    this.progressBarloading = true;
    let company = this.selectcompany;
    let type = this.selectedlevel;
    this.settingService.getMiscellaneousMstValue(company, type).subscribe(getresult => { this.getMiscellaneousMstValueSuccess(getresult) },
      res => { this.onError(res) });
  }
  getMiscellaneousMstValueSuccess(getresult: any[]) {
    debugger
    this.progressBarloading = false;
    this.MiscellaneousMstValueDetails = [];
    if (getresult.length > 0) {
      this.MiscellaneousMstValueDetails = getresult;
    }
  }
  ModalOptionMiscellaneousPopupLoad() {
    this.modalOptionMiscellaneousPopup = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-lg modal-dialog-centered',
      containerClass: '',
      animated: true,
      data: {
        MiscellaneousPopupmodaldata: {
          companyval: this.selectcompany,
          miscellaneouslevelval: this.selectedlevel,
          miscellaneousvalue: this.selectedvalue,
          miscellaneousmstid: this.miscellaneousmstid,
          miscellaneousflag: this.MiscellaneousPopupflag
        }
      }
    }
  }
  openMiscellaneousPopupModal(val, flag) {
    debugger
    this.MiscellaneousPopupflag = flag;
    if (val && flag=='edit') {
      this.selectcompany = val.company;
      this.selectedlevel = val.type;
      this.selectedvalue = val.value;
      this.miscellaneousmstid = val.miscellaneousmstid;
    }
    else if (val == '' || val == undefined && flag == 'add') {
      this.selectcompany = this.selectcompany;
      this.selectedlevel = this.selectedlevel;
      this.selectedvalue = '';
      this.miscellaneousmstid = '';
    }
    else {
      this.selectcompany = '';
      this.selectedlevel = '';
      this.selectedvalue = '';
      this.miscellaneousmstid = '';
    }

    this.ModalOptionMiscellaneousPopupLoad();
    this.MiscellaneousPopupModal();
  }
  MiscellaneousPopupModal() {
    this.modalRef = this.modalService.show(MiscellaneousPopupComponent, this.modalOptionMiscellaneousPopup);
    this.modalRef.content.action.subscribe((result: any) => {
      debugger
      this.MiscellaneousPopup = [];
      this.MiscellaneousPopup = result;
      if (result == 'delete') {
        this.activeValue = true;
        this.MisDataTableFormData();
        this.getMiscellaneousMstValueDetails();
        /*this.selectCompany(this.selectcompany);*/
        this.loadLevelType();
        this.MisDataTableForm.patchValue({
          type:null,value: null
        });
      }
      if (this.MiscellaneousPopupflag == 'add') {
        //this.MisDataTableForm.patchValue({
        //  company: this.selectcompany, type: result.type, value: result.value
        //});
        this.MisDataTableForm.patchValue({
          type: result.type, value: result.value
        });
        if (result.value == '' || result.value == undefined) {
          this.insertMisDataTable();
        }
        else {
          this.insertMisDataTable();
        }

      }
      else if (this.MiscellaneousPopupflag == 'edit' && result != 'delete') {
        this.getMiscellaneousMstValueDetails();
        /*this.selectCompany(this.selectcompany);*/
        this.loadLevelType();
        this.selectLevelType(this.selectedlevel);
        this.selectValue(result);
      }
    });
  }


  /*Miscellaneous Level */

  //MiscellaneousLevelTableFormData() {
  //  this.activeMiscellaneousLevelVal = true;
  //  this.MiscellaneousLevelForm = this.formBuilder.group({
  //    value: [null, Validators.required],
  //    company: [null, Validators.required]
  //  });
  //}
  //get g() { return this.MiscellaneousLevelForm.controls; }

  //enableMiscellaneousLevelTable() {
  //  this.MiscellaneousLevelTable = !this.MiscellaneousLevelTable;
  //  this.submitted = false;
  //  this.enableEditMiscellaneousLevel = -1;
  //  this.MiscellaneousLevelForm.reset();
  //  this.misDataTable = false;
  //  this.LeadCategoryTable = false;
  //}
  //loadMiscellaneousLevelDetails() {
  //  this.MiscellaneousLevelDetailscount = this.MiscellaneousLevelDetails.length;
  //  this.mdbTable.setDataSource(this.MiscellaneousLevelDetails);

  //  this.MiscellaneousLevelDetails = this.mdbTable.getDataSource();
  //  this.previousMiscellaneousLevel = this.mdbTable.getDataSource();
  //}
  //editMiscellaneousLevelTable(item, e, i) {
  //  this.enableEditMiscellaneousLevel = i;
  //  this.MiscellaneousLevelTable = false;
  //  this.misDataTable = false;
  //  this.LeadCategoryTable = false;
  //  this.MiscellaneousLevelForm.patchValue({
  //    value: item.value, company: item.company
  //  });
  //}
  insertMiscellaneousLevelTable() {
    debugger
    this.submitted = true; let type = '';
    if ((this.MisDataTableForm.value.company == '' && this.MisDataTableForm.value.company == undefined) ||
      (this.MisDataTableForm.value.type == '' && this.MisDataTableForm.value.type == undefined)) {
      return;
    }
    let company = this.MisDataTableForm.value.company;
     type = this.MisDataTableForm.value.type;
    this.insertedMiscellaneousLevelValue = type;
    let strusername = this.Username;
    let list = [];
      let Item = {
        "value": type,
        "company": 'AFA'
      };
      list.push(Item);
    this.settingService.insertMiscellaneousLevel(list).subscribe(getresult => { this.insertMiscellaneousLevelSuccess(getresult) },
      res => { this.onError(res) });
  }
  insertMiscellaneousLevelSuccess(getresult: any[]) {
    debugger
    this.MiscellaneousLevelDetails = [];
    this.insertedMiscellaneousLevel = [];
    if (getresult.length > 0) {
      this.MiscellaneousLevelDetails = getresult;

      let miscellaneouslevelmsg = this.MiscellaneousLevelDetails[0].miscellaneouslevelmsg;
      if (miscellaneouslevelmsg == 'exist') {
        this.MisDataTableForm.patchValue({
          type: null,value:null
        });
        this.toastrService.warning('Dropdown list already exists', '', this.options);
      }
      else {
        //this.MiscellaneousLevelForm.reset();
        //this.MiscellaneousLevelTableFormData();
        this.toastrService.success('Dropdown list created', '', this.options);
      }
    }
    this.submitted = false;
    /*this.getMiscellaneousLevelValueDetails();*/
    /*this.selectCompany(this.selectcompany);*/
    this.loadLevelType();
    this.MiscellaneousLevelDetails.forEach(x => {
      if (x.value == this.insertedMiscellaneousLevelValue) {
        this.insertedMiscellaneousLevel = x;
        this.selectLevelType(this.insertedMiscellaneousLevel);
      }
    });
  }
  //cancelMiscellaneousLevelTable(e, i) {
  //  this.enableEditMiscellaneousLevel = -1;
  //}

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }
  //searchMiscellaneousLevel() {
  //  const prev = this.mdbTable.getDataSource();

  //  if (!this.searchMiscellaneousLevelText) {
  //    this.mdbTable.setDataSource(this.previousMiscellaneousLevel);
  //    this.MiscellaneousLevelDetails = this.mdbTable.getDataSource();
  //  }

  //  if (this.searchMiscellaneousLevelText) {
  //    this.MiscellaneousLevelDetails = this.mdbTable.searchLocalDataBy(this.searchMiscellaneousLevelText);
  //    this.mdbTable.setDataSource(prev);
  //  }
  //}
  //dtTablesortMiscellaneousLevel(property) {
  //  let direction = 0;
  //  this.isDescMiscellaneousLevel = !this.isDescMiscellaneousLevel; //change the direction
  //  this.columnMiscellaneousLevel = property;
  //  direction = this.isDescMiscellaneousLevel ? 1 : -1;

  //  this.MiscellaneousLevelDetails.sort(function (a, b) {
  //    if (a[property] < b[property]) {
  //      return -1 * direction;
  //    }
  //    else if (a[property] > b[property]) {
  //      return 1 * direction;
  //    }
  //    else {
  //      return 0;
  //    }
  //  });
  //};
  //clearMiscellaneousLevel() {
  //  this.MiscellaneousLevelForm.reset();
  //  this.MiscellaneousLevelTableFormData();
  //  this.selectMiscellaneousLevelcompany = '';
  //  this.selectedMiscellaneousLevelvalue = '';
  //  this.miscellaneouslevelid = '';
  //  this.MiscellaneousLevelPopupflag = '';
  //  this.activeMiscellaneousLevelVal = true;
  //}



  //selectMiscellaneousLevelCompany(evt) {
  //  debugger
  //  this.clearMisData();
  //  this.clearLeadCategory();
  //  let company = evt;
  //  this.selectMiscellaneousLevelcompany = company;
  //  if (company) {
  //    this.activeMiscellaneousLevelVal = false;
  //    this.getMiscellaneousLevelValueDetails();
  //    this.MiscellaneousLevelForm.patchValue({
  //      company: company
  //    });
  //  }
  //  else {
  //    this.activeMiscellaneousLevelVal = true;
  //  }

  //}
  //selectLevelType(evt) {
  //  debugger
  //  let type = evt;
  //  let company = this.selectcompany;
  //  this.selectedlevel = type;
  //  if (company && type) {
  //    // this.getBranchName();
  //    this.activeValue = false;
  //    this.getMiscellaneousMstValue();
  //    this.MisDataTableForm.patchValue({
  //      company: this.selectcompany, type: type
  //    });
  //  }
  //  else {
  //    this.activeValue = true;
  //    this.MisDataTableForm.patchValue({
  //      company: null, type: null
  //    });
  //  }

  //}
  //selectMiscellaneousLevelValue(evt) {
  //  debugger
  //  let value = evt.value;
  //  let company = this.selectMiscellaneousLevelcompany;
  //  this.selectedMiscellaneousLevelvalue = value;
  //  if (company && value) {
  //    // this.getBranchName();
  //    this.MiscellaneousLevelForm.patchValue({
  //      company: this.selectMiscellaneousLevelcompany, value: value
  //    });
  //    //this.settingService.getLastBranchCode(company).subscribe(getresult => { this.getLastBranchCodeSuccess(getresult) },
  //    //  res => { this.onError(res) });
  //  }
  //  else {
  //    this.MiscellaneousLevelForm.patchValue({
  //      value: null
  //    });
  //  }

  //}
  getMiscellaneousLevelValueDetails() {
    debugger
    this.progressBarloading = true;
    let company = this.selectcompany;
    this.settingService.getMiscellaneousLevelValue(company).subscribe(getresult => { this.getMiscellaneousLevelValueSuccess(getresult) },
      res => { this.onError(res) });
  }
  getMiscellaneousLevelValueSuccess(getresult: any[]) {
    debugger
    this.progressBarloading = false;
    this.MiscellaneousLevelValueDetails = [];
    if (getresult.length > 0) {
      this.MiscellaneousLevelValueDetails = getresult;
    }
  }
  ModalOptionMiscellaneousLevelPopupLoad() {
    this.modalOptionMiscellaneousLevelPopup = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-lg modal-dialog-centered',
      containerClass: '',
      animated: true,
      data: {
        MiscellaneousLevelPopupmodaldata: {
          companyval: this.selectcompany,
          miscellaneousvalue: this.selectedlevel,
          miscellaneouslevelid: this.miscellaneouslevelid,
          miscellaneouslevelflag: this.MiscellaneousLevelPopupflag
        }
      }
    }
  }
  openMiscellaneousLevelPopupModal(val, flag) {
    debugger
    this.MiscellaneousLevelPopupflag = flag;
    if (val && flag == 'edit') {
      this.selectcompany = val.company;
      this.selectedlevel = val.value;
      this.miscellaneouslevelid = val.miscellaneouslevelid;
    }
    else if (val == '' || val == undefined && flag == 'add') {
      this.selectcompany = this.selectcompany;
      /*this.selectedlevel = '';*/
      this.miscellaneouslevelid = '';
    }
    else {
      this.selectcompany = '';
      /*this.selectedlevel = '';*/
      this.miscellaneouslevelid = '';
    }

    this.ModalOptionMiscellaneousLevelPopupLoad();
    this.MiscellaneousLevelPopupModal();
  }
  MiscellaneousLevelPopupModal() {
    this.modalRef = this.modalService.show(MiscellaneousLevelPopupComponent, this.modalOptionMiscellaneousLevelPopup);
    this.modalRef.content.action.subscribe((result: any) => {
      debugger
      this.MiscellaneousPopup = [];
      this.MiscellaneousPopup = result;
      if (result == 'delete') {
        this.activeValue = true;
        this.getMiscellaneousLevelValueDetails();
        /*this.selectCompany(this.selectcompany);*/
        this.loadLevelType();
        this.MisDataTableForm.patchValue({
          type: null,value:null
        });
      }
      if (this.MiscellaneousLevelPopupflag == 'add') {
        //this.activeLevel = false;
        //this.activeValue = true;
        //this.getMiscellaneousLevelValueDetails();
        //this.selectCompany(this.selectcompany);

        //this.MisDataTableForm.patchValue({
        //  company: this.selectcompany, type: result.value
        //});
        this.MisDataTableForm.patchValue({
           type: result.value
        });
        if (result.value == '' || result.value == undefined) {
          this.insertMiscellaneousLevelTable();
        }
        else {
          this.insertMiscellaneousLevelTable();
        }

      }
      else if (this.MiscellaneousLevelPopupflag == 'edit' && result != 'delete') {
        this.getMiscellaneousLevelValueDetails();
        //this.selectCompany(this.selectcompany);
        this.selectLevelType(result);
      }
    });
  }

  /* Lead Category */

  //LeadCategoryTableFormData() {
  //  this.activeLeadCategoryValue = true;
  //  this.LeadCategoryForm = this.formBuilder.group({
  //    leadname: [null, Validators.required],
  //    company: [null, Validators.required]
  //  });
  //}
  //get h() { return this.LeadCategoryForm.controls; }
  //enableLeadCategoryTable() {
  //  this.LeadCategoryTable = !this.LeadCategoryTable;
  //  this.submitted = false;
  //  this.enableEditLeadCategory = -1;
  //  this.LeadCategoryForm.reset();
  //  this.misDataTable = false;
  //  this.MiscellaneousLevelTable = false;
  //}
  //getLeadcategory() {
  //  this.settingService.getLeadcategoryMst().subscribe(getresult => { this.getLeadcategoryMstSuccess(getresult) },
  //    res => { this.onError(res) });
  //}
  //getLeadcategoryMstSuccess(getresult: any[]) {
  //  this.LeadCategoryDetails = [];
  //  if (getresult.length > 0) {
  //    this.LeadCategoryDetails = getresult;
  //    /*this.loadLeadCategoryDetails();*/
  //  }
  //}
  //loadLeadCategoryDetails() {
  //  this.LeadCategoryDetailscount = this.LeadCategoryDetails.length;
  //  this.mdbTable.setDataSource(this.LeadCategoryDetails);

  //  this.LeadCategoryDetails = this.mdbTable.getDataSource();
  //  this.previousLeadCategory = this.mdbTable.getDataSource();
  //}
  //insertLeadCategoryTable() {
  //  this.LeadCategoryTableval = [];
  //  this.submitted = true;
  //  if (this.LeadCategoryForm.invalid) {
  //    return;
  //  }
  //  console.log(this.LeadCategoryForm.value);
  //  this.LeadCategoryTableval.push(this.LeadCategoryForm.value);
  //  this.insertedLeadCategoryValue = this.LeadCategoryForm.value.leadname;
  //  let strusername = this.Username;
  //  let list = [];
  //  this.LeadCategoryTableval.forEach(x => {
  //    let Item = {
  //      "leadname": x.leadname,
  //      "company": x.company
  //    };
  //    list.push(Item);
  //  });
  //  this.settingService.insertLeadcategoryMst(list).subscribe(getresult => { this.insertLeadcategoryMstSuccess(getresult) },
  //    res => { this.onError(res) });
  //}
  //insertLeadcategoryMstSuccess(getresult: any[]) {
  //  this.LeadCategoryDetails = [];
  //  this.insertedLeadCategory = [];
  //  if (getresult.length > 0) {
  //    this.LeadCategoryDetails = getresult;

  //    let leadmsg = this.LeadCategoryDetails[0].leadmsg;
  //    if (leadmsg == 'exist') {
  //      this.LeadCategoryForm.patchValue({
  //        leadname: null
  //      });
  //      this.toastrService.warning('Lead category already exists', '', this.options);
  //    }
  //    else {
  //      this.LeadCategoryForm.reset();
  //      this.LeadCategoryTableFormData();
  //      this.toastrService.success('Lead category created', '', this.options);
  //    }
  //  }
  //  this.submitted = false;
  //  /*this.getLeadCategoryValueDetails();*/
  //  this.selectLeadCategoryCompany(this.selectLeadCategorycompany);
  //  this.LeadCategoryDetails.forEach(x => {
  //    if (x.leadname == this.insertedLeadCategoryValue) {
  //      this.insertedLeadCategory = x;
  //      this.selectLeadCategoryValue(this.insertedLeadCategory);
  //    }
  //  });
  //}
  //editLeadCategoryTable(item, e, i) {
  //  this.enableEditLeadCategory = i;
  //  this.LeadCategoryTable = false;
  //  this.misDataTable = false;
  //  this.MiscellaneousLevelTable = false;
  //  this.LeadCategoryForm.patchValue({
  //    leadname: item.leadname, company: item.company
  //  });
  //}
 
  //cancelLeadCategoryTable(e, i) {
  //  this.enableEditLeadCategory = -1;
  //}
  //searchLeadCategoryItems() {
  //  const prev = this.mdbTable.getDataSource();

  //  if (!this.searchLeadCategoryText) {
  //    this.mdbTable.setDataSource(this.previousLeadCategory);
  //    this.LeadCategoryDetails = this.mdbTable.getDataSource();
  //  }

  //  if (this.searchLeadCategoryText) {
  //    this.LeadCategoryDetails = this.mdbTable.searchLocalDataBy(this.searchLeadCategoryText);
  //    this.mdbTable.setDataSource(prev);
  //  }
  //}
  //dtTablesortLeadCategory(property) {
  //  let direction = 0;
  //  this.isDescLeadCategory = !this.isDescLeadCategory; //change the direction
  //  this.columnLeadCategory = property;
  //  direction = this.isDescLeadCategory ? 1 : -1;

  //  this.LeadCategoryDetails.sort(function (a, b) {
  //    if (a[property] < b[property]) {
  //      return -1 * direction;
  //    }
  //    else if (a[property] > b[property]) {
  //      return 1 * direction;
  //    }
  //    else {
  //      return 0;
  //    }
  //  });
  //};


  //clearLeadCategory() {
  //  this.LeadCategoryForm.reset();
  //  this.LeadCategoryTableFormData();
  //  this.selectLeadCategorycompany = '';
  //  this.selectedLeadCategoryvalue = '';
  //  this.leadcategoryid = '';
  //  this.LeadCategoryPopupflag = '';
  //  this.activeLeadCategoryValue = true;
  //}



  //selectLeadCategoryCompany(evt) {
  //  debugger
  //  this.clearMisData();
  //  let company = evt;
  //  this.selectLeadCategorycompany = company;
  //  if (company) {
  //    this.activeLeadCategoryValue = false;
  //    this.getLeadCategoryValueDetails();
  //    this.LeadCategoryForm.patchValue({
  //      company: company
  //    });
  //  }
  //  else {
  //    this.activeLeadCategoryValue = true;
  //  }

  //}
  //selectLeadCategoryValue(evt) {
  //  debugger
  //  let leadname = evt.leadname;
  //  let company = this.selectLeadCategorycompany;
  //  this.selectedLeadCategoryvalue = leadname;
  //  if (company && leadname) {
  //    this.LeadCategoryForm.patchValue({
  //      company: this.selectLeadCategorycompany, leadname: leadname
  //    });
  //  }
  //  else {
  //    this.LeadCategoryForm.patchValue({
  //      leadname: null
  //    });
  //  }

  //}
  //getLeadCategoryValueDetails() {
  //  debugger
  //  this.progressBarloading = true;
  //  let company = this.selectLeadCategorycompany;
  //  this.settingService.getLeadCategoryValue(company).subscribe(getresult => { this.getLeadCategoryValueSuccess(getresult) },
  //    res => { this.onError(res) });
  //}
  //getLeadCategoryValueSuccess(getresult: any[]) {
  //  debugger
  //  this.progressBarloading = false;
  //  this.LeadCategoryDetailsValue = [];
  //  if (getresult.length > 0) {
  //    this.LeadCategoryDetailsValue = getresult;
  //  }
  //}
  //ModalOptionLeadCategoryPopupLoad() {
  //  this.modalOptionLeadCategoryPopup = {
  //    backdrop: true,
  //    keyboard: true,
  //    focus: true,
  //    show: false,
  //    ignoreBackdropClick: false,
  //    class: 'modal-lg modal-dialog-centered',
  //    containerClass: '',
  //    animated: true,
  //    data: {
  //      LeadCategoryPopupmodaldata: {
  //        companyval: this.selectLeadCategorycompany,
  //        leadnamevalue: this.selectedLeadCategoryvalue,
  //        leadcategoryid: this.leadcategoryid,
  //        leadcategorypopupflag: this.LeadCategoryPopupflag
  //      }
  //    }
  //  }
  //}
  //openLeadCategoryPopupModal(val, flag) {
  //  debugger
  //  this.LeadCategoryPopupflag = flag;
  //  if (val && flag == 'edit') {
  //    this.selectLeadCategorycompany = val.company;
  //    this.selectedLeadCategoryvalue = val.leadname;
  //    this.leadcategoryid = val.leadcategoryid;
  //  }
  //  else if (val == '' || val == undefined && flag == 'add') {
  //    this.selectLeadCategorycompany = this.selectLeadCategorycompany;
  //    this.selectedLeadCategoryvalue = '';
  //    this.leadcategoryid = '';
  //  }
  //  else {
  //    this.selectLeadCategorycompany = '';
  //    this.selectedLeadCategoryvalue = '';
  //    this.leadcategoryid = '';
  //  }

  //  this.ModalOptionLeadCategoryPopupLoad();
  //  this.LeadCategoryPopupModal();
  //}
  //LeadCategoryPopupModal() {
  //  this.modalRef = this.modalService.show(LeadcategoryPopupComponent, this.modalOptionLeadCategoryPopup);
  //  this.modalRef.content.action.subscribe((result: any) => {
  //    debugger
  //    this.LeadCategoryPopup = [];
  //    this.LeadCategoryPopup = result;
  //    if (result == 'delete') {
  //      this.activeLeadCategoryValue = false;
  //      this.LeadCategoryTableFormData();
  //      this.getLeadCategoryValueDetails();
  //      this.selectLeadCategoryCompany(this.selectLeadCategorycompany);
  //      this.LeadCategoryForm.patchValue({
  //        leadname: null
  //      });
  //    }
  //    if (this.LeadCategoryPopupflag == 'add') {
  //      this.LeadCategoryForm.patchValue({
  //        company: this.selectLeadCategorycompany, leadname: result.leadname
  //      });
  //      if (result.leadname == '' || result.leadname == undefined) {
  //        this.insertLeadCategoryTable();
  //      }
  //      else {
  //        this.insertLeadCategoryTable();
  //      }

  //    }
  //    else if (this.LeadCategoryPopupflag == 'edit' && result != 'delete') {
  //      this.getLeadCategoryValueDetails();
  //      this.selectLeadCategoryCompany(this.selectLeadCategorycompany);
  //      this.selectLeadCategoryValue(result);
  //    }
  //  });
  //}
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      event.preventDefault();
    }

  }
  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }
  isNumberwithdot(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && charCode != 32 && charCode != 46 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }
  onError(res: any) {
    this.progressBarloading = false;
    console.log(res);
  }
}
