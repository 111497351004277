import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError, interval, Subscription } from 'rxjs'
import { AppSettings } from '../Appsettings';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
  formDigest: any = null;
  URI = AppSettings.OAuthSettings.redirectUri;
  constructor(private _http: HttpClient) { }

    // getAllContactlist(): Observable<any>{
    saveCreateContact(list): Observable<any> {
      return this._http.post(this.URI + 'api/AllContacts/InsertContact', list);
    }
    CustomerChangeDetails(prefix, strUsername, fieldName): Observable<any> {
      return this._http.get(this.URI + 'api/AllContacts/FetchSearchCustomerno/' +  prefix + '/' + strUsername + '/' + fieldName);
    }
    CsChangeDetails(prefix, strUsername, fieldName): Observable<any> {

      return this._http.get(this.URI + 'api/AllContacts/FetchSearchCsno/' + prefix + '/' + strUsername + '/' + fieldName);
    }
    EmailChangeDetails(prefix, strUsername, fieldName): Observable<any> {
      return this._http.get(this.URI + 'api/AllContacts/FetchSearchEmail/'+ prefix + '/' + strUsername + '/' + fieldName);
    }
  EmailChangeWithCustomerDetails(prefix, strUsername, s_customerid, e_customerid, fieldName): Observable<any> {
    if (s_customerid != '') {
      return this._http.get(this.URI + 'api/AllContacts/FetchSearchEmails_customerid/' + prefix + '/' + strUsername + '/' + s_customerid + '/' + fieldName);
    }
    else {
      return this._http.get(this.URI + 'api/AllContacts/FetchSearchEmaile_customerid/' + prefix + '/' + strUsername + '/' + e_customerid + '/' + fieldName);
    }
      //return this._http.get(this.URI + 'api/AllContacts/FetchSearchEmail/' + prefix + '/' + strUsername + '/' + customerOrcsValue +'/' + fieldName);
    }
  CompanyChangeDetails(prefix, strUsername, fieldName): Observable<any> {
    return this._http.get(this.URI + 'api/AllContacts/FetchSearchCompany/' + prefix + '/' + strUsername + '/' + fieldName);
    }
  CompanyChangeWithCustomerDetails(prefix, strUsername, s_customerid, e_customerid, fieldName): Observable<any> {
    if (s_customerid != '') {
      return this._http.get(this.URI + 'api/AllContacts/FetchSearchCompanys_customerid/' + prefix + '/' + strUsername + '/' + s_customerid + '/' + fieldName);
    }
    else {
      return this._http.get(this.URI + 'api/AllContacts/FetchSearchCompanye_customerid/' + prefix + '/' + strUsername + '/' + e_customerid + '/' + fieldName);
    }
    //return this._http.get(this.URI + 'api/AllContacts/FetchSearchCompany/' + prefix + '/' + strUsername + '/' + customerOrcsValue+'/' + fieldName);
  }
  SiteaddressChangeDetails(prefix, strUsername, fieldName): Observable<any> {
    return this._http.get(this.URI + 'api/AllContacts/FetchSearchSiteaddress/' + prefix + '/' + strUsername + '/' + fieldName);
  }
  SiteaddressChangeWithCustomerDetails(prefix, strUsername, s_customerid, e_customerid, fieldName): Observable<any> {
    if (s_customerid != '') {
      return this._http.get(this.URI + 'api/AllContacts/FetchSearchSiteaddresss_customerid/' + prefix + '/' + strUsername + '/' + s_customerid + '/' + fieldName);
    }
    else {
      return this._http.get(this.URI + 'api/AllContacts/FetchSearchSiteaddresse_customerid/' + prefix + '/' + strUsername + '/' + e_customerid + '/' + fieldName);
    }
    //return this._http.get(this.URI + 'api/AllContacts/FetchSearchSiteaddress/' + prefix + '/' + strUsername + '/' + customerOrcsValue + '/' + fieldName);
  }


    PhonenumberChangeDetails(prefix, strUsername,fieldName): Observable<any> {
      return this._http.get(this.URI + 'api/AllContacts/FetchSearchPhonenumber/' + prefix + '/' + strUsername +'/' + fieldName);
    }
  PhonenumberChangeWithCustomerDetails(prefix, strUsername, s_customerid, e_customerid, fieldName): Observable<any> {
    if (s_customerid != '') {
      return this._http.get(this.URI + 'api/AllContacts/FetchSearchPhnNos_customerid/' + prefix + '/' + strUsername + '/' + s_customerid + '/' + fieldName);
    }
    else {
      return this._http.get(this.URI + 'api/AllContacts/FetchSearchPhnNoe_customerid/' + prefix + '/' + strUsername + '/' + e_customerid + '/' + fieldName);
    }
      //return this._http.get(this.URI + 'api/AllContacts/FetchSearchPhonenumber/' + prefix + '/' + strUsername + '/' + customerOrcsValue + '/' + fieldName);
    }
    PhonenumberChangeforContactownerDetails(phonenumberExact, strUsername, fieldName): Observable<any> {
      return this._http.get(this.URI + 'api/AllContacts/FetchPhonenumber/' + phonenumberExact + '/' + strUsername + '/' + fieldName);
    }
    PhonenumberChangeforContactownerWithCustomerDetails(phonenumberExact, strUsername, s_customerid, e_customerid, fieldName): Observable<any> {
    if (s_customerid != '') {
      return this._http.get(this.URI + 'api/AllContacts/FetchPhnNoCustOrcss_customerid/' + phonenumberExact + '/' + strUsername + '/' + s_customerid + '/' + fieldName);
    }
    else {
      return this._http.get(this.URI + 'api/AllContacts/FetchPhnNoCustOrcse_customerid/' + phonenumberExact + '/' + strUsername + '/' + e_customerid + '/' + fieldName);
    }
      //return this._http.get(this.URI + 'api/AllContacts/FetchPhonenumberCustomerOrcs/' + phonenumberExact + '/' + strUsername + '/' + customerOrcsValue + '/' + fieldName);
  }


  OfficenumberChangeDetails(prefix, strUsername, fieldName): Observable<any> {
    return this._http.get(this.URI + 'api/AllContacts/FetchSearchOfficenumber/' + prefix + '/' + strUsername + '/' + fieldName);
  }
  OfficenumberChangeWithCustomerDetails(prefix, strUsername, s_customerid, e_customerid, fieldName): Observable<any> {
    if (s_customerid != '') {
      return this._http.get(this.URI + 'api/AllContacts/FetchSearchOfficeNos_customerid/' + prefix + '/' + strUsername + '/' + s_customerid + '/' + fieldName);
    }
    else {
      return this._http.get(this.URI + 'api/AllContacts/FetchSearchOfficeNoe_customerid/' + prefix + '/' + strUsername + '/' + e_customerid + '/' + fieldName);
    }
    //return this._http.get(this.URI + 'api/AllContacts/FetchSearchPhonenumber/' + prefix + '/' + strUsername + '/' + customerOrcsValue + '/' + fieldName);
  }
  OfficenumberChangeforContactownerDetails(officenumberExact, strUsername, fieldName): Observable<any> {
    return this._http.get(this.URI + 'api/AllContacts/FetchOfficenumber/' + officenumberExact + '/' + strUsername + '/' + fieldName);
  }
  OfficenumberChangeforContactownerWithCustomerDetails(officenumberExact, strUsername, s_customerid, e_customerid, fieldName): Observable<any> {
    if (s_customerid != '') {
      return this._http.get(this.URI + 'api/AllContacts/FetchOfficeNoCustOrcss_customerid/' + officenumberExact + '/' + strUsername + '/' + s_customerid + '/' + fieldName);
    }
    else {
      return this._http.get(this.URI + 'api/AllContacts/FetchOfficeNoCustOrcse_customerid/' + officenumberExact + '/' + strUsername + '/' + e_customerid + '/' + fieldName);
    }
    //return this._http.get(this.URI + 'api/AllContacts/FetchPhonenumberCustomerOrcs/' + phonenumberExact + '/' + strUsername + '/' + customerOrcsValue + '/' + fieldName);
  }




    SelectedCustomerDetails(s_customerid, Customernoexact, fieldName, strUsername): Observable<any> {
      return this._http.get(this.URI + 'api/AllContacts/FetchCustomerno/'+ s_customerid + '/' + Customernoexact + '/' + fieldName + '/' + strUsername);
    }
    SelectedCsDetails(s_customerid,Csnoexact, fieldName, strUsername): Observable<any> {
      return this._http.get(this.URI + 'api/AllContacts/FetchCsno/' + s_customerid + '/' + Csnoexact + '/' + fieldName + '/' + strUsername);
    }
    SelectedEmailDetails(s_customerid, e_customerid, EmailExact, fieldName, strUsername): Observable<any> {
        if (s_customerid != '') {
          return this._http.get(this.URI + 'api/AllContacts/FetchEmails_customerid/' + s_customerid + '/' + EmailExact + '/' + fieldName + '/' + strUsername);
        }
        else {
          return this._http.get(this.URI + 'api/AllContacts/FetchEmaile_customerid/' + e_customerid + '/' + EmailExact + '/' + fieldName + '/' + strUsername);
        }
  }
  SelectedCompanyDetails(s_customerid, e_customerid, CompanyExact, fieldName, strUsername): Observable<any> {
    if (s_customerid != '') {
      return this._http.get(this.URI + 'api/AllContacts/FetchCompanys_customerid/' + s_customerid + '/' + CompanyExact + '/' + fieldName + '/' + strUsername);
    }
    else {
      return this._http.get(this.URI + 'api/AllContacts/FetchCompanye_customerid/' + e_customerid + '/' + CompanyExact + '/' + fieldName + '/' + strUsername);
    }
  }
    selectedSiteaddressDetails(s_customerid, e_customerid, SiteaddressExact, fieldName, strUsername): Observable<any> {
        if (s_customerid != '') {
          return this._http.get(this.URI + 'api/AllContacts/FetchSiteaddresss_customerid/' + s_customerid + '/' + SiteaddressExact + '/' + fieldName + '/' + strUsername);
        }
        else {
          return this._http.get(this.URI + 'api/AllContacts/FetchSiteaddresse_customerid/' + e_customerid + '/' + SiteaddressExact + '/' + fieldName + '/' + strUsername);
        }
    }
    selectedPhonenumberDetails(s_customerid, e_customerid, phonenumberExact, fieldName, strUsername): Observable<any> {
        if (s_customerid != '') {
          return this._http.get(this.URI + 'api/AllContacts/FetchPhonenumbers_customerid/' + s_customerid + '/' + phonenumberExact + '/' + fieldName + '/' + strUsername);
        }
        else {
          return this._http.get(this.URI + 'api/AllContacts/FetchPhonenumbere_customerid/' + e_customerid + '/' + phonenumberExact + '/' + fieldName + '/' + strUsername);
        }
  }
  selectedOfficenumberDetails(s_customerid, e_customerid, officenumberExact, fieldName, strUsername): Observable<any> {
    if (s_customerid != '') {
      return this._http.get(this.URI + 'api/AllContacts/FetchOfficenumbers_customerid/' + s_customerid + '/' + officenumberExact + '/' + fieldName + '/' + strUsername);
    }
    else {
      return this._http.get(this.URI + 'api/AllContacts/FetchOfficenumbere_customerid/' + e_customerid + '/' + officenumberExact + '/' + fieldName + '/' + strUsername);
    }
  }
  getSiteaddressnamesearch(prefix, strusername, e_customerid, s_customerid): Observable<any> {
    return this._http.get(this.URI + 'api/AllContacts/FetchSiteaddressSearch/' + prefix + '/' + strusername + '/' + e_customerid + '/' + s_customerid);
  }
}
