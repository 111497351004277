import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation, Renderer2, ComponentFactoryResolver, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { Subject} from 'rxjs';
import { DatePipe } from '@angular/common';
import { AppComponentService } from '../../services/appcomponenet.service';
import { HeaderService } from '../../services/header.service';
import { SettingService } from '../../services/settings.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {

  @HostBinding('class.app-settings') someField: boolean = false;
  Username: any; BranchId: any; Userlevel: any; authorizeUserDetails: any[];

  previous: string; isDesc: boolean; column: any; contactid: any; modalRef: MDBModalRef;
  tabValue: any; activeAdmin: boolean; activeUser: boolean; settingsTab: any;
  constructor(private cdRef: ChangeDetectorRef, private appComponentService: AppComponentService,
    public datepipe: DatePipe, private modalService: MDBModalService, private toastrService: ToastService, public renderer2: Renderer2,
    private router: Router, private activatedroute: ActivatedRoute, private componentFactoryResolver: ComponentFactoryResolver,
    private headerService: HeaderService, private settingService: SettingService) { }

  ngOnInit() {
    debugger
    this.someField = true;
    this.activatedroute.queryParams.subscribe(params => this.settingsTab = params.tab);
    if (this.settingsTab == 'user') {
      this.tabValue = 'user';
    }
    else if (this.settingsTab == 'useradd') {
      this.tabValue = 'useradd';
    }
    else if (this.settingsTab == 'useredit') {
      this.tabValue = 'useredit';
    }
    else if (this.settingsTab == 'miscellaneous') {
      this.tabValue = 'miscellaneous';
    }
    if (this.tabValue == 'user' || this.tabValue == undefined) {
      this.openUser();
    }
    this.getAuthorizeUserDetails();
    
  }
  getAuthorizeUserDetails() {
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    let Level = '';
    this.authorizeUserDetails = [];
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      let UserId = this.authorizeUserDetails[0]['userid'];
      let BranchId = this.authorizeUserDetails[0]['branchid'];
      Level = this.authorizeUserDetails[0]['level'];
      if (UserId != "") {
        this.Username = UserId;
      }
      if (BranchId != "") {
        this.BranchId = BranchId;
      }
      if (Level != "") {
        this.Userlevel = Level;
      }
      if (Level) {
        Level = Level.toLowerCase();
      }
      if (Level != "") {
        //Level == 'manager,sales' || Level == 'manager,branch' || || Level == 'corp admin' || Level == 'branch admin'
        if ( Level == 'admin') {
          this.activeAdmin = true;
          this.activeUser = false;
        }
        else if (Level == 'manager') {
          this.activeAdmin = false;
          this.activeUser = false;
        }
        else {
          this.activeUser = true;
          this.activeAdmin = false;
        }
      }
    }
    this.updateUserLeadsCount();
  }


  openUser() {
    let RandomId = Math.floor(Math.random() * 10);
    var tabVal = 'user';
    this.headerService.listener('User Settings');
    this.router.navigate(['setting/user/' + RandomId], { queryParams: { tab: tabVal }, skipLocationChange: true });
    this.tabValue = 'user';
  }
  openUserAdd() {
    let RandomId = Math.floor(Math.random() * 10);
    var tabVal = 'useradd';
    this.headerService.listener('Add User');
    this.router.navigate(['setting/useradd/' + RandomId], { queryParams: { tab: tabVal }, skipLocationChange: true });
    this.tabValue = 'useradd';
  }
  //openUserEdit() {
  //  let RandomId = Math.floor(Math.random() * 10);
  //  var tabVal = 'useredit';
  //  this.headerService.listener('Update User');
  //  this.router.navigate(['setting/useredit/' + RandomId], { queryParams: { tab: tabVal }, skipLocationChange: true });
  //  this.tabValue = 'useredit';
  //}
  openBranchmaster() {
    let RandomId = Math.floor(Math.random() * 10);
    var tabVal = 'branchmaster';
    this.headerService.listener('Branches');
    this.router.navigate(['setting/branchmaster/' + RandomId], { queryParams: { tab: tabVal }, skipLocationChange: true });
    this.tabValue = 'branchmaster';
  }
  
  openDropdownEditor() {
    let RandomId = Math.floor(Math.random() * 10);
    var tabVal = 'miscellaneous';
    this.headerService.listener('Miscellaneous');
    this.router.navigate(['setting/miscellaneous/' + RandomId], { queryParams: { tab: tabVal }, skipLocationChange: true });
    this.tabValue = 'miscellaneous';
  }
  updateUserLeadsCount() {
    let strusername = this.Username;
    this.settingService.getUpdateUserLeadsCount(strusername).subscribe(getresult => { this.getUpdateUserLeadsCountSuccess(getresult) },
      res => { this.onError(res) });
  }
  getUpdateUserLeadsCountSuccess(getresult) {
    if (getresult.length > 0) {

    }
  }
  
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      evt.preventDefault();
    }

  }
  onError(res: any) {
    console.log(res);
  }
}
