import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation, Renderer2, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject} from 'rxjs';
import { DatePipe } from '@angular/common';
import { NewEvent } from '../Interface/new-event';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmationdilog',
  templateUrl: './confirmationdilog.component.html',
  styleUrls: ['./confirmationdilog.component.scss']
})
export class ConfirmationdilogComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
