import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError, interval, BehaviorSubject, Subject } from 'rxjs'
import { AppSettings } from '../Appsettings';

@Injectable({
    providedIn: 'root'
})
export class LeadGenerationService {
  formDigest: any = null;
  URI = AppSettings.OAuthSettings.redirectUri;
    //myEvent = new EventEmitter();
    bs = new BehaviorSubject('0');  cv;

  bsView = new BehaviorSubject([]); cvView;
  bsleadcount = new BehaviorSubject(0); cvleadcount;
  bsSettingTabChange = new BehaviorSubject(0); cvSettingTabChange;
  bsClearHeaderSearch = new BehaviorSubject(0); cvClearHeaderSearch;
  bsLeadsFromExternal = new BehaviorSubject(0); cvLeadsFromExternal;
    constructor(private _http: HttpClient) {
        this.cv = this.bs.asObservable();
      this.cvView = this.bsView.asObservable();
      this.cvleadcount = this.bsleadcount.asObservable();
      this.cvSettingTabChange = this.bsSettingTabChange.asObservable();
      this.cvClearHeaderSearch = this.bsClearHeaderSearch.asObservable();
      this.cvLeadsFromExternal = this.bsLeadsFromExternal.asObservable();
  }

    sendViewLead(dropdownList) {
        this.bsView.next(dropdownList);
  }
  sendLeadcount(newLeadcount) {
    this.bsleadcount.next(newLeadcount);
  }

  sendClearHeaderSearch() {
    this.bsClearHeaderSearch.next(0);
  }
  sendLeadsFromExternal() {
    this.bsLeadsFromExternal.next(0);
  }
  getAllLeads(strusername: any, flagValue: any): Observable<any> {
    return this._http.get(this.URI + 'api/LeadGeneration/FetchallLeads/' + strusername + '/' + flagValue);
  }
  getLeadDetails(strusername: any, leadid: any): Observable<any> {
    return this._http.get(this.URI + 'api/LeadGeneration/FetchLeadDetails/' + strusername + '/' + leadid);
  }
  getView(strusername: any, flagValue: any): Observable<any> {
    return this._http.get(this.URI + 'api/LeadGeneration/FetchLeadView/' + strusername + '/' + flagValue);
    }
  createLead(list): Observable<any> {
    return this._http.post(this.URI + 'api/LeadGeneration/InsertLead', list);
  }
  updateLead(list,leadid): Observable<any> {
    return this._http.put(this.URI + 'api/LeadGeneration/UpdateLead/' + leadid, list);
  }
  saveView(list): Observable<any> {
    debugger
    return this._http.post(this.URI + 'api/LeadGeneration/InsertLeadView',list);
  }
  deleteLead(strusername, leadid): Observable<any> {
    return this._http.delete(this.URI + 'api/LeadGeneration/DeleteLead/' + strusername + '/' + leadid);
  }
  deleteView(leadviewid, viewname, strusername, componetgettingFlag): Observable<any> {
    return this._http.delete(this.URI + 'api/LeadGeneration/DeleteLeadView/' + leadviewid + '/' + viewname + '/' + strusername + '/' + componetgettingFlag);
  }
  loadBranchdetails(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/LeadGeneration/FetchLeadBranchDetails/' + strusername);
  }
  loadBranchcode(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/LeadGeneration/FetchBranchCode/' + strusername);
  }
  loadLeadstatus(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/LeadGeneration/FetchLeadStatus/' + strusername);
  }
  loadLeadcategory(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/LeadGeneration/FetchLeadCategory/' + strusername);
  }
  loadLineofbusiness(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/LeadGeneration/FetchLineofbusiness/' + strusername);
  }
  updateLeadSentMail(leadid): Observable<any> {
    return this._http.get(this.URI + 'api/LeadGeneration/UpdateLeadSentMail/' + leadid);
  }
  getTechnicianEmail(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/LeadGeneration/FetchTechnicianEmail/' + strusername);
  }
  getManagerEmailForLead(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/LeadGeneration/FetchManagerEmailForLead/' + strusername);
  }
  getFetchTableSearchList(searchcategory, searchtext): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchTableSearchList/' + searchcategory + '/' + searchtext);
  }
}
