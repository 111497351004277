import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError, interval, BehaviorSubject, Subject, of } from 'rxjs'
import { AppSettings } from '../Appsettings';

@Injectable({
    providedIn: 'root'
})
export class AppComponentService {
  URI = AppSettings.OAuthSettings.redirectUri;
  Data: any;
  constructor(private _http: HttpClient) {
    }
    // getAllContactlist(): Observable<any>{
  getLogedinUser(): Observable<any> {
    debugger
    return this._http.get(this.URI + 'api/Authorize/FetchlogedInUser',
      { responseType: 'text' });
  }
  getLogOutUser(): Observable<any> {
    debugger
    return this._http.get(this.URI + 'api/Authorize/Logout',
      { responseType: 'text' });
  }
  getAuthorizationForUserMail(email): Observable<any> {
    this.Data = [];
    return this._http.get(this.URI + 'api/Authorize/FetchAuthorize/' + email)
      .pipe(
        map((result) => {
          this.Data = (result);
        console.log(this.Data);
         return result;
        }
        ));  
  }
  getSwitchForUser(switchuser, ProfileEmailid): Observable<any> {
    this.Data = [];
    return this._http.get(this.URI + 'api/Authorize/FetchSwitchser/' + switchuser + '/' + ProfileEmailid)
      .pipe(
        map((result) => {
          this.Data = (result);
          console.log(this.Data);
          return result;
        }
        ));
  }
  getAuthorizationForUser(): Observable<any> {
    return of (this.Data);
  }
  getAppVersion(): Observable<any> {
    return this._http.get(this.URI + 'api/Authorize/FetchAppVersion');
  }
}
