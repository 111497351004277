import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError, interval, BehaviorSubject, Subject } from 'rxjs'
import { AppSettings } from '../Appsettings';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationdilogComponent } from '../confirmationdilog/confirmationdilog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationdilogService {
  formDigest: any = null;
  URI = AppSettings.OAuthSettings.redirectUri;
  bs = new BehaviorSubject('');
  cv;
  constructor(private _http: HttpClient, private modalService: NgbModal) {
    this.cv = this.bs.asObservable();
  }
  sendEmployeecode(employeecode) {
    this.bs.next(employeecode);
  }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'Ok',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationdilogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }
}
