import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { AppSettings } from '../Appsettings';
// Model for the new event form
export class NewEvent {
  //subject?: string;
  //attendees?: string;
  //start?: string;
  //end?: string;
  //body?: string;
  //// Generate a MicrosoftGraph.Event from the model
  getCalenderGraphEvent(meettitle: string, meetnote: string, attendeesval: string, startdatetime: string,
    enddatetime: string): MicrosoftGraph.Event {
    const calendergraphEvent: MicrosoftGraph.Event = {
      subject: meettitle,
      start: {
        dateTime: startdatetime,
        timeZone: AppSettings.OAuthSettings.timeZone
      },
      end: {
        dateTime: enddatetime,
        timeZone: AppSettings.OAuthSettings.timeZone
      },
      //attendees: [
      //  {
      //    emailAddress: {
      //      address: attendeesval
      //    },
      //    type: "required"
      //  }
      //],
    };

     //If there are attendees, convert to array
    // and add them
    if (attendeesval.length > 0) {
      calendergraphEvent.attendees = [];

      const emails = attendeesval.split(',');
      emails.forEach(email => {
        calendergraphEvent.attendees.push({
          type: 'required',
          emailAddress: {
            address: email
          }
        });
      });
    }

    // If there is a body, add it as plain text
    if (meetnote.length > 0) {
      calendergraphEvent.body = {
        contentType: 'text',
        content: meetnote
      };
    }

    return calendergraphEvent;
  }
  //// Generate a MicrosoftGraph.Task from the model
  getTaskGraphEvent(tasktitle: string, priority: string, tasknote: string, startdatetime: string, reminderdatetime: string ,contactname: string,
    companyname: string, applicationName: string, redirectUri: string) {
    const taskgraphEvent = {
      title: tasktitle,
      importance: priority,
      linkedResources: [
        {
          webUrl: redirectUri,
          applicationName: applicationName,
          displayName: contactname +' ' +'(' + companyname + ')'
        }
      ],
      dueDateTime: {
        dateTime: startdatetime,
        timeZone: AppSettings.OAuthSettings.timeZone
      },
      reminderDateTime: {
        dateTime: reminderdatetime,
        timeZone: AppSettings.OAuthSettings.timeZone
      },
      body :{
        contentType: 'text',
        content: tasknote
      }
    };
    return taskgraphEvent;
  }
  //// Generate a MicrosoftGraph.Task from the model
  getUpdateTaskGraphEvent(tasktitle: string, priority: string, tasknote: string, startdatetime: string, reminderdatetime: string) {
    const taskgraphEvent = {
      title: tasktitle,
      importance: priority,
      dueDateTime: {
        dateTime: startdatetime,
        timeZone: AppSettings.OAuthSettings.timeZone
      },
      reminderDateTime: {
        dateTime: reminderdatetime,
        timeZone: AppSettings.OAuthSettings.timeZone
      },
      body: {
        contentType: 'text',
        content: tasknote
      }
    };
    return taskgraphEvent;
  }
  //// Generate a MicrosoftGraph.Emal from the model Starts
  getEmailGraphEvent(subject: string, body: string, to: string, cc: string, attachment: File, attachmentbyte: any) {
    let EmailGraphEvent;
    
    if (cc.length < 0) {
       EmailGraphEvent = {
        //message: {
          subject: subject,
          body: {
            contentType: 'HTML',
            content: body
          },

          toRecipients: [
            {
              emailAddress: {
                address: ''
              }
            }
          ]
        //}
      };
    }
    else {
       EmailGraphEvent = {
        //message: {
          subject: subject,
          body: {
            contentType: 'HTML',
            content: body
          },

          toRecipients: [
            {
              emailAddress: {
                address: ''
              }
            }
          ]
        //}
      };
    }
    if (cc.length < 0 && attachment != null) {
      EmailGraphEvent = {
       // message: {
          subject: subject,
          body: {
            contentType: 'HTML',
            content: body
          },

          toRecipients: [
            {
              emailAddress: {
                address: ''
              }
            }
          ],
          bccRecipients: [
            {
              emailAddress: {
                address: ''
              }
            }
          ],
          attachments: [
            {
              '@odata.type': '#microsoft.graph.fileAttachment',
              name: attachment.name,
              contentType: attachment.type,
              contentBytes: attachmentbyte
            }
          ]
        //}
      };
    }
    else if (attachment != null) {
      EmailGraphEvent = {
        //message: {
          subject: subject,
          body: {
            contentType: 'HTML',
            content: body
          },

          toRecipients: [
            {
              emailAddress: {
                address: ''
              }
            }
          ],
          attachments: [
            {
              '@odata.type': '#microsoft.graph.fileAttachment',
              name: attachment.name,
              contentType: attachment.type,
              contentBytes: attachmentbyte
            }
          ]
        //}
      };
    }
    if (to.length > 0) {
      //EmailGraphEvent.message.toRecipients = [];
      EmailGraphEvent.toRecipients = [];
    const emails = to.split(',');
    emails.forEach(email => {
      EmailGraphEvent.toRecipients.push({
        emailAddress: {
          address: email
        }
      });
    });
    }
    if (cc.length > 0) {
      EmailGraphEvent.ccRecipients = [];
      const emails = cc.split(',');
      emails.forEach(email => {
        EmailGraphEvent.ccRecipients.push({
          emailAddress: {
            address: email
          }
        });
      });
    }
    return EmailGraphEvent;
  }
  //Email Part Close




  /*Email For Approve or Reject*/
  getEmailidManagerGraphEvent(subject: string, body: string, to: string, cc: string) {
    let EmailGraphEvent;

    if (cc.length < 0) {
      EmailGraphEvent = {
        //message: {
        subject: subject,
        body: {
          contentType: 'HTML',
          content: body
        },

        toRecipients: [
          {
            emailAddress: {
              address: ''
            }
          }
        ]
        //}
      };
    }
    else {
      EmailGraphEvent = {
        //message: {
        subject: subject,
        body: {
          contentType: 'HTML',
          content: body
        },

        toRecipients: [
          {
            emailAddress: {
              address: ''
            }
          }
        ]
        //}
      };
    }
    if (to.length > 0) {
      //EmailGraphEvent.message.toRecipients = [];
      EmailGraphEvent.toRecipients = [];
      const emails = to.split(',');
      emails.forEach(email => {
        EmailGraphEvent.toRecipients.push({
          emailAddress: {
            address: email
          }
        });
      });
    }
    if (cc.length > 0) {
      EmailGraphEvent.ccRecipients = [];
      const emails = cc.split(',');
      emails.forEach(email => {
        EmailGraphEvent.ccRecipients.push({
          emailAddress: {
            address: email
          }
        });
      });
    }
    return EmailGraphEvent;
  }
  //createHtmlTableRow = (producto) => `
  //        <tr>
  //          <td size="3">
  //            <label>${producto.marca}</label>
  //          </td>
  //          <td size="4">
  //            <label>${producto.producto}</label>
  //          </td>
  //          <td size="3">
  //            <label>${producto.presentacion}</label>
  //          </td>
  //          <td size="2">
  //            <label>${producto.cantidad}</label>
  //          </td>
  //        </tr>`;

  //createHtmlTemplate = (products) => {
  //  let emailTemplate: string = `<table>
  //    <thead>
  //      <tr>
  //        <th>
  //          <label>MARCA</label>
  //        </th>
  //        <th>
  //          <label>PRODUCTO</label>
  //        </th>
  //        <th>
  //          <label>PRESENT.</label>
  //        </th>
  //        <th>
  //          <label>CANT.</label>
  //        </th>
  //      </tr>
  //    </thead>
  //    <tbody>`;

  createHtmlTableRow = (employee) => `
          <tr>
            <td size="3">
              <label>${employee.manageruserid}</label>
            </td>
            <td size="4">
              <label>${employee.employeename}</label>
            </td>
            <td size="4">
              <label>${employee.leadcount}</label>
            </td>
          </tr>`;

  createHtmlTemplate = (products) => {
    let emailTemplate: string = `<table>
      <thead>
        <tr>
          <th>
            <label>Manager</label>
          </th>
          <th>
            <label>Employee Name</label>
          </th>
          <th>
            <label>Lead Count</label>
          </th>
        </tr>
      </thead>
      <tbody>`;
    for (let product of products) {
      emailTemplate += this.createHtmlTableRow(product);
    }

    emailTemplate += '</tbody></table>';
    debugger
    return emailTemplate;
  }
}
