import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MDBModalRef, MDBModalService, MdbTableDirective, ToastService } from 'ng-uikit-pro-standard';
import { Subject} from 'rxjs';
import { DatePipe } from '@angular/common';
import { AppComponentService } from '../../services/appcomponenet.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptoService } from '../../services/crypto.service';
import { HeaderService } from '../../services/header.service';
import { SettingService } from '../../services/settings.service';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { AppSettings } from '../../Appsettings';
import { NgxSpinnerService } from "ngx-spinner";
import { ExcelService } from '../../services/excel.service';
import { SearchedField, SearchType } from '../../Interface/user';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserSettingComponent implements OnInit {
  Username: any; FullnameValue: any; EmailidValue: any; authorizeUserDetails: any[]; jobno: any;
  filterString: any = ''; filtered: any; Application: any; Password: any; estimatorUrl: any; Level: any; reverse: boolean;

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(InfiniteScrollDirective, { static: true }) infiniteScrollDirective;
  @ViewChild('userTable', { static: false }) userTable: ElementRef;
  searchText: any; previous: string; isDesc: boolean; column: any;
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };

  modalOptions: any; modalRef: MDBModalRef; userDetails: any[]; Usernamedetails: any[];
  rowid: any; userDetailscount: any; tblusernameval: any; selectedUser: any; afterSelectUser: boolean = false; tbluserDefaultval: any;
  activeUserExpand: boolean; UserData: any; activeUserExpandflag: any;

  userAddmodalOptions: any; userEditmodalOptions: any;
  activeAdmin: boolean; activeBtn: boolean=false; Branchnamedetails: any[]; userid: any; company: any;
  allUserCheckData: any[]; usercheckactive: boolean; scrollCheck: boolean; NewUserDetails: any[];
  SearchedFieldName: any = null; /*searchFieldText: any = '';*/ downloadExcel: boolean = false; TypeofSearch: any = null;
  SearchedFieldArray: any[]; SearchTypeArray: any[];

  FetchTableSearchList: any[]; loadGifTableSearch: boolean; keywordTableSearch = 'searchoption'; historyHeadingTableSearch: any; searchTextFlag: any = '0';
  @ViewChild('ngAutoCompleteSearch', { static: false }) ngAutoCompleteSearch;
  constructor(private appComponentService: AppComponentService,
    private router: Router, private activatedroute: ActivatedRoute, private headerService: HeaderService,
    public cryptoservice: CryptoService, private cdRef: ChangeDetectorRef, private modalService: MDBModalService, public formBuilder: FormBuilder,
    private settingService: SettingService, private toastrService: ToastService, private spinner: NgxSpinnerService, private excelService: ExcelService,
    public datepipe: DatePipe  ) {
    this.allUserCheckData = [];
  }

  ngOnInit() {
    debugger
    this.tblusernameval = 'Select User';
    this.tbluserDefaultval = 'default';
    this.SearchedFieldArray = [];
    this.SearchTypeArray = [];
    let SearchedFieldVal = new SearchedField();
    this.SearchedFieldArray = SearchedFieldVal.SearchedFieldArray;
    let SearchTypeVal = new SearchType();
    this.SearchTypeArray = SearchTypeVal.SearchTypeArray;
    this.getAuthorizeUserDetails();
  }
  getAuthorizeUserDetails() {
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    this.authorizeUserDetails = []; let Level = '';
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      var Fullname = this.authorizeUserDetails[0]['fullname'];
      var UserId = this.authorizeUserDetails[0]['userid'];
      var BranchName = this.authorizeUserDetails[0]['branchname'];
       Level = this.authorizeUserDetails[0]['level'];
      var Emailid = this.authorizeUserDetails[0]['email'];
      var Application = this.authorizeUserDetails[0]['application'];
      var Password = this.authorizeUserDetails[0]['password'];
      if (UserId != "") {
        this.Username = UserId;
      }
      if (Fullname != "") {
        this.FullnameValue = Fullname;
      }
      if (Emailid != "") {
        this.EmailidValue = Emailid;
      }
      if (Application != "") {
        this.Application = Application;
      }
      if (Password != "") {
        this.Password = Password;
      }
      if (Level != "") {
        this.Level = Level;
      }
      if (Level) {
        Level = Level.toLowerCase();
      }
      if (Level != "") {
        if (Level == 'admin') {
          this.activeAdmin = true;
          this.activeBtn = true;
        }
        else {
          this.activeAdmin = false;
          this.activeBtn = false;
        }
      }
    }
    this.getBranchname();
  }

  
  loadAllTableData() {
    debugger
    this.allUserCheckData = [];
    let user = this.selectedUser;
    this.activeUserExpandflag = 1;
    this.UserData = -1;
    if (user != null && user != 'default') {
      this.afterSelectUser = true;
      this.spinner.show();
      this.TypeofSearch = null;
      this.getUser();
    }
    else if (user == 'default') {
      this.afterSelectUser = true;
      this.spinner.show();
      this.getUser();
      this.searchText = '';
      this.TypeofSearch = 'columnsearch';
      this.loadTypeOfSearch();
    }
    else {
      this.searchText = '';
      this.TypeofSearch = null;
      this.SearchedFieldName = null;
      this.downloadExcel = false;
      this.userDetails = [];
      this.afterSelectUser = false;
      this.spinner.hide();
    }
  }
  loadTypeOfSearch() {
    debugger
    let TypeofSearch = this.TypeofSearch;
    if (TypeofSearch == 'globalsearch') {
      this.SearchedFieldName = null;
      this.FetchTableSearchList = [];
    }
    else if(TypeofSearch == null) {
      this.clearSearch();
    }
    else {
      this.SearchedFieldName = 'businessunit';
    }
  }
  loadSearchedField() {
    debugger
    let SearchedFieldName = this.SearchedFieldName;
    if (SearchedFieldName == null) {
      this.clearSearch();
    }

  }
  getFetchTableSearchListSuccess(getresult: any[]) {
    debugger
    this.loadGifTableSearch = false;
    this.FetchTableSearchList = [];
    if (getresult.length > 0) {
      this.FetchTableSearchList = getresult;
    }
  }
  getSelectedSearchText(evt) {
    if (evt) {
      this.searchTextFlag ='1';
    }
  }
  getSearchTextChange(evt) {
    debugger
    this.loadGifTableSearch = true;
    let searchcategory = this.SearchedFieldName;
    let searchtext = '';
    if (evt) {
      searchtext = evt;
    }
    else {
      searchtext = null;
    }
    this.settingService.getFetchTableSearchList(searchcategory, searchtext).subscribe(getresult => { this.getFetchTableSearchListSuccess(getresult) },
      res => { this.onError(res) });
  }
  FocusedSearchTextChange(evt) {
    debugger
    let searchcategory;
    this.loadGifTableSearch = true;
     searchcategory = this.SearchedFieldName;
    let searchtext = null;
    if (this.TypeofSearch == 'globalsearch') {
      searchcategory = 'null';
    }
    else {
      searchcategory = searchcategory;
    }
    this.settingService.getFetchTableSearchList(searchcategory, searchtext).subscribe(getresult => { this.getFetchTableSearchListSuccess(getresult) },
      res => { this.onError(res) });
  }
  ClearedSearchTextChange() {
    this.searchTextFlag = '0';
    this.FetchTableSearchList = [];
    this.loadGifTableSearch = false;
    this.spinner.hide();
    this.ngAutoCompleteSearch.close();
    this.getUser();
  }
  clearSearch() {
    debugger
      this.searchText = '';
      this.TypeofSearch = null;
      this.SearchedFieldName = null;
      this.downloadExcel = false;
      this.loadGifTableSearch = false;
      this.spinner.hide();
      this.ngAutoCompleteSearch.close();
      this.getUser();
  }
  getUsername() {
    this.settingService.getUsername().subscribe(getresult => { this.getUsernameSuccess(getresult) },
      res => { this.onError(res) });
  }
  getUsernameSuccess(getresult: any[]) {
    this.Usernamedetails = [];
    if (getresult.length > 0) {
      this.Usernamedetails = getresult;
      this.selectedUser = this.Username;
      this.loadAllTableData();
    }
  }
  getBranchname() {
    let strusername = this.Username;
    this.settingService.getBranchdetails(strusername).subscribe(getresult => { this.getBranchdetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getBranchdetailsSuccess(getresult: any[]) {
    this.Branchnamedetails = [];
    if (getresult.length > 0) {
      this.Branchnamedetails = getresult;
    }
    this.getUsername();
  }
  getUser() {
    debugger
    this.spinner.show();
    let strusername = this.Username;
    let user = this.selectedUser;
    this.settingService.getUser(strusername, user).subscribe(getresult => { this.getUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getUserSuccess(getresult: any[]) {
    debugger
    this.userDetails = [];
    if (getresult.length > 0) {
      this.userDetails = getresult;
      //this.NewUserDetails = getresult;
    }
    this.loadUserDetails();
  }
  loadUserDetails() {
    this.spinner.hide();
    this.NewUserDetails = [];
    this.spinner.hide();
    this.userTable.nativeElement.scrollTop = 0;
    this.userDetailscount = this.userDetails.length;
    if (this.Branchnamedetails.length > 0) {
      this.userDetails.forEach((x) => {
        this.Branchnamedetails.forEach((y) => {
          if (x.branchid == y.branchcode) {
            x.branchname = y.branchname;
          }
        })
      });
    }
    this.userDetails = this.userDetails.map(x => ({ ...x, isselecteduser: false }));
    if (this.userDetails.length == 1) {
      //this.allUserCheckData.push(this.userDetails);
      this.allUserCheckData = this.userDetails;
      this.userDetails.forEach(child => {
        child.isselecteduser = true
        this.usercheckactive = true;
      })
    }
    else if (this.selectedUser =='default') {
      this.userDetails.forEach(child => {
        child.isselecteduser = false
        this.usercheckactive = false
      })
    }
    console.log(this.userDetails);
    this.mdbTable.setDataSource(this.userDetails);

    this.userDetails = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.scrollCheck = false;
    this.NewUserDetails = this.userDetails.slice(0, AppSettings.OAuthSettings.lazyLoadingCount);
  }
  isAllSelected(e, data) {
    debugger
    this.allUserCheckData = [];
    if (e.target.checked) {
      this.allUserCheckData.push(data);
      if (this.allUserCheckData.length >= 1) {
        this.userDetails.forEach(child => {
          child.isselecteduser = false
          this.usercheckactive = false
        })
        this.userDetails.forEach(child => {
          if (data.rowid == child.rowid) {
            child.isselecteduser = true
            this.usercheckactive = true
          }
        })
        //this.allUserCheckData = [];
       // this.allUserCheckData.push(data);
       // this.allUserCheckData = data;
      }
      else {
        this.allUserCheckData = [];
        this.usercheckactive = true;
        //this.allUserCheckData.push(data);
        //this.allUserCheckData = data;
      }
    }
    else {
      if (this.allUserCheckData.length > 1) {
        this.allUserCheckData.splice(this.allUserCheckData.indexOf(data), 1);
      }
      else {
        this.allUserCheckData = [];
      }
      
      this.usercheckactive = false;
    }
    //if (this.allLeadlistData.length > 0) {
    //  this.contactHeader = false;
    //}
    //else {
    //  this.contactHeader = true;
    //  this.loadLeadsGrid();
    //}
    //this.masterSelected = this.allLeadlist.every(function (item: any) {
    //  return item.isselectedlead == true;
    //})

  }
  editUserTable(e, i) {
    debugger
    this.activeUserExpandflag = 1;
    this.UserData = -1;
    let RandomId = Math.floor(Math.random() * 10);
    let rowid = this.allUserCheckData[0].rowid;
    let userid = this.allUserCheckData[0].userid;
    let tabVal = 'useredit';
    
    //this.router.navigate(['setting/useraddedit/' + RandomId, { RowId: rowid, userFlag: '1', userId: userid }], { queryParams: { tab: tabVal }, skipLocationChange: true });
   

    this.headerService.listener('Edit User');
    //this.router.navigate(['setting/useraddedit/' + RandomId], { queryParams: { tab: tabVal }, skipLocationChange: true });
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['setting/useredit/' + RandomId, { RowId: rowid,userId: userid }], { queryParams: { tab: tabVal }, skipLocationChange: true }));
   // this.settingService.sendbsSettingTabChange(tabVal);

  }
  deleteUserTable( e, i) {
    debugger
    this.spinner.show();
    this.activeUserExpandflag = 1;
    this.UserData = -1;
    let rowid = this.allUserCheckData[0].rowid;
    let user = this.selectedUser;
    if (confirm("Are you sure you want to delete this user")) {
      this.settingService.deleteUser(rowid, user).subscribe(getresult => { this.deleteUserSuccess(getresult) },
        res => { this.onError(res) });
      return true;
    }
    else {
      return false;
    }
  }
  deleteUserSuccess(getresult: any[]) {
    this.spinner.hide();
    this.activeUserExpandflag = 0;
    this.userDetails = [];
    if (getresult.length > 0) {
      this.userDetails = getresult;
    }
    this.allUserCheckData = [];
    this.activeUserExpandflag = 1;
    this.UserData = -1;
    this.getUsername();
    this.getUser();
    this.selectedUser = '';
    this.toastrService.error('User deleted', '', this.options);
    this.loadUserDetails();
    this.userDetails.forEach(child => {
      child.isselecteduser = false
      this.usercheckactive = false
    })
  }
  onScroll() {
    debugger

    console.log("scrolled!!");
    this.scrollCheck = true;

    /* const length = this.no;
     setTimeout(() => {
       const p = ' '.repeat(this.Estimates.length).split('').map((s, i) => i + 1 + this.Estimates.length)
 
       // This approach should be used to avoid creating another memory address to the array
       while (p.length <= this.Estimates.length) this.Estimatesnew.push(p.shift())
     }, 1000) */
    if (!this.searchText) {
      if (this.NewUserDetails.length >= this.userDetails.length) {
        return false;
      }
      else {
        this.scrollCheck = false;
        const remainingLength = Math.min(100, this.userDetails.length - this.NewUserDetails.length);
        this.NewUserDetails.push(...this.userDetails.slice(this.NewUserDetails.length, this.NewUserDetails.length + remainingLength));
        return true;
      }
    }
    else {
      return false;
    }


    /* const scrollTop = evt.currentTarget.scrollTop;
     const scrollHeight = evt.target.scrollHeight;
     const offsetHeight = evt.target.offsetHeight;
     const scrollPosition = scrollTop + offsetHeight;
     const scrollTreshold = scrollHeight - this.pageHeight;
     if (scrollPosition > scrollTreshold) {
       this.pageEnd += this.pageBuffer;
     }
    | slice: pageStart: pageEnd  */
  }
  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }
  searchItems() {
    debugger
    this.spinner.show();
    let SearchedFieldName = this.SearchedFieldName;
    let TypeofSearch = this.TypeofSearch;
    const prev = this.mdbTable.getDataSource();
    if (this.searchTextFlag == '1') {
      this.searchText = this.searchText.searchoption;
    }
    else {
      this.searchText = this.searchText;
    }
    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.NewUserDetails = this.mdbTable.getDataSource();
      this.clearSearch();
    }
    else if (this.searchText && TypeofSearch == 'globalsearch') {
      this.spinner.hide();
      this.NewUserDetails = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
    else {
      this.spinner.hide();
      this.NewUserDetails = this.mdbTable.searchLocalDataByFields(this.searchText.toLowerCase(), [SearchedFieldName]);
      //this.userDetails = this.mdbTable.getDataSource().filter((item: any) => {
      //  /* Customize this filter logic based on your data structure.*/
      //  return item.businessunit.toLowerCase().includes(this.searchFieldText.toLowerCase())
      //    || item.company.toLowerCase().includes(this.searchFieldText.toLowerCase())
      //    || item.manager.toLowerCase().includes(this.searchFieldText.toLowerCase());
      //});
      this.mdbTable.setDataSource(prev);
    }
    console.log(this.NewUserDetails.length);
    this.spinner.hide();
    this.downloadExcel = true;
  }
  backToLoginUser() {
    debugger
    this.allUserCheckData = [];
    this.selectedUser = this.Username;
    this.activeUserExpandflag = 1;
    this.UserData = -1;
    this.afterSelectUser = true;
    this.clearSearch();
  }
  dtTablesortUser(property) {
    let direction = 0;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    direction = this.isDesc ? 1 : -1;

    this.NewUserDetails.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  openUserAdd() {
    let RandomId = Math.floor(Math.random() * 10);
    let tabVal = 'useradd';
    this.headerService.listener('Add User');
    //this.router.navigate(['setting/useradd/' + RandomId], { queryParams: { tab: tabVal }, skipLocationChange: true });
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['setting/useradd/' + RandomId], { queryParams: { tab: tabVal }, skipLocationChange: true }));
  }
//  showUserDataDetails(i, user) {
//    this.rowid = user.rowid;
//    this.userid = user.userid;
//    this.company = user.company;
//    if (this.activeUserExpandflag == 1 && this.Level =='Technician') {
//      this.activeUserExpand = false;
//    }
//    else {
//      this.activeUserExpand = true;
//    }
//    if (this.activeUserExpand == true) {
//      this.UserData = this.UserData == i ? null : i;
//    }
//    else {
//      this.UserData = -1;
//    }
///*    this.openCommissionStructure();*/
//  }
  exportExcelUser() {
    debugger
    if (this.downloadExcel == true) {
      let filterExportLeads = this.NewUserDetails.map(function (obj) {
        return {
          firstname: obj.firstname,
          lastname: obj.lastname,
          userid: obj.userid,
          level: obj.level,
          branchname: obj.branchname,
          mobilenumber: obj.mobilenumber,
          email: obj.email,
          company: obj.company,
          active: obj.active == true ? '1' : '0',
          createdDate: obj.createdDate,
          manager: obj.manager,
          manager2: obj.manager2,
          manager3: obj.manager3,
          businessunit: obj.businessunit,
          leadscreatedbycurrmonth: obj.leadscreatedbycurrmonth,
          leadscreatedbyprevmonth: obj.leadscreatedbyprevmonth,
          totalleads: obj.totalleads,
          dateLastlogin: obj.dateLastlogin,
          employeenumber: obj.employeenumber,
          isexecutiveemail: obj.isexecutiveemail==true?'1':'0'
        }
      });
      if (filterExportLeads) {
        //this.downloadFile(filterExportLeads);
        let excelfilename = 'Export User';
        let excelHeaders = ['First Name', 'Last Name', 'User Id', 'Level', 'Branch Name', 'Phone', 'Email', 'Company', 'Active',
          'Created Date', 'Manager', 'Manager 2', 'Manager 3', 'Business Unit', ' # Leads Curr Month', '# Leads Prev Month',
          '# Total Leads', 'Last Login Date', 'Employee Number','Receive Executive Reports'];
        this.excelService.exportAsExcelFile(filterExportLeads, this.Username + '(' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + ')' + ' ' + excelfilename, excelHeaders);
        this.toastrService.success('Excel exported', '', this.options);
      }
    }
   /* this.clearSearch();*/
  }
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      evt.preventDefault();
    }

  }
  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }
  isNumberwithdot(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && charCode != 32 && charCode != 46 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }

  onError(res: any) {
    this.spinner.hide();
    console.log(res);
  }
}
