import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AlertsService } from './alerts.service';
import { AppSettings } from '../Appsettings';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DynamicsService {
  pformDigest: any = null;
  URI = AppSettings.OAuthSettings.redirectUri;
  constructor(private _http: HttpClient) {
  }
  createLeadToDynamics(list): Observable<any> {
    debugger
    const headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
    return this._http.post(this.URI + 'api/Dynamics/CreateLeadToDynamics', list, { headers, responseType: 'text' as 'json' });
  }
  getLeadToDynamics(): Observable<any> {
    debugger
    return this._http.get(this.URI + 'api/Dynamics/GetLeadToDynamics');
  }
}
