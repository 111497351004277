import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent  {

    title = 'Angular 7 CheckBox Select/ Unselect All';
}
