import { Component, OnInit, AfterContentChecked, ChangeDetectorRef ,Input, OnChanges, ViewChild, HostListener, Renderer2, OnDestroy, ElementRef, ComponentRef, AfterViewInit} from '@angular/core';
import { HeaderService } from 'src/app/services/header.service';
import { AppComponentService } from 'src/app/services/appcomponenet.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { AuthendicationService } from '../../services/authendication.service';
/*import { MsalService } from '@azure/msal-angular';*/
import { CryptoService } from '../../services/crypto.service';
import { GraphService } from '../../services/graph.service';
import { NewEvent } from '../../Interface/new-event';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { interval, Subscription } from 'rxjs';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { AppSettings } from '../../Appsettings';
import { AuthGuardService } from '../../services/auth-guard.service';
import { LeadGenerationService } from '../../services/leadgeneration.service';
import { HttpEventType } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ManagerPopupComponent } from '../managerpopup/managerpopup.component';
import { SettingService } from '../../services/settings.service';
import { EmailService } from '../../services/email.service';
import { SearchedField } from '../../Interface/user';
import { ExecutiveLeadsReportService } from '../../services/executiveleads.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterContentChecked, OnChanges, OnDestroy {
  Username: any; FullnameValue: any; EmailidValue: string; authorizeUserDetails: any[]; loadAuthorizeUserDetails: any[]; Salesperson: any[];
  userLevel: any; HeaderTitle: any; switchuserLengthVal: boolean; @Input() connectionstatus: any;
  connectionstatusVal: boolean; togglebtn: boolean; fieldvalselectlevel: any; company: any; headerlogo: any;
  blackpanellogo: any; useridLevel: any; searchAllActive: boolean; componentValue: any; loadGifsearch: boolean;
  searchData: any[]; searchAll: any; historyHeadingsearch: any; notification: boolean; leadCount: any[]; Newleadcount: any;
  /*@ViewChild('sidenav', { static: true }) public sidenav: any;*/
  @Input() switchuserLength: any; userAndbranch: any; BranchnameDetails: any[]; Branchid: any; Branchname: any; reportsectionactive: boolean; Manager: any='';

  /* Email Sent to Executive */
  showpop = false;
  downloadExcel: boolean = false;fileToUpload: File = null; base64textString: any; OutlookemailList: any[]; model = new NewEvent(); ExecutiveEmail: any[];
  ccemails: any; Emails: any[]; previousmonth: boolean; LeadsIndividualStatusCount: any[]; EmailBody: any; EmailBodyArray: any[];
  date = new Date(); dateval: any; datenameval: any; weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' }; fromdate: any = ''; todate: any = ''; ExportLeadsEmailStatus: any[];
  submitissueval: any = 'https://forms.office.com/Pages/ResponsePage.aspx?id=Tv6YKuYNEUG9j7ZhEzTnkqtvdFaoR4REoIl_Hw8dL-JUM1NSUlJPRkFMQlI3MlJXSkJGNUNWT1JZSi4u&embed=true';
  intervalid: Subscription; ExportLeadsFromExternal: any[]; headerpavionlogo: any;
  /*Manager Modal Popup*/
  modalRef: MDBModalRef; modalOptionManagerPopup: any; ManagerPopup: any[]; Rowid: any; Defaultmanager: any; ManagerName: any[]; Manageruserid: any = '';

  ManagerEmail: any[]; ManagerEmails: any[]; EmployeeNameForLeadDetails: any[];
  constructor(private headerService: HeaderService, private appComponentService: AppComponentService, public leadGenerationService: LeadGenerationService, private router: Router,
    private activatedroute: ActivatedRoute, public cdr: ChangeDetectorRef, private eRef: ElementRef, private auth: AuthendicationService/*, private authService: MsalService*/,
    public renderer: Renderer2, public cryptoservice: CryptoService, private graphService: GraphService,
    private executiveLeadsReportService: ExecutiveLeadsReportService,
    private modalService: MDBModalService, private authGuardService: AuthGuardService, private toastrService: ToastService, public datepipe: DatePipe,
    private settingService: SettingService, private emailservice: EmailService) {
    //this.intervalid = interval(900000).subscribe((x => {
    //  this.getLeadsFromExternal();
    //}));

    setInterval(() => {
      this.getLeadsFromExternal();
    }, 600000);
  }
  ngAfterContentChecked(): void {
    this.headerService.cvlisten.subscribe(dt => this.HeaderTitle = dt);
    this.cdr.detectChanges();
  }
  ngOnChanges() {
    debugger
    if (this.switchuserLength > 1) {
      this.switchuserLengthVal = true;
    }
    else {
      this.switchuserLengthVal = false;
    }
    //Online Or Offline Start
    if (this.connectionstatus =='online') {
      this.connectionstatusVal = true;
    }
    else {
      this.connectionstatusVal = false;
    }
    //Online Or Offline End
  }
  ngOnInit() {
    debugger
    let firstDay = new Date(this.date.getFullYear(), this.date.getMonth() - 1, 2);
    let finalFirstDay = firstDay.setUTCHours(0, 0, 0, 0);
    let lastDay = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    let finalLastDay = lastDay.setUTCHours(0, 0, 0, 0);
    console.log(firstDay.toISOString(), lastDay.toISOString())
    this.fromdate = this.datepipe.transform(new Date(finalFirstDay), 'yyyy-MM-dd');
    this.todate = this.datepipe.transform(new Date(finalLastDay), 'yyyy-MM-dd');
    this.headerpavionlogo = 'assets/' + 'Pavion.png';
    this.togglebtn = false;
    var Title = '';
    this.fieldvalselectlevel = 'Select Search Type';
      if (Title =='') {
        this.HeaderTitle = Title;
      }
    // user details toggle
    $(".arrow-p").click(function(){
      $(".user-details").slideToggle();
    
    });
    $(document).mouseup(function (e) {
      var container = $(".user-details");

      // If the target of the click isn't the container
      //if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.hide();
      //}
    });
    this.getAuthorizeUserDetails();
    this.leadGenerationService.bsleadcount.subscribe(x => this.loadnewLeadCount());
    /*this.headerService.bsManager.subscribe(dt => this.getAuthorizeUserDetails());*/
    //this.leadGenerationService.bsapprovalcentercount.subscribe(x => this.getheaderCount());
    this.leadGenerationService.bsClearHeaderSearch.subscribe(x => this.Clearedsearch());
  }
    getAuthorizeUserDetails() {
        this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
            res => { this.onError(res) });
  }
 
  getAuthorizationForUserSuccess(getresult: any[]) {
    debugger
    let Level = ''; let headerlogo = ''; let blackpanellogo = '';
        this.authorizeUserDetails = [];
        if (getresult.length > 0) {
            this.authorizeUserDetails = getresult;
          let Fullname = this.authorizeUserDetails[0]['fullname'];
          let Rowid = this.authorizeUserDetails[0]['rowid'];
          let UserId = this.authorizeUserDetails[0]['userid'];
          Level = this.authorizeUserDetails[0]['level'];
          let Branchid = this.authorizeUserDetails[0]['branchid'];
          let Manager = this.authorizeUserDetails[0]['manager'];
          let Defaultmanager = this.authorizeUserDetails[0]['defaultmanager'];
          if (Level) {
            Level = Level.toLowerCase();
            this.userLevel = Level;
          }
          //var Level = 'Manager,Sales';
          let Emailid = this.authorizeUserDetails[0]['email'];
            if (UserId != "") {
              this.Username = UserId;
            }
            if (Fullname != "") {
              this.FullnameValue = Fullname.substring(0, Fullname.indexOf('('));
            }
            if (Emailid != "") {
                this.EmailidValue = Emailid;
          }
          if (Branchid) {
            this.Branchid = Branchid;
          }
          let company = this.authorizeUserDetails[0].company;
          if (company) {
            this.company = company.toLowerCase();
            headerlogo = this.authorizeUserDetails[0].headerlogo;
            blackpanellogo = this.authorizeUserDetails[0].blackpanellogo;
          }
          if (headerlogo) {
            this.headerlogo = 'assets/' + headerlogo;
          }
          if (blackpanellogo) {
            this.blackpanellogo = 'assets/' + blackpanellogo;
          }
          //if (this.Username.toString() == 'manager1' || this.Username.toString() == 'manager2' || this.Username.toString() == 'sales1'
          //  || this.Username.toString() == 'sales2' || this.Username.toString() == 'officer12') {
          //  this.liapprovalcenter = true;
          //}
          if (Manager) {
            this.Manager = Manager;
          }
          else {
            this.toastrService.warning('Add your manager!', '', this.options);
          }
          if (Defaultmanager) {
            this.Defaultmanager = Defaultmanager;
          }
          if (Rowid) {
            this.Rowid = Rowid;
          }
          if (UserId != "" || Level != "") {
            this.Username = UserId;
            this.useridLevel = Level;
          }
          if (this.userLevel == 'admin' || this.userLevel == 'executive') {
            this.reportsectionactive = true;
          } else {
            this.reportsectionactive = false;
          }
      }
        //this.loadnewLeadCount();
      //const source = interval(10000);
      //this.subscription = source.subscribe(() => this.loadnewContactCount());
      //this.passedEstimatesubscription = source.subscribe(() => this.getacceptEstimate());
    this.updateUserLoginDate();
    this.loadExecutiveEmails();
    this.loadManagerEmails();
    this.loadBranchname();
    this.loadManagerDetails();
  }
  loadExecutiveEmails() {
    let strusername = this.Username;
    this.executiveLeadsReportService.getExecutiveEmail(strusername).subscribe(getresult => { this.getExecutiveEmailSuccess(getresult) },
      res => { this.onError(res) });
  }
  getExecutiveEmailSuccess(getresult: any[]) {
    this.ExecutiveEmail = [];
    this.Emails = [];
    if (getresult.length > 0) {
      this.ExecutiveEmail = getresult;
      for (var i = 0; i < this.ExecutiveEmail.length; i++) {
        this.Emails.push(this.ExecutiveEmail[i].email)
      }
    }
  }
  loadManagerEmails() {
    let fromdate = this.fromdate;
    let todate = this.todate;
    this.executiveLeadsReportService.getManagerEmail(fromdate, todate).subscribe(getresult => { this.getManagerEmailSuccess(getresult) },
      res => { this.onError(res) });
  }
  getManagerEmailSuccess(getresult: any[]) {
    this.ManagerEmail = [];
    this.ManagerEmails = [];
    if (getresult.length > 0) {
      this.ManagerEmail = getresult;
      for (var i = 0; i < this.ManagerEmail.length; i++) {
        this.ManagerEmails.push(this.ManagerEmail[i].email)
      }
      this.loadEmployeeNameForLead();
    }
  }
  loadEmployeeNameForLead() {
    let fromdate = this.fromdate;
    let todate = this.todate;
    this.executiveLeadsReportService.getEmployeeNameForLead(fromdate, todate).subscribe(getresult => { this.getEmployeeNameForLeadSuccess(getresult) },
      res => { this.onError(res) });
  }
  getEmployeeNameForLeadSuccess(getresult: any[]) {
    this.EmployeeNameForLeadDetails = [];
    if (getresult.length > 0) {
      this.EmployeeNameForLeadDetails = getresult;
    }
  }
  loadManagerDetails() {
    this.settingService.getManagerDetails().subscribe(getresult => { this.getManagerDetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getManagerDetailsSuccess(getresult: any[]) {
    this.ManagerName = [];
    if (getresult.length > 0) {
      this.ManagerName = getresult;
      this.ManagerName.forEach(x => {
        if (x.email == this.Defaultmanager) {
          this.Manageruserid = x.userid;
        }
      });
      if (this.Manageruserid == this.Manager && this.useridLevel == 'technician') {
        this.toastrService.warning('Confirm your manager!', '', this.options);
      }
    }
  }
  loadBranchname() {
    let strusername = this.Username;
    this.leadGenerationService.loadBranchdetails(strusername).subscribe(getresult => { this.loadBranchdetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  loadBranchdetailsSuccess(getresult: any[]) {
    this.BranchnameDetails = [];
    if (getresult.length > 0) {
      this.BranchnameDetails = getresult;
      let branchid = this.Branchid;
      if (this.BranchnameDetails.length > 0) {
        for (let i = 0; i < this.BranchnameDetails.length; i++) {
          let branchcode = this.BranchnameDetails[i].branchcode;
          if (branchcode == branchid) {
            this.Branchname = this.BranchnameDetails[i].branchname;
          }
        }
      }
      let BranchName = this.Branchname;
      let UserId = this.Username;
      if (BranchName != "" && UserId != '') {
        this.userAndbranch = UserId + ' ' + '(' + BranchName + ')';
      }
    }

    /* Email Sent to Executive */
    let strusername = this.Username;
    let list = [];
    let Item = {
      "userid": strusername,
      "fromdate": this.fromdate ? this.fromdate.toString() : '',
      "todate": this.todate ? this.todate.toString() : ''
    };
    list.push(Item);
    this.executiveLeadsReportService.getExecutiveleads(list).subscribe(getresult => { this.getExportleadsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getExportleadsSuccess(getresult) {
    debugger
    if (getresult.length > 0) {
      this.dateval = this.date.getDate();
      this.datenameval = this.weekday[this.date.getDay()];
      let type = 'leads';
      this.executiveLeadsReportService.getExportEmailStatus(type).subscribe((result) => {
        if (result.length > 0) {
          let currentmonth = (new Date().getMonth() + 1).toString();
          let emailmonth = result[0].emailmonth;
          let emailstatus = result[0].emailstatus.toLowerCase();
          let manageremailstatus = result[0].manageremailstatus.toLowerCase();
          if (emailmonth != currentmonth && emailstatus == 'sent' && manageremailstatus == 'sent') {
            this.executiveLeadsReportService.deleteExportEmailStatus(type).subscribe((emailstatusresult) => {
              this.ExportLeadsEmailStatus = emailstatusresult;
            },
              res => { this.onError(res) });
            /*this.emailLeadstoExecutive();*/
          }
        }
        else {
          /*this.emailLeadstoExecutive();*/
        }
      },
        res => { this.onError(res) });
    }
  }
  loadnewLeadCount() {
    let strUsername = this.Username;
    let flag = '1';
    this.headerService.GetLeadsCount(strUsername, flag).subscribe(getresult => { this.GetLeadsCountSuccess(getresult) },
      res => { this.onError(res) });
  }
  GetLeadsCountSuccess(getresult) {
    this.leadCount = [];
    if (getresult.length > 0) {
      this.leadCount = getresult;
      var Newleadcount = this.leadCount[0].leads;
      this.Newleadcount = Newleadcount;
      if (Newleadcount == 0) {
        this.notification = false;
      }
      else {
        this.notification = true;
      }
    }
    else {
      this.notification = false;
    }

  }

  /* Email Sent to Executive */
  emailLeadstoExecutive() {
    this.downloadExcel = true;
    window.open(AppSettings.OAuthSettings.URIPrint + 'RXhwb3J0TGVhZHM=' + window.btoa('executiveleads')
      + '&FromDate=' + window.btoa(this.fromdate) + '&ToDate=' + window.btoa(this.todate)
      + '&UserName=' + window.btoa(this.Username), '_blank', 'noopener noreferrer');

    setTimeout(() => {
      if (this.downloadExcel == true) {
        this.sentEmailLeadsExcel();
      }
    }, 7000);
  }
  sentEmailLeadsExcel() {
    const formData = new FormData();
    let filePathval = `${'ExecutiveLeads'} ${this.fromdate} through ${this.todate} (${this.Username}).${'xlsx'}`;
    /*let filePathval = this.Username + '(' + this.datepipe.transform(new Date(), 'MM-dd-yyyy') + ')' + '-' + 'ExportLeads.xls';*/
    this.executiveLeadsReportService.downloadDoc(filePathval).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            //this.downloadStatus.emit({ status: ProgressStatusEnum.COMPLETE });
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            formData.append('file', downloadedFile, filePathval);
            var object = {};
            formData.forEach((value, key) => object[key] = value);
            var json = JSON.stringify(object);
            this.handleFileInput(object);
        }

      });
  }
  handleFileInput(evt: any) {
    //this.fileToUpload = files.item(0);
    //console.log(this.fileToUpload);
    // const file = evt.target.files[0];
    const file = evt.file;
    this.fileToUpload = file;
    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    console.log(this.base64textString);

    this.LoadLeadsIndividualStatusCount();
  }
  LoadLeadsIndividualStatusCount() {
    let strusername = this.Username;
    let list = [];
    let Item = {
      "userid": strusername,
      "fromdate": this.fromdate ? this.fromdate.toString() : '',
      "todate": this.todate ? this.todate.toString() : ''
    };
    list.push(Item);
    this.executiveLeadsReportService.getLeadsIndividualStatusCount(list).subscribe(getresult => { this.getLeadsIndividualStatusCountSuccess(getresult) },
      res => { this.onError(res) });
  }
  getLeadsIndividualStatusCountSuccess(getresult: any[]) {
    this.LeadsIndividualStatusCount = []; this.EmailBodyArray = []; let leadstatus = ''; let ststuscount = ''; let company = ''; let Emailbody = ''; let previouscompany = '';
    let statuscompany = '';
    if (getresult.length > 0) {
      this.LeadsIndividualStatusCount = getresult;
      for (var i = 0; i < this.LeadsIndividualStatusCount.length; i++) {
        company = this.LeadsIndividualStatusCount[i].company;
        if (i == 0) {
          Emailbody = '<p> Company:' + ' ' + '<b>' + company + '</b></p><br/>';
          Emailbody = Emailbody + 'Lead Status:' + '<br/> ';
          for (var j = 0; j < this.LeadsIndividualStatusCount.length; j++) {
            statuscompany = this.LeadsIndividualStatusCount[j].company;
            if (company == statuscompany) {
              leadstatus = this.LeadsIndividualStatusCount[j].leadstatus;
              ststuscount = this.LeadsIndividualStatusCount[j].ststuscount;
              Emailbody = Emailbody + '<b>' + leadstatus + '</b>' + ' ' + '-' + ' ' + '<b>' + ststuscount + '</b>';
            }
          }
          this.EmailBodyArray.push(Emailbody);
          //if (previouscompany == company) {
          //  Emailbody = Emailbody + 'Lead Status:' + '<br/> ';
          //  Emailbody = Emailbody + '<b>' + leadstatus + '</b>' + ' ' + '-' + ' ' + '<b>' + ststuscount + '</b>';
          //}

          previouscompany = company;
        }
        else if (i != 0 && previouscompany != '') {
          if (company != previouscompany) {
            Emailbody = '<p> Company:' + ' ' + '<b>' + company + '</b></p><br/>';
            Emailbody = Emailbody + 'Lead Status:' + '<br/> ';
            for (var j = 0; j < this.LeadsIndividualStatusCount.length; j++) {
              statuscompany = this.LeadsIndividualStatusCount[j].company;
              if (company == statuscompany) {
                leadstatus = this.LeadsIndividualStatusCount[j].leadstatus;
                ststuscount = this.LeadsIndividualStatusCount[j].ststuscount;
                Emailbody = Emailbody + '<b>' + leadstatus + '</b>' + ' ' + '-' + ' ' + '<b>' + ststuscount + '</b>';
              }
            }
            this.EmailBodyArray.push(Emailbody);
            //if (previouscompany == company) {
            //  Emailbody = Emailbody + 'Lead Status:' + '<br/> ';
            //  Emailbody = Emailbody + '<b>' + leadstatus + '</b>' + ' ' + '-' + ' ' + '<b>' + ststuscount + '</b>';
            //}
          }
          previouscompany = company;
        }

      }
      if (this.EmailBodyArray.length > 0) {
        this.EmailBody = this.EmailBodyArray.toString();
      }
    }

    this.loadEmailDetails();
  }
  loadEmailDetails() {
    let body = '';
    let attachment = this.fileToUpload;
    let attachmentbyte = this.base64textString;
    if (attachment && attachmentbyte && this.Emails.length > 0) {
      let subject = 'Pavion Leads added last month';
      if (this.EmailBody != '') {
        body = '<p>Please see attached list of leads added in the Lead Generator from' + ' ' +
          '<b>' + this.fromdate + '</b>' + ' to ' + ' ' + '<b>' + this.todate + '</b>' + '<br/><br/>' +
          this.EmailBody + '</p>';
      }
      let to = this.Emails.toString();
      let toname = to.substring(0, to.indexOf('@'));
      let cc = '';
      let emailgraphEvent = this.model.getEmailGraphEvent(subject, body, to, cc, attachment, attachmentbyte);
      console.log(emailgraphEvent);
      this.OutlookemailList = [];
      //this.graphService.addEventToEmail(emailgraphEvent).then((events) => {
      //  this.OutlookemailList.push(events);
      //  this.emailSent();
      //  return true;
      //});
      const formData = new FormData();
      if (attachment != null) {
        formData.append('cc', cc ? cc : '');
        formData.append('attachments', attachment ? attachment : null, attachment.name ? attachment.name : null);
        formData.append('subject', subject);
        formData.append('body', body);
        formData.append('to', to);
        formData.append('toname', toname);
      }
      else {
        formData.append('cc', cc ? cc : '');
        formData.append('subject', subject);
        formData.append('body', body);
        formData.append('to', to);
        formData.append('toname', toname);
      }
      this.emailservice.sendEmailAttachment(formData).subscribe(getresult => { this.sendEmailSuccess(getresult,'executive') },
        res => { this.onError(res) });
      this.fromdate = ''; this.todate = ''; this.LeadsIndividualStatusCount = []; this.EmailBodyArray = [];
    }
    else {
      this.toastrService.warning('No user has been assigned the executive level so monthly leads could not be sent. Please contact administrator.', '', this.options);
    }
  }
  sendEmailSuccess(getresult, sectionflag) {
    let flag = '';
    if (getresult != '') {
      let emailmsg = getresult;
      if (emailmsg == '1') {
        if (sectionflag == 'executive') {
          flag = 'executiveemail';
          this.toastrService.success('Email sent to executive', '', this.options);
          if (this.EmployeeNameForLeadDetails.length > 0) {
            this.sendEmailToManager();
          }
        }
        else {
          flag = 'manageremail';
          this.toastrService.success('Email sent to manager', '', this.options);
        }
        let date = this.datepipe.transform(this.date, 'MM-dd-yyyy');
        let type = 'leads';
        this.executiveLeadsReportService.insertExportEmailStatus(date, flag, type).subscribe(getresult => { this.insertExportEmailStatusSuccess(getresult) },
          res => { this.onError(res) });
      }
      else {
        this.toastrService.error('Email could not be sent due to network error!', '', this.options);
      }

    }
      
    this.downloadExcel == false;
  }

  sendEmailToManager() {
    debugger
    let body = '';
    let createHtmlTemplate = new NewEvent();
    let SearchedFieldVal = new SearchedField();
    let EmailBody = createHtmlTemplate.createHtmlTemplate(this.EmployeeNameForLeadDetails);
    let subject = 'Pavion Leads added last month';
    if (EmailBody != '') {
      body = '<p>Please see the list of leads added last month in the Lead Generator from' + ' ' +
        '<b>' + this.fromdate + '</b>' + ' to ' + ' ' + '<b>' + this.todate + '</b>' + '<br/><br/></p><br/>' +
        EmailBody;
    }
    let to = this.ManagerEmails.toString();
    let toname = to.substring(0, to.indexOf('@'));
    let cc = '';
    const formData = new FormData();
    formData.append('cc', cc);
    formData.append('subject', subject);
    formData.append('body', body);
    formData.append('to', to);
    formData.append('toname', toname);

    this.emailservice.sendEmailAttachment(formData).subscribe(getresult => { this.sendEmailSuccess(getresult, 'manager') },
      res => { this.onError(res) });
  }

  //emailSent() {
  //  if (this.OutlookemailList != undefined) {
  //    if (this.OutlookemailList.length > 0) {
  //      for (var i = 0; i < this.OutlookemailList.length; i++) {
  //        let subject = this.OutlookemailList[i].subject;
  //        //var from = this.OutlookemailList[i].from.emailAddress.address;
  //        console.log(subject);
  //        if (subject != '') {
  //          var id = this.OutlookemailList[i].id;
  //          var parentFolderId = this.OutlookemailList[i].parentFolderId;
  //          var outlookemailid = id;
  //          this.graphService.SendEmail(outlookemailid);
  //          console.log(id);
  //        }
  //      }
  //    }
  //  }
  //  let date = this.datepipe.transform(this.date, 'MM-dd-yyyy');
  //  this.exportLeadService.insertExportLeadsEmailStatus(date).subscribe(getresult => { this.insertExportLeadsEmailStatusSuccess(getresult) },
  //    res => { this.onError(res) });
  //  this.toastrService.success('Email sent to executive', '', this.options);
  //  this.downloadExcel == false;
  //}
  insertExportEmailStatusSuccess(getresult: any[]) {
    this.ExportLeadsEmailStatus = [];
    if (getresult.length > 0) {
      this.ExportLeadsEmailStatus = getresult;
    }
  }
  getLeadsFromExternal() {
    let strusername = this.Username;
    this.headerService.getExportLeadsFromExternal(strusername).subscribe(getresult => { this.getExportLeadsFromExternalSuccess(getresult) },
      res => { this.onError(res) });
  }
  getExportLeadsFromExternalSuccess(getresult: any[]) {
    this.ExportLeadsFromExternal = [];
    if (getresult.length > 0) {
      this.ExportLeadsFromExternal = getresult;
      let exportleadsmsg = this.ExportLeadsFromExternal[0].exportleadsmsg;
      if (exportleadsmsg == 'leadsexported') {
        this.loadnewLeadCount();
        this.leadGenerationService.sendLeadsFromExternal();
      }
    }
  }
  reloadDashboard() {
      this.searchAllActive = false;
      /*this.sidenav.hide();*/
      this.headerService.sendEmployeecode('');
      
    if (this.userLevel != "") {
      if (this.userLevel == 'manager') {
        this.authGuardService.getUserLevel(this.authorizeUserDetails);
      }
      else {
        this.router.navigateByUrl('/').then(() =>
          this.router.navigate(['createlead'], { skipLocationChange: true }));
      }
    }
    setInterval(() => {
      /*location.reload();*/
      window.location.href = window.location.href;
      location.reload(true);
    }, 1000);
  }
  Redirecttodashboard(employeecode) {
    this.searchAllActive = false;
    this.componentValue = "dashboard";
    /*this.sidenav.hide();*/
        this.router.navigateByUrl('/').then(() =>
          this.router.navigate(['dashboard/leadgeneration'], { skipLocationChange: true }));
        this.headerService.sendEmployeecode(employeecode);
  }
  openSubmitIssue() {
    this.searchAllActive = true;
    window.open(this.submitissueval, '_blank', 'noopener noreferrer');
  }
  openExportExcelUser() {
    this.searchAllActive = true;
    this.componentValue = "exportexceluser";
    this.headerService.listener('Import Excel User');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['exportexceluser'], { skipLocationChange: true }));
  }
  openSubmitIssueoptional() {
    this.searchAllActive = true;
    this.componentValue = "submitissue";
    this.headerService.listener('Submit Issue');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['submitissue'], { skipLocationChange: true }));
  }
  openUserManual() {
    this.searchAllActive = true;
    this.componentValue = "usermanualpdf";
    this.headerService.listener('User Manual');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['usermanualpdf'], { skipLocationChange: true }));
  }
  openCreatelead() {
    this.componentValue = "createlead";
    /*this.sidenav.hide();*/
    this.router.navigateByUrl('/dashboard').then(() =>
      this.router.navigate(['createlead'], { skipLocationChange: true }));
  }
  openSettings() {
    this.searchAllActive = true;
    this.componentValue = "setting";
    this.headerService.listener('Settings');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['setting'], { skipLocationChange: true }));
  }
  openExecutiveLeadsReport() {
    this.searchAllActive = true;
    this.componentValue = "executiveleadsreport";
    this.headerService.listener('Executive Leads Report');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['executiveleadsreport'], { skipLocationChange: false }));
  }
  openUserActivity() {
    this.searchAllActive = true;
    this.componentValue = "useractivity";
    this.headerService.listener('User Activity');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['useractivity'], { skipLocationChange: true }));
  }
  signOut() {
   // this.authService.logout();
    this.clearCookies(); // Clear cookies to ensure no session remains
  }
  clearCookies(): void {
    debugger
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
    location.reload();
    this.appComponentService.getLogOutUser().subscribe({
      next: () => {
        // Redirect to a page that requires Windows Authentication (or reload the page)
        localStorage.clear(); // Clear local storage
        sessionStorage.clear(); // Clear session storage
        window.location.href = '/createlead'; // Trigger the Windows Auth prompt
        window.location.reload(true); // Reload the page without cache
      },
      error: (err) => { this.onError(err) }
    });
  }
  async switchuser() {
  // await this.authService.loginPopup();
    this.reloadDashboard();
  }


  ngOnDestroy() {
    this.intervalid.unsubscribe();
  }

  searchchange(text: any) {
    if (text) {
      let searchtext = text;

      //let e_customerid = this.e_customerid;
      //let s_customerid = this.s_customerid;

      let strusername = this.Username;
      this.headerService.getSearchAll(strusername, searchtext).subscribe(getresult => { this.getSearchAllSuccess(getresult) },
          res => { this.onError(res) });
      }
    }
  getSearchAllSuccess(getresult: any[]) {
    this.loadGifsearch = false;
    this.searchData = [];
    if (getresult.length > 0) {
      this.searchData = getresult
    }
  }
  searchSelected(evt) {
    if (evt) {
     
    }
  }
  Focusedsearch(e: any) {}
  Clearedsearch() {
    this.searchData = [];
    this.loadGifsearch = false;
    this.searchAll = '';
    this.historyHeadingsearch = '';
  }

  ModalOptionManagerPopupLoad() {
    this.modalOptionManagerPopup = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      containerClass: '',
      animated: true,
      data: {
        managerPopupmodaldata: {
          rowid: this.Rowid
        }
      }
    }
  }
  openManagerPopupModal() {
    debugger
    this.ModalOptionManagerPopupLoad();
    this.ManagerPopupModal();
  }
  ManagerPopupModal() {
    this.modalRef = this.modalService.show(ManagerPopupComponent, this.modalOptionManagerPopup);
    this.modalRef.content.action.subscribe((result: any) => {
      debugger
      this.ManagerPopup = [];
      this.ManagerPopup = result;
      if (result == 'result') {
        this.loadUserdetails();
      }
    });
  }
  loadUserdetails() {
    debugger
    let switchuser = this.Username;
    let ProfileEmailid = null;
    this.appComponentService.getSwitchForUser(switchuser, ProfileEmailid).subscribe(getresult => { this.getSwitchForUserSuccess(getresult); }, res => { this.onError(res); });
  }
  getSwitchForUserSuccess(getresult) {
    this.loadAuthorizeUserDetails = [];
    if (getresult.length > 0) {
      this.loadAuthorizeUserDetails = getresult;
      let Manager = this.loadAuthorizeUserDetails[0]['manager'];
      if (Manager) {
        this.Manager = Manager;
      }
    }
  }
  updateUserLoginDate() {
    let strusername = this.Username;
    this.headerService.getUpdateUserLoginDate(strusername).subscribe(getresult => { this.getUpdateUserLoginDateSuccess(getresult)},
      res => { this.onError(res) });
  }
  getUpdateUserLoginDateSuccess(getresult){
    if (getresult.length > 0) {

    }
  }
 
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      event.preventDefault();
    }
  }
    onError(res: any) {
        console.log(res);
  }
  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    const targetElement = event.target as HTMLElement;

    // Check if the clicked element or its parent has the specific ID (dropdownMenuButton)
    if (targetElement.id === 'svgdropdownMenuButton' ||
      targetElement.parentElement.id === 'dropdownMenuButton' ||
      targetElement.parentElement.parentElement.id === 'dropdownMenuButton' ||
      targetElement.parentElement.id === 'svgdropdownMenuButton' ||
      targetElement.closest('#dropdownMenuButton') ||
      targetElement.closest('#svgdropdownMenuButton') ||
      targetElement.parentElement.closest('#dropdownMenuButton') ||
      targetElement.parentElement.parentElement.closest('#dropdownMenuButton')) {
      return; // If clicked on the button, do nothing (i.e., don't close the dropdown)
    }

    // Check if the click is outside the dropdown content
    const clickedInside = this.eRef.nativeElement.contains(targetElement);
    if (clickedInside) {
      this.showpop = false; // Close dropdown if clicked outside
    }
  }
  headerOptions(): void {
    this.showpop = !this.showpop;
  }
}
