import { Component, ViewChild, ElementRef, ChangeDetectorRef, OnInit, ViewContainerRef, TemplateRef, Input, AfterViewInit, HostListener, OnDestroy, Renderer2, HostBinding} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponentService } from 'src/app/services/appcomponenet.service';
import { HeaderService } from 'src/app/services/header.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Subject, Subscription} from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { NewEvent } from '../../Interface/new-event';
/*import { GraphService } from '../../services/graph.service';*/
import { AppSettings } from '../../Appsettings';
import { MDBModalRef, MDBModalService, MdbTableDirective, MdbTablePaginationComponent, ToastService } from 'ng-uikit-pro-standard';
import { PageIndexService } from '../../services/pageindex.service';
import { CdkDragDrop, moveItemInArray, CdkDragStart, CdkDragRelease } from '@angular/cdk/drag-drop';
import * as moment from 'moment';
import { LeadGenerationService } from '../../services/leadgeneration.service';
import { SettingService } from '../../services/settings.service';
import { LeademailPopupComponent } from '../leademailpopup/leademailpopup.component';
import { CryptoService } from '../../services/crypto.service';
import { NgxSpinnerService } from "ngx-spinner";
import { EmailService } from '../../services/email.service';
import { NgSelectComponent } from '@ng-select/ng-select';
declare var $;

@Component({
  selector: 'app-createlead',
  templateUrl: './createlead.component.html',
  styleUrls: ['./createlead.component.scss']
})
export class CreateLeadComponent implements OnInit, AfterViewInit, OnDestroy{
  @HostBinding('class.app-createlead') someField: boolean = false;
  submitted = false; contactHeader: boolean; show: boolean; saveViewSavebtn: boolean; PreviewData: any;
  imageUrl: any = "assets/user.png"; useridLevel: any; userEmail: any;
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  CreateLeadForm: FormGroup; masterSelected: boolean; allLeadlist: any[];
  Username: any; getLeadFlag: any; allLeadlistData: any[]; responseData: any[];
  authorizeUserDetails: any[]; zoomWidth: number; pagenumber: number; theight: any; OutlookemailList: any[];
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };
  phonenumberfieldtext: any; createLeadList: any[]; submitLeadlist: any[];
  fullEmployeename: any; officeOption: any; leadStatusOption: any; existingacountOption: any; lineofbusinessOption: any; leadcategoryOption: any;
  BranchnameDetails: any[]; BranchCode: any[]; Branch: any; isDisabled: boolean = true; firstName: any; lastName: any;
  Leadstatusarray: any[]; Leadcategoryarray: any[]; Lineofbusinessarray: any[];
  ComponetgettingFlag: any; leadId: any; leadFlag: any; LeadDetails: any[]; saveupdatebtn: any; ManagerDetails: any[];
  model = new NewEvent(); emailBody: any; statusWonEmailValue: any; statusWonLeadStatusValue: any;
  @ViewChild('emailfocus', { static: false }) emailfocus: ElementRef;
  @ViewChild('ngSelectComponent', { static: false }) ngSelectComponent: NgSelectComponent;
  modalRef: MDBModalRef; emailpopupmodalOptions: any;
  Emailformng: any; Phoneformng: any; Customernameformng: any; Companyformng: any; Cityformng: any; Stateformng: any; Zipformang: any; Officeformng: any; Siteaddressformng: any;
  TechnicianEmail: any[]; fullname: any; updatebtnClickFlag: any;
  constructor(private cd: ChangeDetectorRef, public formBuilder: FormBuilder, private router: Router, private activatedroute: ActivatedRoute,
    private appComponentService: AppComponentService, private headerService: HeaderService, private leadGenerationservice: LeadGenerationService,
    private dashBoardservice: DashboardService/*, private graphService: GraphService*/, private settingService: SettingService, private cryptoservice: CryptoService,
    private pagerService: PageIndexService, public renderer2: Renderer2, private toastrService: ToastService, private modalService: MDBModalService,
    private spinner: NgxSpinnerService, private emailservice: EmailService) {
    this.masterSelected = false;
    this.allLeadlistData = [];
    this.responseData = [];
  }

  ngOnInit() {
    debugger
    this.headerService.sendEmployeecode('');
    this.officeOption = 'Select Office *';
    this.leadStatusOption = 'Select Lead Status';
    this.existingacountOption = 'Select Existing Acount *';
    this.lineofbusinessOption = 'Select Line Of Business';
    this.leadcategoryOption = 'Select Lead Category';

    //this.activatedroute.params.subscribe(params => this.ComponetgettingFlag = params.leadlevel);
    //this.activatedroute.params.subscribe(params => this.leadId = params.leadId);
    //this.activatedroute.params.subscribe(params => this.leadFlag = params.leadFlag);

    this.activatedroute.queryParams.subscribe(params => this.ComponetgettingFlag = this.cryptoservice.Decrypt(params.leadlevel));
    this.activatedroute.queryParams.subscribe(params => this.leadId = this.cryptoservice.Decrypt(params.leadId));
    this.activatedroute.queryParams.subscribe(params => this.leadFlag = this.cryptoservice.Decrypt(params.leadFlag));
   

    if (this.leadId != undefined && this.leadFlag != undefined) {
      if (this.leadFlag == '1') {
        this.saveupdatebtn = 'Update Lead';
        this.headerService.listener('Update Lead');
      }
      else {
        this.saveupdatebtn = 'Submit Lead';
        this.headerService.listener('Create Lead');
      }
    }
    else {
      this.saveupdatebtn = 'Submit Lead';
      this.headerService.listener('Create Lead');
    }
    this.CreateLeadFormData();
    this.getAuthorizeUserDetails();
    this.contactHeader = true;

    let browserZoomLevel = Math.ceil(((window.outerWidth - 10) / window.innerWidth) * 100);
    if (window.innerHeight >= 1000) {
      this.zoomTheLrgscreen(browserZoomLevel);
    }
    else {
      this.zoomThescreen(browserZoomLevel);
    }

  }
  getAuthorizeUserDetails() {
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    let Level = ''; let userEmail = '';
    this.authorizeUserDetails = [];
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      let UserId = this.authorizeUserDetails[0]['userid'];
      Level = this.authorizeUserDetails[0]['level'];
      this.firstName = this.authorizeUserDetails[0]['firstname'];
      this.lastName = this.authorizeUserDetails[0]['lastname'];
      this.fullname = this.authorizeUserDetails[0]['fullname'];
      if (Level) {
        Level = Level.toLowerCase();
      }
      userEmail = this.authorizeUserDetails[0]['email'];
      if (userEmail) {
        userEmail = userEmail.toLowerCase();
      }

      if (UserId != "") {
        this.Username = UserId;
        this.useridLevel = Level;
      }
      if (userEmail != "") {
        this.userEmail = userEmail;
      }

      if (this.leadId == undefined && this.leadFlag == undefined) {
          this.loadBranchname();
      }
      /*this.loadBranchname();*/
      this.loadManager();
      this.loadLeadcategory();
      this.loadLeadstatus();
      this.loadLineofbusiness();
      if (this.leadId == undefined && this.leadFlag == undefined) {
        if (this.leadFlag != '1') {
          this.CreateLeadForm.patchValue({ employeename: this.firstName + ' ' + this.lastName });
        }
      }
    }
  }

  CreateLeadFormData() {
    this.CreateLeadForm = this.formBuilder.group({
      employeename: ['', Validators.required],
      date: [(new Date()).toISOString().substring(0, 10), Validators.required],
      office: [null, Validators.required],
      customercontactname: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', Validators.compose([Validators.pattern(this.emailPattern)])],//Validators.pattern(this.emailPattern)
      phonenumber: ['', Validators.compose([Validators.minLength(13), Validators.maxLength(15)])],
      siteaddress: [''],
      city: [''],
      state: [''],
      zip: ['', Validators.compose([Validators.minLength(5)])],
      leadstatus: ['New'],
      existingacount: ['Y', Validators.required],
      lineofbusiness: [null],
      leadcategory: [null],
      jobtitle: [''],
      note: ['', Validators.required],
      salespersonemail: [''],
      dynamicsleadid: [''],
      prioritylead: ['Y', Validators.required]
    });
    /* this.ContactOwnerOption, Validators.compose([Validators.required, Validators.minLength(13)])*/
  }
  get f() { return this.CreateLeadForm.controls; }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    //this.unsubscribe$.next();
    //this.unsubscribe$.complete();
  }
  loadManager() {
    let strusername = this.Username;
    this.leadGenerationservice.getManagerEmailForLead(strusername).subscribe(getresult => { this.getManagerSuccess(getresult) },
      res => { this.onError(res) });
  }
  getManagerSuccess(getresult: any[]) {
    this.ManagerDetails = [];
    if (getresult.length > 0) {
      this.ManagerDetails = getresult;
    }
  }
  loadleadDetails(leadid) {
    let strusername = this.Username;
    this.leadGenerationservice.getLeadDetails(strusername, leadid).subscribe(getresult => { this.getLeadDetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getLeadDetailsSuccess(getresult: any[]) {
    debugger
    this.LeadDetails = []; let leadstatus = ''; let email = ''; let createdby = '';
    if (getresult.length > 0) {
      this.LeadDetails = getresult;

      createdby = this.LeadDetails[0].createdby;
      email = this.LeadDetails[0].email;
      let phonenumber = this.LeadDetails[0].phonenumber;
      let fullname = this.LeadDetails[0].fullname;
      let office = this.LeadDetails[0].office;
      let customercontactname = this.LeadDetails[0].customercontactname;
      let company = this.LeadDetails[0].company;
      let siteaddress = this.LeadDetails[0].siteaddress;
      let city = this.LeadDetails[0].city;
      let state = this.LeadDetails[0].state;
      let zip = this.LeadDetails[0].zip;
      let date = this.LeadDetails[0].date;
      leadstatus = this.LeadDetails[0].leadstatus;
      let jobtitle = this.LeadDetails[0].jobtitle;
      let existingacount = this.LeadDetails[0].existingacount;
      let lineofbusiness = this.LeadDetails[0].lineofbusiness;
      let leadcategory = this.LeadDetails[0].leadcategory;
      let note = this.LeadDetails[0].note;
      let salespersonemail = this.LeadDetails[0].salespersonemail;
      let dynamicsleadid = this.LeadDetails[0].dynamicsleadid;
      let prioritylead = this.LeadDetails[0].prioritylead;
      this.statusWonEmailValue = email;
      this.statusWonLeadStatusValue = leadstatus;
      this.Phonenumberchange(phonenumber);
      if (this.leadId != undefined && this.leadFlag != undefined) {
        if (this.leadFlag == '1') {
          this.CreateLeadForm.patchValue({ employeename: fullname ? fullname:'' });
        }
      }
      this.CreateLeadForm.patchValue({
        office: office,customercontactname: customercontactname, company: company, email: email,
        siteaddress: siteaddress, city: city, state: state, zip: zip, leadstatus: leadstatus ? leadstatus:null, existingacount: existingacount, lineofbusiness: lineofbusiness ? lineofbusiness:null,
        leadcategory: leadcategory ? leadcategory:null, jobtitle: jobtitle, note: note, salespersonemail: salespersonemail, dynamicsleadid: dynamicsleadid, prioritylead: prioritylead
      });

      let strusername = createdby;
      this.leadGenerationservice.getTechnicianEmail(strusername).subscribe(getresult => { this.getTechnicianEmailSuccess(getresult) },
        res => { this.onError(res) });
    }
  }
  getTechnicianEmailSuccess(getresult: any[]) {
    debugger
    this.TechnicianEmail = []; let leadstatus = ''; let email = '';
    if (getresult.length > 0) {
      this.TechnicianEmail = getresult;
      leadstatus = this.statusWonLeadStatusValue;
      email = this.TechnicianEmail[0].email;
      if (this.leadId != undefined && this.leadFlag != undefined && this.updatebtnClickFlag =='1') {
        if (this.leadFlag == '1') {
          if (leadstatus == 'Won') {
            this.statusWonEmailValue = email;
            this.EmailsentToTechnician();
          }
        }
      }
    }
  }
  loadBranchname() {
    let strusername = this.Username;
    this.leadGenerationservice.loadBranchdetails(strusername).subscribe(getresult => { this.loadBranchdetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  loadBranchdetailsSuccess(getresult: any[]) {
    this.BranchnameDetails = [];
    if (getresult.length > 0) {
      this.BranchnameDetails = getresult;
    }
    this.loadBranchcode();
  }
  loadBranchcode() {
    debugger
    let strusername = this.Username;
    this.leadGenerationservice.loadBranchcode(strusername).subscribe(getresult => { this.loadBranchcodeSuccess(getresult) },
      res => { this.onError(res) });
  }
  loadBranchcodeSuccess(getresult: any[]) {
    debugger
    this.BranchCode = [];
    if (getresult.length > 0) {
      this.BranchCode = getresult
      let branchcode = this.BranchCode[0].branchcode;
      if (this.BranchnameDetails.length > 0) {
        for (let i = 0; i < this.BranchnameDetails.length; i++) {
          let branchdetailscode = this.BranchnameDetails[i].branchcode;
          if (branchdetailscode == branchcode) {
            this.Branch = this.BranchnameDetails[i].branchname;
            this.CreateLeadForm.patchValue({ office: this.Branch });
          }
        }
      }
    }
  }
  loadLeadcategory() {
    let strusername = this.Username;
    this.leadGenerationservice.loadLeadcategory(strusername).subscribe(getresult => { this.loadLeadcategorySuccess(getresult) },
      res => { this.onError(res) });
  }
  loadLeadcategorySuccess(getresult: any[]) {
    this.Leadcategoryarray = [];
    if (getresult.length > 0) {
      this.Leadcategoryarray = getresult;
    }
  }
  loadLineofbusiness() {
    let strusername = this.Username;
    this.leadGenerationservice.loadLineofbusiness(strusername).subscribe(getresult => { this.loadLineofbusinessSuccess(getresult) },
      res => { this.onError(res) });
  }
  loadLineofbusinessSuccess(getresult: any[]) {
    this.Lineofbusinessarray = [];
    if (getresult.length > 0) {
      this.Lineofbusinessarray = getresult;
    }
    if (this.leadId != undefined && this.leadFlag != undefined) {
      if (this.leadFlag == '1') {
        let leadid = this.leadId;
        this.loadleadDetails(leadid);
      }
    }
  }
  loadLeadstatus() {
    let strusername = this.Username;
    this.leadGenerationservice.loadLeadstatus(strusername).subscribe(getresult => { this.loadLeadstatusSuccess(getresult) },
      res => { this.onError(res) });
  }
  loadLeadstatusSuccess(getresult: any[]) {
    this.Leadstatusarray = [];
    if (getresult.length > 0) {
      this.Leadstatusarray = getresult;
    }
  }
  onSubmitUpdateLead() {
    debugger
    this.updatebtnClickFlag = '1';
    this.createLeadList = []; this.submitLeadlist = [];
    let email = this.CreateLeadForm.value.email;
    let phonenumber = this.CreateLeadForm.value.phonenumber;
    if ((email == '' || email == undefined || email == null) && (phonenumber == '' || phonenumber == undefined || phonenumber == null)) {
      this.toastrService.warning('Email or Mobile # required', '', this.options);
      this.emailfocus.nativeElement.focus();
      return;
    }

    this.spinner.show();
    this.submitted = true;
    if (this.CreateLeadForm.invalid) {
      return;
    }


    let strusername = this.Username;
    let flagValue = '2';
      if (this.leadFlag != '1') {
        this.leadGenerationservice.getAllLeads(strusername, flagValue).subscribe(getresult => { this.compareLeadSuccess(getresult) },
          res => { this.onError(res) });
      }
    


    // display form values on success
    this.createLeadList.push(this.CreateLeadForm.value);

    var leadstatusval = this.createLeadList[0]['leadstatus'];
    var existingacountval = this.createLeadList[0]['existingacount'];
    var lineofbusinessval = this.createLeadList[0]['lineofbusiness'];
    var leadcategoryval = this.createLeadList[0]['leadcategory'];


    let leadstatus = ''; let existingacount = ''; let lineofbusiness = ''; let leadcategory = '';
    if (leadstatusval != this.leadStatusOption && leadstatusval != null) {
      leadstatus = leadstatusval;
    }
    else {
      leadstatus = '';
    }

    if (existingacountval != this.existingacountOption && existingacountval != null) {
      existingacount = existingacountval;
    }
    else {
      existingacount = '';
    }
    if (lineofbusinessval != this.lineofbusinessOption && lineofbusinessval != null) {
      lineofbusiness = lineofbusinessval;
    }
    else {
      lineofbusiness = '';
    }
   

    if (leadcategoryval != this.leadcategoryOption && leadcategoryval != null) {
      leadcategory = leadcategoryval;
    }
    else {
      leadcategory = '';
    }

    let list = [];
    this.createLeadList.forEach(x => {
      let Item = {
        "userid": strusername,
        "email": x.email,
        "phonenumber": x.phonenumber == null ? '' : x.phonenumber.replace(/[^0-9]+/g, ''),
        "customercontactname": x.customercontactname,
        "company": x.company,
        "siteaddress": x.siteaddress,
        "city": x.city,
        "state": x.state ? x.state.toUpperCase():'',
        "zip": x.zip,
        "date": x.date,
        "office": x.office,
        "leadstatus": leadstatus,
        "jobtitle": x.jobtitle,
        "existingacount": existingacount,
        "lineofbusiness": lineofbusiness,
        "leadcategory": leadcategory,
        "note": x.note,
        "salespersonemail": x.salespersonemail ? x.salespersonemail : '',
        "prioritylead": x.prioritylead ? x.prioritylead : ''
      }
      list.push(Item);
    });
    this.fullEmployeename = this.CreateLeadForm.value.employeename;
    this.Emailformng = list[0].email;
    this.Phoneformng = list[0].phonenumber;
    this.Customernameformng = list[0].customercontactname;
    this.Companyformng = list[0].company;
    this.Siteaddressformng = list[0].siteaddress;
    this.Cityformng = list[0].city;
    this.Stateformng = list[0].state;
    this.Zipformang = list[0].zip;
    this.Officeformng = list[0].office;

    this.submitLeadlist = list;

    if (this.leadId != undefined && this.leadFlag != undefined) {
      if (this.leadFlag == '1') {

        let leadid = this.leadId;
        this.leadGenerationservice.updateLead(list, leadid).subscribe(getresult => { this.updateLeadSuccess(getresult) },
          res => { this.onError(res) });
      }
    }
  }
  compareLeadSuccess(getresult: any[]) {
    let toEmail = '';
    let list = [];
    list = this.submitLeadlist;
    if (this.ManagerDetails.length > 0) {
      this.ManagerDetails.forEach((x, i) => {
        // toEmail = x.email;
        if (i > 0) {
          toEmail += ',';
        }
        toEmail += x.email;
      })
      if (toEmail) {
        this.leadGenerationservice.createLead(list).subscribe(getresult => { this.createLeadSuccess(getresult) },
          res => { this.onError(res) });
        return true;
      }
      else {
        this.ManagerAssignErrorMsg();
        return false;
      }
    }
  }
  async createLeadSuccess(getresult: any) {
    debugger
    if (getresult.length > 0) {
      let leadmsg = getresult[0].leadmsg;
      if (leadmsg == '') {
          this.onclearContent('1');
        }
        else {
          this.onclearContent('2');
        }
    }
    this.leadGenerationservice.sendLeadcount(getresult.length);

    /*this.EmailsentToManager();*/
  }
  async updateLeadSuccess(getresult: any) {
    this.spinner.hide();
    let toEmail = '';
    if (getresult.length > 0) {
      this.getLeadDetailsSuccess(getresult);
      this.toastrService.success('Lead updated', '', this.options);
    }
  }
  //async EmailsentToManager() {
  //  debugger
  //  let toEmail = ''; let managerUserid = '';
  //  if (this.ManagerDetails.length > 0) {
  //    this.ManagerDetails.forEach((x,i) => {
  //        // toEmail = x.email;
  //        if (i > 0) {
  //          toEmail += ',';
  //        }
  //        toEmail += x.email;

  //        managerUserid = x.userid;
  //    })
  //    console.log(toEmail);
  //    if (toEmail) {
  //      if (managerUserid != this.Username) {
  //        let subject = 'Pavion leads';

  //        let body = '<p>A new lead has been submitted via the Pavion Lead Generator by' + ' ' + '<b>' + this.fullEmployeename + '</b>.<br/><br/>'
  //          + 'Please review the details below.' + '<br/>' +
  //          '<address>' +
  //          'Lead Email: ' + '<b>' + this.Emailformng + '</b>' + '<br/>' +
  //          'Phone: ' + '<b>' + this.Phoneformng + '</b>' + '<br/>' +
  //          'Contact Name: ' + '<b>' + this.Customernameformng + '</b>' + '<br/>' +
  //          'Company: ' + '<b>' + this.Companyformng + '</b>' + '<br/>' +
  //          'Site Address: ' + '<b>' + this.Siteaddressformng + ' ' + this.Cityformng + ' ' + this.Stateformng + ' ' + this.Zipformang + '</b>' + '</address></p>';
  //        this.emailBody = body;
  //        let to = toEmail;
  //        let toname = to.substring(0, to.indexOf('@'));
  //        let cc = '';
  //        let emailgraphEvent = this.model.getEmailidManagerGraphEvent(subject, body, to, cc);
  //        this.OutlookemailList = [];
  //        //await this.graphService.addEventToEmail(emailgraphEvent).then((events) => {
  //        //  this.OutlookemailList.push(events);
  //        //  this.emailSent();
  //        //});
  //        let list = {
  //          "to": toEmail,
  //          "toname": toname,
  //          "subject": subject,
  //          "body": body
  //        }
  //        this.emailservice.sendEmail(list).subscribe(getresult => { this.sendManagerEmailSuccess(getresult) },
  //          res => { this.onError(res) });
  //      }
  //    }
  //    else {
  //      this.ManagerAssignErrorMsg();
  //    }  
  //  }

  //  this.fullEmployeename = ''; this.submitLeadlist = []; this.fullEmployeename = ''; this.Emailformng = ''; this.Phoneformng = ''; this.Customernameformng = '';
  //  this.Companyformng = ''; this.Cityformng = ''; this.Stateformng = ''; this.Zipformang = ''; this.Officeformng = '';
  //}
  ManagerAssignErrorMsg() {
    this.spinner.hide();
   return this.toastrService.error('A manager has not been assigned to this user so no email can be sent.' +
      'Please assign yourself a manager or contact the administrator!', '', this.options);
  }
  //sendManagerEmailSuccess(getresult) {
  //  if (getresult != '') {
  //    let emailmsg = getresult;
  //    if (emailmsg == '1') {
  //      this.toastrService.success('Email sent to Manager', '', this.options);
  //    }
  //    else {
  //      this.toastrService.error('Email could not be sent due to network error!', '', this.options);
  //    }
      
  //  }
  //}
  sendTechnicianEmailSuccess(getresult) {
    if (getresult != '') {
      let emailmsg = getresult;
      if (emailmsg == '1') {
        this.toastrService.success('Email sent', '', this.options);
      }
      else {
        this.toastrService.error('Email could not be sent due to network error!', '', this.options);
      }

    }
  }
  async EmailsentToTechnician() {
    let subject = 'Pavion Lead status has been changed to won in the Pavion Lead Generator';

    let body = '<p>Lead status has been changed to won in Lead Generator by' + ' ' + '<b>' + this.fullEmployeename + '</b>.<br/><br/>'
           + 'Please review the details below.' + '<br/>' +
          '<address>' +
          'Lead Email: ' + '<b>' + this.Emailformng + '</b>' + '<br/>' +
          'Phone: ' + '<b>' + this.Phoneformng + '</b>' + '<br/>' +
          'Contact Name: ' + '<b>' + this.Customernameformng + '</b>' + '<br/>' +
          'Company: ' + '<b>' + this.Companyformng + '</b>' + '<br/>' +
          'Site Address: ' + '<b>' + this.Siteaddressformng + ' ' + this.Cityformng + ' ' + this.Stateformng + ' ' + this.Zipformang + '</b>' + '</address></p>';
        this.emailBody = body;
        let to = this.statusWonEmailValue;
        let toname = to.substring(0, to.indexOf('@'));
        let cc = '';
        let emailgraphEvent = this.model.getEmailidManagerGraphEvent(subject, body, to, cc);
        this.OutlookemailList = [];
        //await this.graphService.addEventToEmail(emailgraphEvent).then((events) => {
        //  this.OutlookemailList.push(events);
        //  this.emailSent();
        //});
    let list = {
      "to": to,
      "toname": toname,
      "subject": subject,
      "body": body
    }
    this.emailservice.sendEmail(list).subscribe(getresult => { this.sendTechnicianEmailSuccess(getresult) },
      res => { this.onError(res) });
    /*this.EmailsentToManager();*/
  }
  Phonenumberchange(fieldTypedtext) {
    debugger
    var prefix = ('' + fieldTypedtext).replace(/\D/g, '');
    // this.phonenumberfieldtext = prefix;
    var strUsername = this.Username;
    var phonenumberlength = fieldTypedtext.length;
    if (phonenumberlength > 15) {
      return false;
    }
    let phone = '';
    var cleanedlength = ('' + prefix).replace(/\D/g, '').length;
    if (cleanedlength == 10) {
      var cleaned = ('' + prefix).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        phone = '(' + match[1] + ') ' + match[2] + '-' + match[3];
        this.CreateLeadForm.patchValue({
          phonenumber: phone
        });
      }
    }
    else {
      var cleaned = ('' + prefix).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        phone = match[1] + '(' + match[2] + ') ' + match[3] + '-' + match[4];
        this.CreateLeadForm.patchValue({
          phonenumber: phone
        });
      }
    }
  }
  onReset() {
    debugger
    /*this.ngSelectComponent.clearModel();*/
    //this.phonenumbersmltime = '';
    //this.officenumbersmltime = '';
    this.submitted = false;
    this.CreateLeadForm.reset();
    this.CreateLeadForm.patchValue({
      employeename: this.firstName + ' ' + this.lastName,
      date:(new Date()).toISOString().substring(0, 10),
      office: this.Branch,
      leadstatus: 'New',
      existingacount: 'Y',
      lineofbusiness: null,
      leadcategory: null,
      prioritylead: 'Y'
    });
    //this.loadGifCustomer = false;
    //this.loadGifCs = false;
  }

  EmailPopupModalOptionLoad() {
    debugger
    let emailbody = ''; let leadid = '';
    if (this.fullEmployeename != undefined && this.fullEmployeename != '') {
      emailbody = this.emailBody;
    }
    else {
      let employeename =  this.CreateLeadForm.value.employeename;
      let email = this.CreateLeadForm.value.email;
      let phonenumber = this.CreateLeadForm.value.phonenumber;
      let office = this.CreateLeadForm.value.office;
      let customercontactname = this.CreateLeadForm.value.customercontactname;
      let company = this.CreateLeadForm.value.company;
      let siteaddress = this.CreateLeadForm.value.siteaddress;
      let city = this.CreateLeadForm.value.city;
      let state = this.CreateLeadForm.value.state;
      let zip = this.CreateLeadForm.value.zip;
      emailbody = '<p>A new lead has been submitted via the Pavion Lead Generator by' + ' ' + '<b>' + employeename + '</b>.<br/><br/>'
        +'Please review the details below.' + '<br/>' +
       '<address>'+
        'Lead Email: ' + '<b>' + email + '</b>' + '<br/>' +
        'Phone: ' + '<b>' + phonenumber + '</b>' + '<br/>' +
        'Contact Name: ' + '<b>' + customercontactname + '</b>' + '<br/>' +
        'Company: ' + '<b>' + company + '</b>' + '<br/>' +
        'Site Address: ' + '<b>' + siteaddress + ' ' + city + ' ' + state + ' ' + zip + '</b>' +'</address></p>';

      this.emailBody = '';
    }
    if (this.leadId != undefined && this.leadFlag != undefined) {
      if (this.leadFlag == '1') {
         leadid = this.leadId;
      }
    }
    this.emailpopupmodalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-lg modal-dialog-centered',
      containerClass: '',
      animated: true,
      data: {
        leademailpopupmodaldata: {
          emailbody: emailbody, leadid: leadid
        }
      }
    }
  }
  openEmailPopupModal() {
    let email = this.CreateLeadForm.value.email;
    let phonenumber = this.CreateLeadForm.value.phonenumber;
    if ((email == '' || email == undefined || email == null) && (phonenumber == '' || phonenumber == undefined || phonenumber == null)) {
      this.toastrService.warning('Email or Mobile # required', '', this.options);
      this.emailfocus.nativeElement.focus();
      return;
    }
    this.EmailPopupModalOptionLoad();
    this.EmailPopupModal();
  }
  EmailPopupModal() {

    this.modalRef = this.modalService.show(LeademailPopupComponent, this.emailpopupmodalOptions);
    this.modalRef.content.action.subscribe((result: any) => {
    });

  }

  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      evt.preventDefault();
    }

  }
  restrictFieldlength(evt: any) {
    if (evt.target.value.length >= 60) {
      this.toastrService.warning('Customer name/Customer address/Site address cannot exceed 60 characters', '', this.options);
      evt.preventDefault();
    }
  }
  isNumberwithhyphn(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && charCode != 32 && charCode != 45 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }
  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }
  isNumberwithchar(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 90) {
      evt.preventDefault();
    }
  }
  isNumberwithcharhyphn(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && charCode != 32 && charCode != 45 && (charCode < 48 || charCode > 57) && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      evt.preventDefault();
    }
  }
  isNumberPhonenumber(evt: any) {
    debugger
    var phonenumber = this.phonenumberfieldtext;
    if (phonenumber != undefined) {
      var phonenumberLengt = phonenumber.length;
    }
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var cleaned = ('' + phonenumber).replace(/\D/g, '').length;
    if (charCode > 31 && charCode != 45 && charCode != 40 && charCode != 41 && charCode != 32 && (charCode < 48 || charCode > 57) || phonenumberLengt >= 15 || cleaned >= 11) {
      evt.preventDefault();
    }
    this.phonenumberfieldtext = '';
  }
  desiableField() {

  }
  onclearContent(flagval) {
    this.spinner.hide();
    if (flagval == '1') {

      this.toastrService.success('Lead created', '', this.options);
      this.onReset();
     /* this.loadLeadsGrid();*/
    }
    else if (flagval == '2') {

      this.toastrService.warning('Lead already exist', '', this.options);
      /*this.loadLeadsGrid();*/
    }
    else {
     /* this.loadLeadsGrid();*/
    }
    this.headerService.addLeadevent(false);
  }


  ngAfterViewInit() {
    this.cd.detectChanges();
  }
 
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.zoomWidth = Math.ceil(((event.target.window.outerWidth - 10) / event.target.window.innerWidth) * 100);
    this.zoomThescreen(this.zoomWidth);
  }

  zoomThescreen(browserZoomLevel) {
    if (browserZoomLevel >= 100 && browserZoomLevel <= 105) {
      this.pagenumber = 11;
      //this.theight = 403;
    } else if (browserZoomLevel >= 90 && browserZoomLevel <= 95) {
      this.pagenumber = 13;
      //this.theight = 430;
    } else if (browserZoomLevel >= 80 && browserZoomLevel <= 85) {
      this.pagenumber = 16;
      // this.theight = 450;
    } else {
      this.pagenumber = 19;
      //this.theight = 550;
    }
  }
  zoomTheLrgscreen(browserZoomLevel) {
    if (browserZoomLevel >= 100 && browserZoomLevel <= 105) {
      this.pagenumber = 18;
      // this.theight = 403;
    } else if (browserZoomLevel >= 90 && browserZoomLevel <= 95) {
      this.pagenumber = 20;
      //this.theight = 430;
    } else if (browserZoomLevel >= 80 && browserZoomLevel <= 85) {
      this.pagenumber = 23;
      //this.theight = 450;
    } else {
      this.pagenumber = 26;
      //this.theight = 550;
    }
  }
  //emailSent() {
  //  if (this.OutlookemailList != undefined) {
  //    if (this.OutlookemailList.length > 0) {
  //      for (var i = 0; i < this.OutlookemailList.length; i++) {
  //        let subject = this.OutlookemailList[i].subject;
  //        //var from = this.OutlookemailList[i].from.emailAddress.address;
  //        console.log(subject);
  //        if (subject != '') {
  //          var id = this.OutlookemailList[i].id;
  //          var parentFolderId = this.OutlookemailList[i].parentFolderId;
  //          var outlookemailid = id;
  //          this.graphService.SendEmail(outlookemailid);
  //          console.log(id);
  //        }
  //      }
  //    }
  //  }
  //}
  onError(res: any) {
    console.log(res);
    this.spinner.hide();
  }
}
