import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError, interval, BehaviorSubject, Subject, Subscription } from 'rxjs'
import { AppSettings } from '../Appsettings';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {
  formDigest: any = null;
  URI = AppSettings.OAuthSettings.redirectUri;
    bs = new BehaviorSubject('');
  cv;
  bslisten = new BehaviorSubject<any>('');
  cvlisten;
  //bsManager = new BehaviorSubject<any>('');
  //cvManager;
  invokeFirstComponentFunction = new EventEmitter();
  subsVar: Subscription;
  addLeadValue: boolean = false;
  bsaddLead: BehaviorSubject<boolean>;

    constructor(private _http: HttpClient) {
      this.cv = this.bs.asObservable();
      this.cvlisten = this.bslisten.asObservable();
      //this.cvManager = this.bsManager.asObservable();
      this.bsaddLead = new BehaviorSubject(this.addLeadValue);
    }
  subHeaderToggleVar: Subscription;
  //private selectionFormatState = new Subject<any>();

  //setState(state: any) {
  //  this.selectionFormatState.next(state);
  //}

  //getState(): Observable<any> {
  //  return this.selectionFormatState.asObservable();
  //}
  listener(val) {
    this.bslisten.next(val)
  }
  //updateManager(val) {
  //  this.bsManager.next(val)
  //}
    sendEmployeecode(employeecode) {
        this.bs.next(employeecode);
    }
  
  onFirstComponentButtonClick() {
    this.invokeFirstComponentFunction.emit();
  }
  addLeadevent(val) {
    this.addLeadValue = val;
    this.bsaddLead.next(this.addLeadValue)
  }
  GetLeadsCount(strUsername,flag): Observable<any> {
    return this._http.get(this.URI + 'api/HeaderSearch/FetchLeadsCount/' + strUsername + '/' + flag);
  }
  GetOutlookUpdate(strUsername): Observable<any> {
    return this._http.get(this.URI + 'api/OutlookDataSync/FetchSyncDataOutlook/' + strUsername);
  }
  OutlookUpdate(strUsername, flag, deltaid): Observable<any> {
    return this._http.get(this.URI + 'api/OutlookDataSync/InsertSyncDataOutlook/' + strUsername + '/' + flag + '/' + deltaid);
  }
  getSearchAll(strusername, searchtext): Observable<any> {
    return this._http.get(this.URI + 'api/HeaderSearch/FetchSearchAll/' + strusername + '/' + searchtext);
  }
  getExportLeadsFromExternal(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/HeaderSearch/ExportLeadsFromExternal/' + strusername);
  }
  getUpdateUserLoginDate(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/UpdateUserLoginDate/' + strusername);
  }
}
