import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError, interval, BehaviorSubject, Subject } from 'rxjs'
import { AppSettings } from '../Appsettings';

@Injectable({
    providedIn: 'root'
})
export class DefaultLeadAddService {
  formDigest: any = null;
  URI = AppSettings.OAuthSettings.redirectUri;
  bsRoot = new BehaviorSubject<any>('');cvRoot;
    //myEvent = new EventEmitter();
    constructor(private _http: HttpClient) {
      this.cvRoot = this.bsRoot.asObservable();
  }
  callRoot(val) {
    this.bsRoot.next(val)
  }
  getManagerEmailForLead(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/DefaultLead/FetchManagerEmailForLead/' + strusername);
  }
  submitDefaultLead(list): Observable<any> {
    return this._http.post(this.URI + 'api/DefaultLead/InsertDefaultLead', list);
  }
  getAppVersion(): Observable<any> {
    return this._http.get(this.URI + 'api/DefaultLead/FetchAppVersion');
  }
}
