import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})

export class CryptoService {

  secretKey = "YourSecretKeyForEncryption&Descryption";
  decrypted: string;
  constructor() { }

  Encrypt(value) {
    if (value) {
      let encJson = CryptoJS.AES.encrypt(JSON.stringify(value), this.secretKey).toString();
      let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
      return encData.toString();
    }
  }

  Decrypt(textToDecrypt) {
    if (textToDecrypt) {
      let decData = CryptoJS.enc.Base64.parse(textToDecrypt).toString(CryptoJS.enc.Utf8);
      let bytes = CryptoJS.AES.decrypt(decData, this.secretKey).toString(CryptoJS.enc.Utf8);
      return JSON.parse(bytes);
    }
  }
  encryptUsingAES256(strObject: any, key: string) {
    let _key = CryptoJS.enc.Utf8.parse(key);
    let encrypted = CryptoJS.AES.encrypt(
      strObject, _key, {
      keySize: 256 / 8,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();

  }

  decryptUsingAES256(stringObj: any, key: string) {

    let _key = CryptoJS.enc.Utf8.parse(key);


    this.decrypted = CryptoJS.AES.decrypt(
      stringObj, _key, {
      keySize: 256 / 8,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    return JSON.parse(this.decrypted);
  }

}
