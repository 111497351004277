import { Injectable } from '@angular/core';
//import { Client } from '@microsoft/microsoft-graph-client';
//import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

import { AuthendicationService } from './authendication.service';
import { AlertsService } from './alerts.service';
import { AppSettings } from '../Appsettings';

@Injectable({
  providedIn: 'root'
})

export class GraphService {
  
  /*private graphClient: Client;*/
  timezone = AppSettings.OAuthSettings.timeZone;
  constructor(
    private authService: AuthendicationService,
    private alertsService: AlertsService) {

    // Initialize the Graph client
    //this.graphClient = Client.init({
    //  authProvider: async (done) => {
    //    // Get the token from the auth service
    //    let token = await this.authService.getAccessToken()
    //      .catch((reason) => {
    //        done(reason, null);
    //      });

    //    if (token)
    //    {
    //      console.log(token);
    //      done(null, token);
    //    } else {
    //      done("Could not get an access token", null);
    //    }
    //  }
    //});
  }

  //async getCalendarView(start: string, end: string, timeZone: string): Promise<MicrosoftGraph.Event[]> {
  //  try {
  //    // GET /me/calendarview?startDateTime=''&endDateTime=''
  //    // &$select=subject,organizer,start,end
  //    // &$orderby=start/dateTime
  //    // &$top=50
  //    let result =  await this.graphClient
  //      .api('/me/calendarview')
  //      .header('Prefer', `outlook.timezone="${timeZone}"`)
  //      .query({
  //        startDateTime: start,
  //        endDateTime: end
  //      })
  //      .select('subject,organizer,start,end')
  //      .orderby('start/dateTime')
  //      .top(50)
  //      .get();

  //    return result.value;
  //  } catch (error) {
  //    this.alertsService.addError('Could not get events', JSON.stringify(error, null, 2));
  //  }
    
  //}

  //All Get Request
  //async getContactsDetails(): Promise<any> {
  //  try {
  //    let result = await this.graphClient
  //      .api('/me/contacts')
  //      .get();
  //    return result;
  //  }
  //  catch (error) {

  //  }
  //}
  //async getContactsDetailsNextlinklist(nextContactdetails): Promise<any> {
  //  try {
  //    let result = await this.graphClient
  //      .api('/me/contacts?' + nextContactdetails)
  //      .get();
  //    return result;
  //  }
  //  catch (error) {

  //  }
  //}
  //async getReferrByuseridDetails(): Promise<any> {
  //  try {
  //    let result = await this.graphClient
  //      .api('/users')
  //      .get();
  //    return result;
  //  }
  //  catch (error) {

  //  }
  //}
  //async getReferrByuseridNextlinklist(nextReferrByuserid): Promise<any> {
  //  try {
  //    let result = await this.graphClient
  //      .api('/users?' + nextReferrByuserid)
  //      .get();
  //    return result;
  //  }
  //  catch (error) {

  //  }
  //}
  //async getEmailboxfolder(): Promise<any> {
  //  try {
  //    let result = await this.graphClient
  //      .api('/me/mailFolders')
  //      .get();
  //    return result;
  //  }
  //  catch (error) {

  //  }
  //}

  //async getAlloutlookCalendar(startdate, enddate): Promise<any> {
  //  try {
  //    let result = await this.graphClient
  //      .api('/me/calendarview?startdatetime=' + startdate + '&enddatetime=' + enddate)
  //      .get();
  //    return result.value;
  //  }
  //  catch (error) {

  //  }
  //}

  ////All Post Request
  //async addEventToCalendar(newCalenderEvent: MicrosoftGraph.Event): Promise<any> {
  //  try {
  //    console.log(this.graphClient);
  //    console.log(newCalenderEvent);
  //    // POST /me/events
  //    let result = await this.graphClient
  //      .api('/me/events')
  //      .post(newCalenderEvent);
  //    return result;
  //  } catch (error) {
  //    throw Error(JSON.stringify(error, null, 2));
  //  }
  //}
  //async addEventToEmail(emailgraphEvent): Promise<void> {
  //  try {
  //    console.log(this.graphClient);
  //    console.log(emailgraphEvent);
  //    // POST /me/events
  //    let result = await this.graphClient
  //      .api('/me/messages')
  //      .post(emailgraphEvent);
  //    return result;
  //  } catch (error) {
  //    throw Error(JSON.stringify(error, null, 2));
  //  }
  //}
  //async SendEmail(outlookemailid): Promise<void> {
  //  try {
  //    console.log(this.graphClient);
  //    console.log(outlookemailid);
  //    // POST /me/events
  //       await this.graphClient
  //      .api('/me/messages/' + outlookemailid +'/send')
  //         .post({});
  //  } catch (error) {
  //    throw Error(JSON.stringify(error, null, 2));
  //  }
  //}
}
