import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MDBModalRef, MdbTableDirective, ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponentService } from '../../services/appcomponenet.service';
import { SettingService } from '../../services/settings.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-useraddpopup',
  templateUrl: './useraddpopup.component.html',
  styleUrls: ['./useraddpopup.component.scss']
})
export class UserAddPopupComponent implements OnInit {
   userAddPopupmodaldata: any;
  action = new Subject();
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };
  UserAddForm: FormGroup; submitted = false;userAddval: any[];
  levelPlaceholder: string; branchnamePlaceholder: string; useractive: boolean = true;
  UserLeveldetails: any[]; Branchnamedetails: any[];
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  userDetails: any[]; userFullDetails: any[]; firstName: any; lastName: any;
  companyPlaceholder: any; Companydetails: any[]; /*saveupdatebtn: any;*/ /*RowId: any; userFlag: any; userId: any;*/
  branchnameactive: boolean = false; ManagerName: any[]; managerPlaceholder: any; userIdVal: any; userInsertFlag: any; insertUserVal: any;
  constructor(private cdRef: ChangeDetectorRef, private router: Router, private activatedroute: ActivatedRoute,
    private appComponentService: AppComponentService,private datepipe: DatePipe, private _el: ElementRef, public modalRef: MDBModalRef, private toastrService: ToastService,
    public formBuilder: FormBuilder, private settingService: SettingService, private spinner: NgxSpinnerService) { }
  ngOnInit() {
    debugger
    this.spinner.show();
    this.levelPlaceholder = 'Select Level *';
    this.branchnamePlaceholder = 'Select Branch *';
    this.companyPlaceholder = 'Select Company *';
    this.managerPlaceholder = 'Select Manager *';
    this.userIdVal = this.userAddPopupmodaldata.userId;
    this.getUserLevel();
    this.loadManagerDetails();
    this.UserAddFormData();
    this.getCompanyDetails();
  }
  UserAddFormData() {
    this.UserAddForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      userid: [this.userIdVal, Validators.compose([Validators.required, Validators.minLength(5)])],
      branchname: [null, Validators.required],
      level: ['Technician', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
      mobilenumber: ['', Validators.compose([Validators.minLength(13), Validators.maxLength(15)])],
      isactive: [true, Validators.required],
      company: [null, Validators.required],
      manager: [null, Validators.required]
    });
  }
  get f() { return this.UserAddForm.controls; }
  getUserLevel() {
    this.settingService.getLoginUserLevel().subscribe(getresult => { this.getLoginUserLevelSuccess(getresult) },
      res => { this.onError(res) });
  }
  getLoginUserLevelSuccess(getresult: any[]) {
    this.UserLeveldetails = [];
    if (getresult.length > 0) {
      this.UserLeveldetails = getresult;
    }
  }
  loadManagerDetails() {
    this.settingService.getManagerDetails().subscribe(getresult => { this.getManagerDetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getManagerDetailsSuccess(getresult: any[]) {
    this.ManagerName = [];
    if (getresult.length > 0) {
      this.ManagerName = getresult;
    }
  }
  getBranchname(evt, flag) {
    debugger
    let company = evt;
    this.settingService.getCompanyWithBranchDetails(company).subscribe(getresult => { this.getCompanyWithBranchDetailsSuccess(getresult, flag) },
      res => { this.onError(res) });
  }
  getCompanyWithBranchDetailsSuccess(getresult: any[], flag) {
    this.spinner.hide();
    this.Branchnamedetails = [];
    if (getresult.length > 0) {
      this.Branchnamedetails = getresult;
    }

    //if (this.RowId != undefined && this.userFlag != undefined) {
    //  if (this.userFlag == '1' && this.userFullDetails.length > 0 && flag =='1') {
    //    let firstname = this.userFullDetails[0].firstname;
    //    let lastname = this.userFullDetails[0].lastname;
    //    let userid = this.userFullDetails[0].userid;
    //    let branchid = this.userFullDetails[0].branchid;
    //    let level = this.userFullDetails[0].level;
    //    let email = this.userFullDetails[0].email;
    //    let mobilenumber = this.userFullDetails[0].mobilenumber;
    //    let active = this.userFullDetails[0].active;
    //    let company = this.userFullDetails[0].company;

    //    if (this.Branchnamedetails.length > 0) {
    //      this.userFullDetails.forEach((x) => {
    //        this.Branchnamedetails.forEach((y) => {
    //          if (x.branchid == y.branchcode) {
    //            x.branchname = y.branchname;
    //          }
    //        })
    //      });
    //    }
    //    let branchname = this.userFullDetails[0].branchname;
    //    this.Phonenumberchange(mobilenumber);

    //    this.UserAddForm.patchValue({
    //      firstname: firstname, lastname: lastname, userid: userid, branchname: branchname, level: level, email: email, isactive: active, company: company
    //    });
    //  }
    //}
  }
  companyChange(evt) {
    if (evt) {
      this.spinner.show();
      this.branchnameactive = true;
      this.UserAddForm.patchValue({
        branchname: null
      });
      this.getBranchname(evt, '0');
    }
    else {
      this.branchnameactive = false;
    }
  }
  branchNameChange() {
    let branchname = '';
    let branchnameval = this.UserAddForm.value.branchname;
    this.Branchnamedetails.forEach((branch) => {
      if (branch.branchcode == branchnameval) {
        branchname = branch.branchname;
      }
    })
    //if (branchname) {
    //  this.getUserMultipleLevel();
    //}
  }
  getCompanyDetails() {
    this.settingService.getCompanyDetails().subscribe(getresult => { this.getCompanyDetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getCompanyDetailsSuccess(getresult: any[]) {
    debugger
    this.spinner.hide();
    this.Companydetails = [];
    this.UserAddForm.patchValue({
      userid: this.userIdVal
    });
    if (getresult.length > 0) {
      this.Companydetails = getresult;
    }
    //if (this.RowId != undefined && this.userFlag != undefined) {
    //  if (this.userFlag == '1') {
    //    let userid = this.userId;
    //    this.getUser(userid);
    //  }
    //}
  }
  //getUser(userid) {
  //  let strusername = this.Username;
  //  let user = userid;
  //  this.settingService.getUser(strusername, user).subscribe(getresult => { this.getUserSuccess(getresult) },
  //    res => { this.onError(res) });
  //}
  //getUserSuccess(getresult: any[]) {
  //  this.userFullDetails = [];
  //  if (getresult.length > 0) {
  //    this.userFullDetails = getresult;

  //    //let firstname = this.userFullDetails[0].firstname;
  //    //let lastname = this.userFullDetails[0].lastname;
  //    //let userid = this.userFullDetails[0].userid;
  //    //let branchid = this.userFullDetails[0].branchid;
  //    //let level = this.userFullDetails[0].level;
  //    //let email = this.userFullDetails[0].email;
  //    //let mobilenumber = this.userFullDetails[0].mobilenumber;
  //    //let active = this.userFullDetails[0].active;
  //    let company = this.userFullDetails[0].company;

  //    this.getBranchname(company,'1');
  //    //if (this.Branchnamedetails.length > 0) {
  //    //  this.userFullDetails.forEach((x) => {
  //    //    this.Branchnamedetails.forEach((y) => {
  //    //      if (x.branchid == y.branchcode) {
  //    //        x.branchname = y.branchname;
  //    //      }
  //    //    })
  //    //  });
  //    //}
  //    //let branchname = this.userFullDetails[0].branchname;
  //    //this.Phonenumberchange(mobilenumber);

  //    //this.UserAddForm.patchValue({
  //    //  firstname: firstname, lastname: lastname, userid: userid, branchname: branchname, level: level, email: email, active: active, company: company
  //    //});
  //  }
  //}
  insertUser() {
    debugger
    this.userInsertFlag = '1';
    this.spinner.show();
    this.userAddval = []; let branchname = ''; let user = ''; /*let manageruserid = '';*/
    this.submitted = true;
    if (this.UserAddForm.invalid) {
      return;
    }
    console.log(this.UserAddForm.value);
    this.userAddval.push(this.UserAddForm.value);

    let branchnameval = this.UserAddForm.value.branchname;
    this.Branchnamedetails.forEach((branch) => {
      if (branch.branchcode == branchnameval) {
        branchname = branch.branchcode;
      }
    })

    if (branchname == '') {
      this.Branchnamedetails.forEach((branch) => {
        if (branch.branchname == branchnameval) {
          branchname = branch.branchcode;
        }
      })
    }
    //let managerval = this.UserAddForm.value.manager;
    //this.ManagerName.forEach((manager) => {
    //  if (manager.emailusername == managerval) {
    //    manageruserid = manager.userid;
    //  }
    //})

    //if (manageruserid == '') {
    //  this.ManagerName.forEach((manager) => {
    //    if (manager.email == managerval) {
    //      manageruserid = manager.userid;
    //    }
    //  })
    //}
    let userval = this.UserAddForm.value.userid;
    this.insertUserVal = userval;
    //if (this.RowId != undefined && this.userFlag != undefined) {
    //  if (this.userFlag == '1') {
    //    user = this.userId;
    //  }
    //  else {
    //    user = userval;
    //  }
    //}
    //else {
    user = userval;
    /* }*/

    let list = [];
    this.userAddval.forEach(x => {
      let Item = {
        "firstname": x.firstname,
        "lastname": x.lastname,
        "userid": x.userid,
        "branchid": branchname,
        "level": x.level,
        "email": x.email,
        "mobilenumber": x.mobilenumber ? x.mobilenumber.replace(/[^0-9]+/g, '') : '',
        "isactive": x.isactive == true ? '1' : '0',
        "company": x.company,
        "loginuser": x.userid,
        "user": user,
        "manager": x.manager ? x.manager : ''
      };
      list.push(Item);
    });
    //if (this.RowId != undefined && this.userFlag != undefined) {
    //  if (this.userFlag == '1') {
    //    let rowid = this.RowId;
    //    this.settingService.updateUser(list, rowid).subscribe(getresult => { this.updateUserSuccess(getresult) },
    //      res => { this.onError(res) });
    //  }
    //  else {
    //    this.settingService.insertUser(list).subscribe(getresult => { this.insertUserSuccess(getresult) },
    //      res => { this.onError(res) });
    //  }
    //}
    //else {
    this.settingService.insertUser(list).subscribe(getresult => { this.insertUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  insertUserSuccess(getresult: any[]) {
    this.spinner.hide();
    this.userDetails = [];
    if (getresult.length > 0) {
      this.userDetails = getresult;
      let userMsg = this.userDetails[0].usermsg;
      if (userMsg == 'exist') {
        this.toastrService.warning('UserId already exists', '', this.options);
      }
      else {
        this.toastrService.success('User created', '', this.options);
      }
    }
    this.submitted = false;
    if (this.userInsertFlag == '1') {
      this.CloseAddUser();
    }
  }
  //updateUserSuccess(getresult: any[]) {
  //  this.progressBarloading = false;
  //  this.userDetails = [];
  //  if (getresult.length > 0) {
  //    this.userDetails = getresult;
  //    let userMsg = this.userDetails[0].usermsg;
  //    if (userMsg == 'exist') {
  //      this.toastrService.warning('UserId already exists', '', this.options);
  //    }
  //    else {
  //      this.toastrService.success('User updated', '', this.options);
  //      this.getUserSuccess(getresult);
  //    }
  //  }
  //  this.submitted = false;
  //}

  userActive() {
    let userActive = this.UserAddForm.value.isactive;
    if (userActive == true) {
      return true;
    }
    else {
      this.toastrService.warning('User will be deactivated', '', this.options);
    }
  }
  //onUserCancel() {
  //  if (this.RowId != undefined && this.userFlag != undefined) {
  //    if (this.userFlag == '1') {
  //      let userid = this.userId;
  //      this.getUser(userid);
  //    }
  //  }
  //}
  onUserReset() {
    this.spinner.hide();
    this.levelPlaceholder = 'Select Level *';
    this.branchnamePlaceholder = 'Select Branch *';
    this.companyPlaceholder = 'Select Company *';
    this.managerPlaceholder = 'Select Manager *';
    this.UserAddFormData();
    this.submitted = false;
    this.UserAddForm.reset();
    this.UserAddForm.patchValue({
      firstname: '',
      lastname: '',
      userid: this.userIdVal,
      branchname: null,
      level: 'Technician',
      email: '',
      mobilenumber: '',
      isactive: true,
      company: null,
      manager: null
    });
    this.branchnameactive = false;
  }
  CloseAddUser() {
    this.onUserReset();
    this.action.next(this.insertUserVal);
    this.modalRef.hide();
    this.insertUserVal = '';
    this.userInsertFlag = '';
    this.userIdVal = '';
  }
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      event.preventDefault();
    }

  }
  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }
  isNumberwithdot(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && charCode != 32 && charCode != 46 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
  }
  isNumberPhonenumber(evt: any) {
    let mobilenumber = '';
    if (mobilenumber != undefined) {
      var phonenumberLengt = mobilenumber.length;
    }
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var cleaned = ('' + mobilenumber).replace(/\D/g, '').length;
    if (charCode > 31 && charCode != 45 && charCode != 40 && charCode != 41 && charCode != 32 && (charCode < 48 || charCode > 57) || phonenumberLengt >= 15 || cleaned >= 11) {
      evt.preventDefault();
    }
  }
  Phonenumberchange(fieldTypedtext) {
    var prefix = ('' + fieldTypedtext).replace(/\D/g, '');
    // this.phonenumberfieldtext = prefix;
    var phonenumberlength = fieldTypedtext.length;
    if (phonenumberlength > 15) {
      return false;
    }
    let phone = '';
    var cleanedlength = ('' + prefix).replace(/\D/g, '').length;
    if (cleanedlength == 10) {
      var cleaned = ('' + prefix).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        phone = '(' + match[1] + ') ' + match[2] + '-' + match[3];
        this.UserAddForm.patchValue({
          mobilenumber: phone
        });
      }
    }
    else {
      var cleaned = ('' + prefix).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        phone = match[1] + '(' + match[2] + ') ' + match[3] + '-' + match[4];
        this.UserAddForm.patchValue({
          mobilenumber: phone
        });
      }
    }
  }
  onError(res: any) {
    this.spinner.hide();
    console.log(res);
  }
}
