import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MDBModalRef, MdbTableDirective, ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponentService } from '../../../services/appcomponenet.service';
import { SettingService } from '../../../services/settings.service';
import { ConfirmationdilogService } from '../../../services/confirmationdilog.service';


@Component({
  selector: 'app-miscellaneouspopup',
  templateUrl: './miscellaneouspopup.component.html',
  styleUrls: ['./miscellaneouspopup.component.scss']
})
export class MiscellaneousPopupComponent implements OnInit {
  Username: any; authorizeUserDetails: any[]; MiscellaneousPopupmodaldata: any;
  @ViewChild("valuefocus", { static: false }) valuefocus: ElementRef;
  action = new Subject();
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };
  company: any = ''; type: any = ''; value: any = ''; miscellaneousflag: any = '';
  misDataDetails: any[]; Companydetails: any[]; MiscellaneousLevelDetails: any[]; miscellaneousmstid: any;
  updatedMiscellaneous: any[]; previousvalue: any;
  constructor(private cdRef: ChangeDetectorRef, private router: Router, private activatedroute: ActivatedRoute,
    private appComponentService: AppComponentService,private datepipe: DatePipe, private _el: ElementRef, public modalRef: MDBModalRef, private toastrService: ToastService,
    public formBuilder: FormBuilder, private settingService: SettingService, private confirmationDialogService: ConfirmationdilogService) { }
  ngAfterViewInit() {
    this.miscellaneousflag = this.MiscellaneousPopupmodaldata.miscellaneousflag;
    if (this.valuefocus != undefined && this.miscellaneousflag == 'add') {
      this.valuefocus.nativeElement.focus();
    }
  }
  ngOnInit() {
    debugger
    this.company = this.MiscellaneousPopupmodaldata.companyval;
    this.type = this.MiscellaneousPopupmodaldata.miscellaneouslevelval;
    this.value = this.MiscellaneousPopupmodaldata.miscellaneousvalue;
    this.previousvalue = this.value;
    this.miscellaneousmstid = this.MiscellaneousPopupmodaldata.miscellaneousmstid;
    this.miscellaneousflag = this.MiscellaneousPopupmodaldata.miscellaneousflag;
    this.getAuthorizeUserDetails();
  }
  getAuthorizeUserDetails() {
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    this.authorizeUserDetails = [];
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      let UserId = this.authorizeUserDetails[0]['userid'];
      let level = this.authorizeUserDetails[0]['level'];
      let Sedonadatabase = this.authorizeUserDetails[0]['sedonadatabase'];
      if (UserId != "") {
        this.Username = UserId;
      }
      this.getCompanyDetails();
    }
  }
  getCompanyDetails() {
    this.settingService.getCompanyDetails().subscribe(getresult => { this.getCompanyDetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getCompanyDetailsSuccess(getresult: any[]) {
    this.Companydetails = [];
    if (getresult.length > 0) {
      this.Companydetails = getresult;
    }
  }
  getMiscellaneousLevel() {
    this.settingService.getMiscellaneousLevel().subscribe(getresult => { this.getMiscellaneousLevelSuccess(getresult) },
      res => { this.onError(res) });
  }
  getMiscellaneousLevelSuccess(getresult: any[]) {
    this.MiscellaneousLevelDetails = [];
    if (getresult.length > 0) {
      this.MiscellaneousLevelDetails = getresult;
    }
  }

  deleteMisDataTable() {
    let value = this.previousvalue;
      if (confirm("Are you sure you want to delete this option?")) {
        this.settingService.checkMiscellaneousLink(value).subscribe(getresult => { this.checkMiscellaneousLinkDeleteSuccess(getresult) },
          res => { this.onError(res) });
      return true;
    }
    else {
      return false;
    }
  }
  checkMiscellaneousLinkDeleteSuccess(getresult) {
    debugger
    let miscellaneousmstid = this.miscellaneousmstid;
    let strusername = this.Username;
    if (getresult.length > 0) {
      let message = getresult[0].miscellaneousexistmsg;
      if (message == 'exist') {
        this.confirmationDialogService.confirm('This dropdown option is linked to a lead/user!', 'Please confirm delete.')
          .then((confirmed) => {
            if (confirmed == true) {
              this.settingService.deleteMiscellaneousMst(miscellaneousmstid).subscribe(getresult => { this.deleteMiscellaneousMstSuccess(getresult) },
                res => { this.onError(res) });
              return true;
            }
            else {
              return false;
            }
          })
          .catch(res => { this.onError(res) });
        /*this.toastrService.warning('This dropdown options is linked to a user/lead.', '', this.options);*/
      }
      else {
        this.settingService.deleteMiscellaneousMst(miscellaneousmstid).subscribe(getresult => { this.deleteMiscellaneousMstSuccess(getresult) },
          res => { this.onError(res) });
      }
    }
  }
  checkMiscellaneousLinkUpdateSuccess(getresult, list) {
    debugger
    let miscellaneousmstid = this.miscellaneousmstid;
    let strusername = this.Username;
    if (getresult.length > 0) {
      let message = getresult[0].miscellaneousexistmsg;
      if (message == 'exist') {
        this.confirmationDialogService.confirm('This dropdown option is linked to a lead/user!', 'Please confirm update.')
          .then((confirmed) => {
            if (confirmed == true) {
              this.settingService.updateMiscellaneousMst(list, miscellaneousmstid).subscribe(getresult => { this.updateMiscellaneousMstuccess(getresult) },
                res => { this.onError(res) });
              return true;
            }
            else {
              return false;
            }
          })
          .catch(res => { this.onError(res) });
        /*this.toastrService.warning('This dropdown options is linked to a user/lead.', '', this.options);*/
      }
      else {
        this.settingService.updateMiscellaneousMst(list, miscellaneousmstid).subscribe(getresult => { this.updateMiscellaneousMstuccess(getresult) },
          res => { this.onError(res) });
      }
    }
  }
  deleteMiscellaneousMstSuccess(getresult: any[]) {
    this.misDataDetails = [];
    if (getresult.length > 0) {
      this.misDataDetails = getresult;
    }
    this.toastrService.error('Dropdown option deleted', '', this.options);
    this.closeMiscellaneousTable();
    this.action.next('delete');
  }
  updateMisDataTable() {
    let list = [];
      let Item = {
        "type": this.type,
        "value": this.value,
        "company": this.company
      };
    list.push(Item);
    let value = this.previousvalue;
    this.settingService.checkMiscellaneousLink(value).subscribe(getresult => { this.checkMiscellaneousLinkUpdateSuccess(getresult, list) },
      res => { this.onError(res) });
  }
  updateMiscellaneousMstuccess(getresult: any[]) {
    this.misDataDetails = [];
    this.updatedMiscellaneous = [];
    if (getresult.length > 0) {
      this.misDataDetails = getresult;
      let miscellaneousmsg = this.misDataDetails[0].miscellaneousmsg;
      if (miscellaneousmsg == 'exist') {
        this.toastrService.warning('Dropdown option already exists', '', this.options);
      }
      else {
        this.toastrService.success('Dropdown option updated', '', this.options);
      }
      this.misDataDetails.forEach(x => {
        if (x.miscellaneousmstid == this.miscellaneousmstid) {
          this.updatedMiscellaneous = x;
          this.closeMiscellaneousTable();
          this.action.next(this.updatedMiscellaneous);
        }
      });
    }
   
  }
  saveMiscellaneousTable() {

    this.CheckMiscellaneousExist();
  }
  CheckMiscellaneousExist() {
    debugger
    let type = this.type;
    let value = this.value;
    let company = this.company;
    this.settingService.checkMiscellaneousValueExist(type, value, company).subscribe(getresult => { this.checkMiscellaneousValueExistSuccess(getresult) },
      res => { this.onError(res) });
  }
  checkMiscellaneousValueExistSuccess(getresult: any[]) {
    debugger
    if (getresult.length > 0) {
      let miscellaneousexistmsg = getresult[0].miscellaneousexistmsg;
      if (miscellaneousexistmsg == 'exist') {
        this.value = '';
        this.toastrService.warning('Dropdown list already exists', '', this.options);
        return false;
      }
      else {
        this.closeMiscellaneousTable();
        this.action.next({ type: this.type, value: this.value });
        return true;
      }
    }
  }
  clearMiscellaneousTable() {
    this.value = '';
  }
  closeMiscellaneousTable() {
    this.modalRef.hide();
  }
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      evt.preventDefault();
    }

  }
  onError(res: any) {
    console.log(res);
  }
}
