export class AppSettings {
  // public static URI = 'https://mrgatewayapiapp-sandbox.azurewebsites.net/';
  //public static URI = 'http://125.99.253.134/GatewayAPI/';
  //public static URI='http://localhost:61372/';
  //public static URI = 'http://localhost:81/CRMNew';
  //public static URI = './';
  //public static URI = 'https://localhost:105/';

  //public static URI = 'https://localhost:155/';
  //public static URIEstimatorLogin = 'http://localhost:1122/Login.aspx?';
  //public static URIEstimator = 'http://localhost:1122/Home/HomePage.aspx?';
  //public static Mail = 'sparajaiq@hotmail.com';

  //public static URI = 'https://localhost:155/';
  //public static URIEstimatorLogin = 'http://localhost:2040/Login.aspx?';
  //public static URIEstimator = 'http://localhost:2040/Home/HomePage.aspx?';

  //public static URI = 'https://localhost:44380/';
  //public static URIEstimatorLogin = 'http://localhost:2040/Login.aspx?';
  //public static URIEstimator = 'http://localhost:2040/Home/HomePage.aspx?';

  //public static URI = 'https://visioncrm.afap.com/';
  //public static URIEstimatorLogin = 'https://estimatortest.afap.com/Login.aspx?';
  //public static URIEstimator = 'https://estimatortest.afap.com/Home/HomePage.aspx?';
  //public static Mail = 'manager1@afap.com';

  //public static OAuthSettings = {
  //  'clientId': 'bb8d2ad4-fc60-4719-b00d-5cbbc2c5d5a2',
  //  'authority': 'https://login.microsoftonline.com/0e1464bb-5d4a-46e1-9d22-39ba057aacdf',
  //  'redirectUri': 'https://localhost:155/',
  //  'api': 'api://bb8d2ad4-fc60-4719-b00d-5cbbc2c5d5a2/access_as_user',
  //  scopes: [
  //    'user.read',
  //    'openid',
  //    'profile',
  //    'email',
  //    'mailboxsettings.read',
  //    'calendars.readwrite',
  //    'mail.send',
  //    'tasks.readwrite',
  //    'tasks.read'
  //  ],
  //  'GRAPH_ENDPOINT': 'https://graph.microsoft.com/v1.0/me',
  //  'URIEstimatorLogin': 'http://localhost:1122/Login.aspx?',
  //  'URIEstimator': 'http://localhost:1122/Home/HomePage.aspx?',
  //  'ApplicationName': 'AFA Vision CRM'
  //}

  //public static OAuthSettings = {
  //  'clientId': 'bb8d2ad4-fc60-4719-b00d-5cbbc2c5d5a2',
  //  'authority': 'https://login.microsoftonline.com/0e1464bb-5d4a-46e1-9d22-39ba057aacdf',
  //  'redirectUri': 'https://localhost:44379/',
  //  'api': 'api://bb8d2ad4-fc60-4719-b00d-5cbbc2c5d5a2/access_as_user',
  //  scopes: [
  //    'user.read',
  //    'openid',
  //    'profile',
  //    'offline_access',
  //    'email',
  //    'mailboxsettings.readwrite',
  //    'mail.readwrite',
  //    'mail.send',
  //    'api://bb8d2ad4-fc60-4719-b00d-5cbbc2c5d5a2/access_as_user'
  //  ],
  //  'GRAPH_ENDPOINT': 'https://graph.microsoft.com/v1.0/me',
  //  'URIEstimatorLogin': 'http://localhost:2040/Login.aspx?',
  //  'URIEstimator': 'http://localhost:2040/Home/HomePage.aspx?',
  // 'URIPrint':'https://localhost:44338/Report/PrintReport.aspx?',
  //  'ApplicationName': 'AFA Vision CRM',
  //  'timeZone': 'Eastern Standard Time',
  //  'idleTime': 2400000 ,
  //  'tokenExpireTime': 1200,
  //  'lazyLoadingCount':10
  //}


  //public static OAuthSettings = {
  //  'clientId': 'bb8d2ad4-fc60-4719-b00d-5cbbc2c5d5a2',
  //  'authority': 'https://login.microsoftonline.com/0e1464bb-5d4a-46e1-9d22-39ba057aacdf',
  //  'redirectUri': 'https://localhost:2000/',
  //  'api': 'api://bb8d2ad4-fc60-4719-b00d-5cbbc2c5d5a2/access_as_user',
  //  scopes: [
  //    'user.read',
  //    'openid',
  //    'profile',
  //    'offline_access',
  //    'email',
  //    'mailboxsettings.readwrite',
  //    'calendars.readwrite',
  //    'mail.readwrite',
  //    'mail.send',
  //    'tasks.readwrite',
  //    'tasks.read',
  //    'api://bb8d2ad4-fc60-4719-b00d-5cbbc2c5d5a2/access_as_user'
  //  ],
  //  'GRAPH_ENDPOINT': 'https://graph.microsoft.com/v1.0/me',
  //  'URIEstimatorLogin': 'http://localhost:2040/Login.aspx?',
  //  'URIEstimator': 'http://localhost:2040/Home/HomePage.aspx?',
  //  'URIPrint': 'https://localhost:44338/Report/PrintReport.aspx?',
  //  'ApplicationName': 'AFA Vision CRM',
  //  'timeZone': 'Eastern Standard Time',
  //  'idleTime': 2400000,
  //  'tokenExpireTime': 1200
  //}


  // public static OAuthSettings = {
  //  "clientId": 'b670b22b-758a-4a6e-b4e3-d0aa39900f01',
  //  "authority": 'https://login.microsoftonline.com/2a98fe4e-0de6-4111-bd8f-b6611334e792',
  //   "redirectUri": 'https://leads.afap.com/',
  //  "api": 'api://b670b22b-758a-4a6e-b4e3-d0aa39900f01/access_as_user',
  //  scopes: [
  //    'user.read',
  //    'openid',
  //    'profile',
  //    'email',
  //    'mailboxsettings.read',
  //    'calendars.readwrite',
  //    'mail.readwrite',
  //    'mail.send',
  //    'tasks.readwrite',
  //    'tasks.read',
  //    'api://b670b22b-758a-4a6e-b4e3-d0aa39900f01/access_as_user'
  //  ],
  //  'GRAPH_ENDPOINT': 'https://graph.microsoft.com/v1.0/me',
  //  'URIEstimatorLogin': 'https://estimatortest.afap.com/Login.aspx?',
  //  'URIEstimator': 'https://estimatortest.afap.com/Home/HomePage.aspx?',
  //'URIPrint': 'https://crmestreport.afap.com/Report/PrintReport.aspx?',
  //  'ApplicationName': 'Lead Generator',
  //  'timeZone':"Eastern Standard Time",
  //  'idleTime': 2400000 ,
  //  'tokenExpireTime': 1200
  //}

  // public static OAuthSettings = {
  //  "clientId": 'b670b22b-758a-4a6e-b4e3-d0aa39900f01',
  //  "authority": 'https://login.microsoftonline.com/2a98fe4e-0de6-4111-bd8f-b6611334e792',
  //   "redirectUri": 'https://leads.afap.com/',
  //  "api": 'api://b670b22b-758a-4a6e-b4e3-d0aa39900f01/access_as_user',
  //  scopes: [
  //    'user.read',
  //    'openid',
  //    'profile',
  //    'email',
  //    'mailboxsettings.read',
  //    'calendars.readwrite',
  //    'mail.readwrite',
  //    'mail.send',
  //    'tasks.readwrite',
  //    'tasks.read',
  //    'api://b670b22b-758a-4a6e-b4e3-d0aa39900f01/access_as_user'
  //  ],
  //  'GRAPH_ENDPOINT': 'https://graph.microsoft.com/v1.0/me',
  //  'URIEstimatorLogin': 'https://estimatortest.afap.com/Login.aspx?',
  //  'URIEstimator': 'https://estimatortest.afap.com/Home/HomePage.aspx?',
  //  'URIPrint': 'https://crmestreporttest.afap.com/Report/PrintReport.aspx?',
  //  'ApplicationName': 'Lead Generator',
  //  'timeZone':"Eastern Standard Time",
  //  'idleTime': 2400000 ,
  //  'tokenExpireTime': 1200,
   //  'lazyLoadingCount': 170
  //}


   public static OAuthSettings = {
    "clientId": 'f4c4c9e7-1c2d-4be8-81e3-5b7152648c83',
    "authority": 'https://login.microsoftonline.com/2a98fe4e-0de6-4111-bd8f-b6611334e792',
     "redirectUri": 'https://leads.afap.com/',
     "api": 'api://f4c4c9e7-1c2d-4be8-81e3-5b7152648c83/access_as_user',
    scopes: [
      'user.read',
      'openid',
      'profile',
      'email',
      'mailboxsettings.read',
      'mail.readwrite',
      'mail.send',
      'api://f4c4c9e7-1c2d-4be8-81e3-5b7152648c83/access_as_user'
    ],
    'GRAPH_ENDPOINT': 'https://graph.microsoft.com/v1.0/me',
    'URIEstimatorLogin': 'https://estimatortest.afap.com/Login.aspx?',
    'URIEstimator': 'https://estimatortest.afap.com/Home/HomePage.aspx?',
    'URIPrint': 'https://crmestreporttest.afap.com/Report/PrintReport.aspx?',
    'ApplicationName': 'Lead Generator',
    'timeZone':"Eastern Standard Time",
    'idleTime': 2400000 ,
    'tokenExpireTime': 1200,
    'lazyLoadingCount': 170
  }
}
