import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError, interval, BehaviorSubject, Subject, Subscription } from 'rxjs'
import { AppSettings } from '../Appsettings';
/*import { sendMail } from '../Email.js';*/
const mailOptions = {
  from: "noreply@bluechipsw.com",
  to: "parajakec04@gmail.com",
  subject: "Nodejs Mail",
  text: "this is the email's body text..."
};
@Injectable({
    providedIn: 'root'
})

export class EmailService {
  formDigest: any = null;
  URI = AppSettings.OAuthSettings.redirectUri;
    constructor(private _http: HttpClient) {
    }
  sendEmail(list): Observable<any> {
    debugger
    return this._http.post(this.URI + 'api/Email/SendEmail', list,{ responseType: 'text' });
  }
  sendEmailAttachment(formData): Observable<any> {
    debugger
    return this._http.post(this.URI + 'api/Email/SendEmailAttachment', formData, { responseType: 'text' });
  }
}
