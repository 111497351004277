import { Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-usermanualpdf',
  templateUrl: './usermanualpdf.component.html',
  styleUrls: ['./usermanualpdf.component.scss']
})
export class UserManualPdfComponent implements OnInit{
  src: any;
    constructor() {
  }  
  ngOnInit() {
    this.src = 'assets/usermanual/Lead Generator User Manual (CTSI).pdf';
    }       
}
