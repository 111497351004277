import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError, interval, BehaviorSubject, Subject } from 'rxjs'
import { AppSettings } from '../Appsettings';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  formDigest: any = null;
  URI = AppSettings.OAuthSettings.redirectUri;
  constructor(private _http: HttpClient) { }
  getUserActivity(list): Observable<any> {
    return this._http.post(this.URI + 'api/UserActivity/FetchUserActivity', list);
  }
  getExecutiveEmail(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/UserActivity/FetchUserExecutiveEmail/' + strusername);
  }
  uploadDocument(formData): Observable<any> {
    return this._http.post(this.URI + 'api/DocsUploadDownload/UploadDoc', formData);
  }
  readExportLeadsEmailDoc(filePathval: string): Observable<HttpEvent<Blob>> {
    return this._http.request(new HttpRequest(
      'GET',
      `${this.URI + 'api/DocsUploadDownload/ReadExportLeadsEmailDoc'}?file=${filePathval}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  downloadDoc(filePathval: string): Observable<HttpEvent<Blob>> {
    return this._http.request(new HttpRequest(
      'GET',
      `${this.URI + 'api/DocsUploadDownload/DownloadDoc'}?file=${filePathval}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  deleteDocumentFile(filePathval: string): Observable<any> {
    return this._http.request(new HttpRequest(
      'GET',
      `${this.URI + 'api/DocsUploadDownload/DeleteDoc'}?file=${filePathval}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  getFetchTableSearchList(searchcategory, searchtext): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchTableSearchList/' + searchcategory + '/' + searchtext);
  }
}
