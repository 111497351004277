import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MDBModalRef, MdbTableDirective, ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponentService } from '../../../services/appcomponenet.service';
import { SettingService } from '../../../services/settings.service';
import { ConfirmationdilogService } from '../../../services/confirmationdilog.service';


@Component({
  selector: 'app-miscellaneouslevelpopup',
  templateUrl: './miscellaneouslevelpopup.component.html',
  styleUrls: ['./miscellaneouslevelpopup.component.scss']
})
export class MiscellaneousLevelPopupComponent implements OnInit {
  Username: any; authorizeUserDetails: any[]; MiscellaneousLevelPopupmodaldata: any;
  @ViewChild("valuefocus", { static: false }) valuefocus: ElementRef;
  action = new Subject();
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };
  company: any = ''; value: any = ''; previousvalue: any = ''; miscellaneouslevelflag: any = '';
  Companydetails: any[]; MiscellaneousLevelDetails: any[]; miscellaneouslevelid: any;
  updatedMiscellaneousLevel: any[];
  constructor(private cdRef: ChangeDetectorRef, private router: Router, private activatedroute: ActivatedRoute,
    private appComponentService: AppComponentService, private datepipe: DatePipe, private _el: ElementRef, public modalRef: MDBModalRef, private toastrService: ToastService,
    public formBuilder: FormBuilder, private settingService: SettingService, private confirmationDialogService: ConfirmationdilogService) { }
  ngAfterViewInit() {
    this.miscellaneouslevelflag = this.MiscellaneousLevelPopupmodaldata.miscellaneouslevelflag;
    if (this.valuefocus != undefined && this.miscellaneouslevelflag == 'add') {
      this.valuefocus.nativeElement.focus();
    }
  }
  ngOnInit() {
    debugger
    this.miscellaneouslevelflag = this.MiscellaneousLevelPopupmodaldata.miscellaneouslevelflag;
    if (this.MiscellaneousLevelPopupmodaldata.companyval) {
      this.company = this.MiscellaneousLevelPopupmodaldata.companyval;
    }
    else {
      this.company = '';
    }
    if (this.MiscellaneousLevelPopupmodaldata.miscellaneousvalue && this.miscellaneouslevelflag != 'add') {
      this.value = this.MiscellaneousLevelPopupmodaldata.miscellaneousvalue;
    }
    else {
      this.value = '';
    }
    this.previousvalue = this.value;
    this.miscellaneouslevelid = this.MiscellaneousLevelPopupmodaldata.miscellaneouslevelid;
    this.getAuthorizeUserDetails();
  }
  getAuthorizeUserDetails() {
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    this.authorizeUserDetails = [];
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      let UserId = this.authorizeUserDetails[0]['userid'];
      let level = this.authorizeUserDetails[0]['level'];
      let Sedonadatabase = this.authorizeUserDetails[0]['sedonadatabase'];
      if (UserId != "") {
        this.Username = UserId;
      }
      this.getCompanyDetails();
    }
  }
  getCompanyDetails() {
    this.settingService.getCompanyDetails().subscribe(getresult => { this.getCompanyDetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getCompanyDetailsSuccess(getresult: any[]) {
    this.Companydetails = [];
    if (getresult.length > 0) {
      this.Companydetails = getresult;
    }
  }
  deleteMiscellaneousLevelTable() {
    let value = this.previousvalue;
    if (confirm("Are you sure you want to delete this dropdown list?")) {
      this.settingService.checkMiscellaneousLevelLink(value).subscribe(getresult => { this.checkMiscellaneousLevelLinkDeleteSuccess(getresult) },
        res => { this.onError(res) });
      return true;
    }
    else {
      return false;
    }
  }
  checkMiscellaneousLevelLinkDeleteSuccess(getresult) {
    debugger
    let miscellaneouslevelid = this.miscellaneouslevelid;
    let strusername = this.Username;
    if (getresult.length > 0) {
      let message = getresult[0].miscellaneouslevelexistmsg;
      if (message == 'exist') {
        this.confirmationDialogService.confirm('Please confirm..', 'Dropdown list is linked to a user/lead!')
          .then((confirmed) => {
            if (confirmed == true) {
              this.settingService.deleteMiscellaneousLevel(miscellaneouslevelid).subscribe(getresult => { this.deleteMiscellaneousLevelSuccess(getresult) },
                res => { this.onError(res) });
              return true;
            }
            else {
              return false;
            }
          })
          .catch(res => { this.onError(res) });
        /* this.toastrService.warning('Dropdown list is linked to a user/lead.', '', this.options);*/
      }
      else {
        this.settingService.deleteMiscellaneousLevel(miscellaneouslevelid).subscribe(getresult => { this.deleteMiscellaneousLevelSuccess(getresult) },
          res => { this.onError(res) });
      }
    }
  }
  checkMiscellaneousLevelLinkUpdateSuccess(getresult, list) {
    debugger
    let miscellaneouslevelid = this.miscellaneouslevelid;
    if (getresult.length > 0) {
      let message = getresult[0].miscellaneouslevelexistmsg;
      if (message == 'exist') {
        this.confirmationDialogService.confirm('Please confirm..', 'Dropdown list is linked to a user/lead!')
          .then((confirmed) => {
            if (confirmed == true) {
              this.settingService.updateMiscellaneousLevel(list, miscellaneouslevelid).subscribe(getresult => { this.updateMiscellaneousLevelSuccess(getresult) },
                res => { this.onError(res) });
              return true;
            }
            else {
              return false;
            }
          })
          .catch(res => { this.onError(res) });
        /* this.toastrService.warning('Dropdown list is linked to a user/lead.', '', this.options);*/
      }
      else {
        this.settingService.updateMiscellaneousLevel(list, miscellaneouslevelid).subscribe(getresult => { this.updateMiscellaneousLevelSuccess(getresult) },
          res => { this.onError(res) });
      }
    }
    
  }
  deleteMiscellaneousLevelSuccess(getresult: any[]) {
    this.MiscellaneousLevelDetails = [];
    if (getresult.length > 0) {
      this.MiscellaneousLevelDetails = getresult;
      /*this.loadMiscellaneousLevelDetails();*/
    }
    this.toastrService.error('Dropdown list deleted', '', this.options);
    this.closeMiscellaneousLevelTable();
    this.action.next('delete');
  }
  updateMiscellaneousLevelTable() {
    debugger
    let list = [];
      let Item = {
        "value": this.value,
        "company": this.company
      };
      list.push(Item);
    let value = this.previousvalue;
    this.settingService.checkMiscellaneousLevelLink(value).subscribe(getresult => { this.checkMiscellaneousLevelLinkUpdateSuccess(getresult, list) },
      res => { this.onError(res) });
  }
  updateMiscellaneousLevelSuccess(getresult: any[]) {
    this.MiscellaneousLevelDetails = [];
    this.updatedMiscellaneousLevel = [];
    if (getresult.length > 0) {
      this.MiscellaneousLevelDetails = getresult;
      /*this.loadMiscellaneousLevelDetails();*/
      let miscellaneouslevelmsg = this.MiscellaneousLevelDetails[0].miscellaneouslevelmsg;
      if (miscellaneouslevelmsg == 'exist') {
        this.toastrService.warning('Dropdown list already exists', '', this.options);
      }
      else {
        this.toastrService.success('Dropdown list updated', '', this.options);
      }
      this.MiscellaneousLevelDetails.forEach(x => {
        if (x.miscellaneouslevelid == this.miscellaneouslevelid) {
          this.updatedMiscellaneousLevel = x;
          this.closeMiscellaneousLevelTable();
          this.action.next(this.updatedMiscellaneousLevel);
        }
      });
    }
  }
  saveMiscellaneousLevelTable() {

    this.CheckMiscellaneousLevelExist();
  }
  CheckMiscellaneousLevelExist() {
    debugger
    let value = this.value;
    let company = this.company;
    this.settingService.checkMiscellaneousLevelExist(value, company).subscribe(getresult => { this.checkMiscellaneousLevelExistSuccess(getresult) },
      res => { this.onError(res) });
  }
  checkMiscellaneousLevelExistSuccess(getresult: any[]) {
    debugger
    if (getresult.length > 0) {
      let miscellaneouslevelexistmsg = getresult[0].miscellaneouslevelexistmsg;
      if (miscellaneouslevelexistmsg == 'exist') {
        this.value = '';
        this.toastrService.warning('Dropdown list already exists', '', this.options);
        return false;
      }
      else {
        this.closeMiscellaneousLevelTable();
        this.action.next({value: this.value });
        return true;
      }
    }
  }
  clearMiscellaneousLevelTable() {
    this.value = '';
  }
  closeMiscellaneousLevelTable() {
    this.modalRef.hide();
  }
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      evt.preventDefault();
    }

  }
  onError(res: any) {
    console.log(res);
  }
}
