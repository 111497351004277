import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'm2h'
})
export class MinutesToHours implements PipeTransform {
  transform(value: number): string {
    //var res = value.split('.', 2);
    //var minutes = 0;
    //if (res[1] != '') {
    //  minutes = (parseInt(res[0]) * 60) + parseInt(res[1]);
    //}
    //else {
    //  minutes = parseInt(res[0]) * 60;
    //}
    
    //var hours = minutes / 100;
    //var hours1 = minutes % 100;
    //return hours + '.' + hours1 ;
    //var hours = parseInt(value[0], 10);
    //var minutes = value[1] ? parseInt(value[1], 10) : 0;
    //return (hours + minutes / 60).toFixed(2);

    var Days = Math.floor(value / 24);
    var Remainder = value % 24;
    var Hours = Math.floor(Remainder);
    var Minutes = Math.floor(60 * (Remainder - Hours));
    return (Hours + '.' + Minutes);
  }
}
