import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fileSaver from 'file-saver';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import { AppSettings } from '../Appsettings';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class ExcelService {
  templateToExcel: any[][];
  formDigest: any = null;
  URI = AppSettings.OAuthSettings.redirectUri;
  constructor(private _http: HttpClient) { }
  public exportAsExcelFile(json: any[], excelFileName: string, excelHeaders: any[]): void {
    debugger
    this.templateToExcel = [excelHeaders, []];
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    XLSX.utils.sheet_add_aoa(worksheet, this.templateToExcel);

    let range = XLSX.utils.decode_range(worksheet['!ref']);
    //for (let C = range.s.r; C <= range.e.c; ++C) {
    //  debugger
    //  var address = XLSX.utils.encode_col(C) + '1'; // <-- first row, column number C
    //  if (!worksheet[address]) continue;
    //  worksheet[address].v = worksheet[address].v.bold();
    //}
    //for (var R = range.s.r; R <= range.e.r; ++R) {
    //  for (var C = range.s.c; C <= range.e.c; ++C) {
    //    var address = XLSX.utils.encode_col(C) + '1'; // <-- first row, column number C
    //    if (!worksheet[address]) continue;
    //    worksheet[address].s = {
    //      fill: {
    //        patternType: "none", // none / solid
    //        fgColor: { rgb: "FF000000" },
    //        bgColor: { rgb: "FFFFFFFF" }
    //      },
    //      font: {
    //        name: 'Times New Roman',
    //        sz: 16,
    //        color: { rgb: "#FF000000" },
    //        bold: true,
    //        italic: false,
    //        underline: false
    //      },
    //      border: {
    //        top: { style: "thin", color: { auto: 1 } },
    //        right: { style: "thin", color: { auto: 1 } },
    //        bottom: { style: "thin", color: { auto: 1 } },
    //        left: { style: "thin", color: { auto: 1 } }
    //      }
    //    }
    //  }
    //}

    const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    fileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  getBranchMaster(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchBranchMaster/' + strusername);
  }
  getUserLevel(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchUserLevel/' + strusername);
  }
  getCompanyWithBranchDetails(company): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchCompanyWithBranchDetails/' + company);
  }
  getCompanyDetails(): Observable<any> {
    return this._http.get(this.URI + 'api/Setting/FetchCompanyDetails');
  }
  getImportUser(strusername): Observable<any> {
    return this._http.get(this.URI + 'api/ExportExcelUser/FetchImportUser/' + strusername);
  }
  insertImportUser(list): Observable<any> {
    debugger
    return this._http.post(this.URI + 'api/ExportExcelUser/InsertImportUser', list);
  }
  updateImportUser(list): Observable<any> {
    debugger
    return this._http.put(this.URI + 'api/ExportExcelUser/UpdateImportUser', list);
  }
  deleteImportUser(rowid, user): Observable<any> {
    return this._http.delete(this.URI + 'api/ExportExcelUser/DeleteImportUser/' + rowid + '/' + user);
  }
}
