import { Injectable } from '@angular/core';
/*import { MsalService, BroadcastService } from '@azure/msal-angular';*/
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AlertsService } from './alerts.service';
//import { OAuthSettings } from '../../oauth';
import { AppSettings } from '../Appsettings';
import { User } from '../Interface/user';
//import { Client } from '@microsoft/microsoft-graph-client';
//import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

@Injectable({
  providedIn: 'root'
})

export class AuthendicationService {
  public authenticated: boolean;
  public user: User;
  resul = [];
  GRAPH_ENDPOINT = AppSettings.OAuthSettings.GRAPH_ENDPOINT;
  accessTokenRequest = {
    scopes: ['https://graph.microsoft.com/.default']
    //scopes: ["user.read"]
  };
  constructor(
    /*private msalService: MsalService,*/
    private alertsService: AlertsService,
    private http: HttpClient,
    /*private broadcastService: BroadcastService*/
  ) {
    //this.authenticated = this.msalService.getAccount() != null;
    //this.getUser().then((user) => {this.user = user});
  }
  //// Prompt the user to sign in and
  //// grant consent to the requested permission scopes
  //async signIn(): Promise<void> {
  //  let result = await this.msalService.loginPopup(AppSettings.OAuthSettings)
  //    .catch((reason) => {
  //      this.alertsService.addError('Login failed', JSON.stringify(reason, null, 2));
  //    });

  //  if (result) {
  //    this.authenticated = true;
  //    // Temporary placeholder
  //    this.user = new User();
  //    //this.user.displayName = 'Adele Vance';
  //    //this.user.email = 'AdeleV@contoso.com';
  //    //this.user.avatar = '/assets/no-profile-photo.png';
  //  }
  //}

  //// Sign out
  //signOut(): void {
  //  this.msalService.logout();
  //  this.user = null;
  //  this.authenticated = false;
  //}

  // Silently request an access token
  //async getAccessToken(): Promise<string> {
  //  let result = await this.msalService.acquireTokenSilent(this.accessTokenRequest)
  //    .catch((reason) => {
  //      this.alertsService.addError('Get token failed', JSON.stringify(reason, null, 2));
  //    });
  //  //this.broadcastService.subscribe("msal:acquireTokenSuccess", (payload) => {
  //  //   console.log("acquire token success " + JSON.stringify(payload));
  //  //   //JSON.stringify(payload);
  //  //  this.resul = payload;
  //  //  //console.log(this.resul);
  //  // });
  //  if (result) {
  //    // Temporary to display token in an error box
  //    this.alertsService.addSuccess('Token acquired', result.accessToken);
  //    return result.accessToken;
  //  }

  //  // Couldn't get a token
  //  this.authenticated = false;
  //  return null;

  //}
  //private async getUser(): Promise<User> {
  //  //if (!this.authenticated) return null;

  //  let graphClient = Client.init({
  //    // Initialize the Graph client with an auth
  //    // provider that requests the token from the
  //    // auth service
  //    authProvider: async(done) => {
  //      let token = await this.getAccessToken()
  //        .catch((reason) => {
  //          done(reason, null);
  //        });

  //      if (token)
  //      {
  //        console.log(token);
  //        done(null, token);
  //      } else {
  //        done("Could not get an access token", null);
  //      }
  //    }
  //  });

  //  // Get the user from Graph (GET /me)
  //  let graphUser: MicrosoftGraph.User = await graphClient
  //    .api('/me')
  //    .select('displayName,mail,mailboxSettings,userPrincipalName')
  //    .get();
  //  //this.http.get(GRAPH_ENDPOINT)
  //  //  .toPromise()
  //  //  .then(profile => {
  //  //    this.profile = profile;
  //  //  });
  //  let user = new User();
  //  user.displayName = graphUser.displayName;
  //  // Prefer the mail property, but fall back to userPrincipalName
  //  user.email = graphUser.mail || graphUser.userPrincipalName;
  //  user.timeZone = graphUser.mailboxSettings.timeZone;

  //  // Use default avatar
  //  user.avatar = '/assets/no-profile-photo.png';

  //  return user;
  //}
}
