import { Injectable } from '@angular/core';
import {HttpInterceptor,HttpRequest,HttpHandler,HttpEvent,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RequestHeaderInterceptor implements HttpInterceptor {
  formDigest: any = null;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let reqheaders = new HttpHeaders();
    this.createAuthorizationHeader(reqheaders);
    reqheaders.append("Content-Type", "application/json;odata=verbose");
    reqheaders.append("IF-MATCH", "*");
    reqheaders.append("Access-Control-Allow-Origin", "*");
    reqheaders.append("Access-Control-Allow-Methods", "GET, POST, PATCH, PUT, DELETE, OPTIONS");
    reqheaders.append("Access-Control-Allow-Headers", "Origin, Content-Type, X-Auth-Token");

    const customReq = req.clone({
      headers: reqheaders
    });

    return next.handle(customReq);
  }
  createAuthorizationHeader(headers: HttpHeaders) {
    headers.append("X-RequestDigest", this.formDigest);
    headers.append("Accept", 'application/json; odata=verbose');

  }
}
